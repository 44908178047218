import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {moodiksColors} from "../Styles/palette";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import theme from "../../Theme";
import Link from "@material-ui/core/Link";


const isDarkMode = (test_theme) => {
    return test_theme.palette.type === 'dark';
}


const styles = {
    square: {
        borderRadius: "0%",
        width: 20,
        height: 20,
        padding: theme.spacing(1),
        marginRight: 5,
    },
    continue_button: {
        margin: theme.spacing(1, 0, 1),
        borderRadius: 50,
        width: '10rem'
    },
    later_button: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 50,
        width: '10rem'
    },
    text_1: {
        position: 'absolute',
        top: '30%',
        left: '10%',
    },

    text_2: {
        position: 'absolute',
        top: '35%',
        left: '10%',
    },
    header_line: {
        marginTop: '2rem'
    },
    result_header: {
        marginBottom: '0',
        marginTop: '0px',

    },
    result_text: {
        marginTop: '0px',
        marginBottom: '0px',
    },
    right_align: {
        float: 'right',
    },
    neutral_header: {
        marginTop: '0px',
        marginBottom: 0,
        paddingBottom: 0,
        display: 'inline',
        verticalAlign: 'bottom',
    },

    intro_text: {
        maxHeight: '70%',
        padding: '2rem',
        display: 'block',
        backgroundColor: theme.palette.background.paper,
    },
    scroll: {
        height: '100%',
        overflow: 'auto',
    },
};

const useStyles = makeStyles((display_theme) => (styles));

const LongText = (text) => {
    const lines = text.split('\n');
    return (<>{lines.map((line,i) => <p key={i}>{line}</p>)}</>)
}


const resultColor = (name) => {

    const construct_color = {
        'N': moodiksColors["moodiks_orange"],
        'E': moodiksColors["moodiks_magenta"],
        'O': moodiksColors["moodiks_green"],
        'A': moodiksColors["moodiks_yellow"],
        'C': moodiksColors["moodiks_blue"],
    };
    let color = moodiksColors["moodiks_blue"];
    if (name in construct_color) {
        color = construct_color[name]
    }
    return color
}

const textColor = (name, test_theme) => {
    return isDarkMode(test_theme) ? resultColor(name) : theme.palette.text.primary
}


const MakeHeader = (item, interpretations, suppress_average) => {
    const {t} = useTranslation(['questionnaire']);
    const classes = useStyles();
    const average = (item.adjusted_value > 40) && (item.adjusted_value < 60);
    const this_theme = useTheme();
    const construct = item.name[0];

    if ((suppress_average && average) || item.name === 'N3') return;
    let value_text = '';
    if (item.adjusted_value >= 60) {
        value_text = t("high");
    } else if (item.adjusted_value <= 40) {
        value_text = t("low");
    } else {
        value_text = t("average");
    }

    let description;
    const i = interpretations.findIndex(e => e.construct_name === item.name);
    if (i > 0) {
        description = interpretations[i].description;
    } else {
        description = interpretations.find(e => e.construct_name === item.name + '+').description;
    }

    const explanation = get_explanation(item, interpretations)
    const class_name = suppress_average ? classes.result_header : classes.neutral_header;
    const Heading = suppress_average ? "h4" : "h3";
    return (
        <div>

            <Grid item container direction='row' justify='space-between' alignItems='flex-end'>
                <Grid item>
                    <Heading style={{color: textColor(construct, this_theme)}} className={class_name}>
                        {description} ({item.name})
                    </Heading>
                </Grid>
                {explanation && <>
                    <Grid item>
                        <More className={classes.right_align} text={explanation} more={t('description')}/>
                    </Grid>
                </>
                }
            </Grid>

            <Heading style={{
                color: textColor(construct, this_theme),
                marginTop: '0.5rem'
            }}>
                {t('your result')}: {value_text}
            </Heading>
        </div>
    )
}

const More = ({text, more, className}) => {
    const [visible, setVisible] = React.useState(false);
    const this_theme = useTheme();
    const linkColor = isDarkMode(this_theme) ? blue['A100'] : "#0000EE";

    const toggleVisibility = () => {
       setVisible((old)=> {
           return !old
       })
    }

    return (
        <>
            <Link component="button" style={{color: linkColor, display: 'inline', verticalAlign: 'top'}}
                  onClick={toggleVisibility}>{more}</Link>
            {visible &&
            <Box component='p' className={className}>{text}</Box>
            }
        </>
    )
}

const get_explanation = (item, interpretations) => {
    const i = interpretations.findIndex(e => e.construct_name === item.name);
    let explanation = false
    if (i > 0) {
        explanation = interpretations[i].text;
    }
    return explanation;
}


const MakeDetail = (item, interpretations, suppress_average) => {
    const classes = useStyles();
    const {t} = useTranslation(['questionnaire']);
    const current_theme = useTheme();
    const construct = item.name[0]


    function getInterpretationByName(interpretations, name) {
        const index = interpretations.findIndex(e => e.construct_name === name);
        if (index > 0) {
            return interpretations[index].text;
        } else {
            return '';
        }
    }


    let i_char = '';
    if (item.adjusted_value >= 60) {
        i_char = '+';
    } else if (item.adjusted_value <= 40) {
        i_char = '-';
    } else {
        i_char = '=';
    }
    if (suppress_average && i_char === '=') return;


    const interpretation = getInterpretationByName(interpretations, item.name + i_char);
    const class_name = suppress_average ? classes.result_text : classes.neutral_header;
    return (
        <>

            <p style={{color: textColor(construct, current_theme)}} className={class_name}>{interpretation}</p>
        </>
    )
}


const MakeSubSection = (item, interpretations) => {
    const header = MakeHeader(item, interpretations, true);
    const section = MakeDetail(item, interpretations, true);
    const classes = useStyles();

    return (
        <>
            <div className={classes.header_line}>{header}</div>
            {section}
        </>
    );
}


const MakeMainSection = (item, interpretations) => {
    const classes = useStyles();
    const header = MakeHeader(item, interpretations, false);
    const section = MakeDetail(item, interpretations, false);
    const this_theme = useTheme();
    const result_color = resultColor(item.name, this_theme);
    const textColor = isDarkMode(theme) ? result_color : this_theme.palette.text.primary;

    return (
        <>
            <Grid className={classes.header_line} wrap='nowrap' container direction="row" justify="flex-start"
                  alignItems="top">
                <Grid item>
                    <Box component="div"
                         style={{backgroundColor: result_color}}
                         className={classes.square}/>
                </Grid>
                <Grid item style={{color: textColor}}>{header}</Grid>
            </Grid>
            <Divider/>
            {section}
        </>
    )
}


const ShowResults = ({handler, constructs, interpretations, questionnaire, questionnaire_name, props}) => {
    const {t} = useTranslation(['questionnaire'])
    const q_name = questionnaire ? questionnaire.name : questionnaire_name;

    console.log('constructs: ', constructs);
    const results = constructs.map((item) => {
        if (item.name.length === 1) {
            return (MakeMainSection(item, interpretations));
        } else {
            return (MakeSubSection(item, interpretations));
        }
    });
    const intro_text = q_name === 'NEOPIR' ? LongText(t('neopir result intro')) : LongText(t('ipip result intro'));
    const end_text = q_name === 'IPIP' ? LongText(t('ipip conclusion')) : "";


    const display = <div>
        <More text={intro_text} more={t('description')}/>
        {results}
        <p>{end_text}</p>
    </div>;

    return (
        <>
            <TextPopUp handler={handler} headline={t("Results")} body={display}/>
        </>
    );

}
const TextPopUp = ({handler, headline, body}) => {
    const classes = useStyles();
    const {t} = useTranslation(['questionnaire'])
    const scroll = 'paper';
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        handler('continue');
    };

    return (
        <div>
            <Dialog
                scroll={scroll}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{headline}</DialogTitle>

                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText>
                        <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                            {body}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color={"primary"}
                        className={classes.continue_button}
                        onClick={() => handler('continue')}>
                        <h4>{t('continue')}</h4>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const Introduction = ({handler, text_option}) => {
    const {t} = useTranslation(['questionnaire']);
    const headlineText = {
        'IPIP' : 'ipip header',
        'NEOPIR' : 'neopir header'
    }
    const introTexts = {
        'IPIP': 'ipip instructions',
        'NEOPIR': 'neopir instructions'
    };
    const headline = t(headlineText[text_option]);
    const body = LongText(t(introTexts[text_option]));

    return (
        <>
            <TextPopUp handler={handler} headline={headline} body={body}/>
        </>
    )
}


const CheckPoint = ({handler, progress, props}) => {
    const classes = useStyles();
    const {t} = useTranslation(['questionnaire'])
    return (
        <div>
            <h1>{t("Do you want to continue?")}</h1>
            <Button
                className={classes.continue_button}
                onClick={() => handler('continue')}>
                <h4>{t('continue')}</h4>
            </Button>
            <Button
                className={classes.later_buttton}
                onClick={() => handler('later')}>
                <h4>{t('later')}</h4>
            </Button>
        </div>
    );
}

function DisplayText({progress, handleChoice, text_option, props}) {
    const TextItem = (progress === 0)
        ? <Introduction text_option={text_option} handler={handleChoice}/>
        : <CheckPoint handler={handleChoice} progress={progress}/>;
    return (
        <div>
            {TextItem}
        </div>
    );
}

export {LongText, DisplayText, ShowResults, TextPopUp};

