import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import withStyles from "@material-ui/styles/withStyles";

export const styles = {
    root: {
        position: 'absolute',
        top: '1%',
        left: '1%',
    },
}

const ExitButton = (props) => {
const {
    onExit,
    className,
    classes,
    icon : icon_prop=( <ArrowBackIosIcon/>),
    ...other
} = props;

    return (
        <div className={clsx(classes.root,className)}>
            <IconButton
                aria-label="back" onClick={onExit} {...other}>
                {icon_prop}
            </IconButton>
        </div>
    )
}

export default withStyles(styles)(ExitButton);