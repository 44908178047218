import React, {useEffect, useState, useCallback, useRef, useReducer} from "react";
import {withStyles} from '@material-ui/core/styles';
import {compose} from "recompose";
import {withFirebase} from "../Firebase";
import {withTranslation} from "react-i18next";
import {useLazyQuery} from "../../hooks/gql_helpers";
import PropTypes from "prop-types";
import Loading from "../Loading";
import {useAsync} from "react-async";
import {getMoodiks} from "../Moodiks";

var fastDeepEqual = require('fast-deep-equal');


const styles = {};

const isFunction = (obj) => typeof obj === 'function';
const InfiniteFeedBase = (props) => {
    const {
        firebase,
        classes,
        query,
        mapper,
        queryParams,
        suppressQuery = false,
        onEmpty = null,
        children,
        ...other
    } = props;

    const hideShare = firebase.isAnonymous();
    const [{data, loading, error, pagination}, getData] = useLazyQuery(firebase, mapper);
    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;
    const [newData, setNewData] = useState(false);
    const [feed, setFeed] = useState([]);
    const queryParamsRef = useRef(queryParams);
    const queryRef = useRef(query);
    const onEmptyRef = useRef(onEmpty)

    if (!fastDeepEqual(queryParamsRef.current, queryParams)) {
        if (!suppressQuery)
            queryParamsRef.current = queryParams
    }
    if (!fastDeepEqual(queryRef.current, query)) {
        if (!suppressQuery)
            queryRef.current = query
    }

    if (!fastDeepEqual(onEmptyRef.current, onEmptyRef))
        onEmptyRef.current = onEmpty;

    console.log('infiniteFeed: onEmptyRef.current: ', onEmptyRef.current);

    const pageReducer = (state, action) => {
        switch (action.type) {
            case 'ADVANCE_PAGE':
                if (pagination) {
                    if (pagination.hasNextPage) {
                        return {
                            ...state,
                            after: '"' + pagination.endCursor + '"'
                        }
                    } else
                        return state
                } else
                    return state
            case 'TOP_PAGE':
                if (pagination) {
                    console.log('InfiniteFeed: to TOP_PAGE')
                    return {
                        ...state,
                        after: '""'
                    }
                } else
                    return state
            default:
                return state;
        }
    }

    const [pager, pagerDispatch] = useReducer(pageReducer, {after: '""'});


    useEffect(() => {
        const params = {'first': 20, 'after': pager.after, ...queryParamsRef.current};
        console.log('InfiniteFeed: fetching page ', params);
        getData(queryRef.current(params)).then(() => {
            setNewData(true)
        })
    }, [getData, pager.after, queryRef.current, queryParamsRef.current]);


    useEffect(() => {
        console.log("InfiniteFeed: restarting!");
        pagerDispatch({type: 'TOP_PAGE'});
    }, [setFeed, queryRef.current, queryParamsRef.current]);


    useEffect(() => {
        if (newData) {
            if (pager.after === '""') {
                setFeed(data);
                if (data.length === 0 && onEmptyRef.current) {
                    console.log('InfiniteFeed: is empty!')
                    onEmptyRef.current()
                }
            } else {
                setFeed((f) => f.concat(data));
            }
            setNewData(false)
        }
    }, [data, newData, pager.after, setFeed]);

    let bottomBoundaryRef = useRef(null);

    const scrollObserver = useCallback(
        node => {
            new IntersectionObserver(entries => {
                entries.forEach(en => {
                    if (en.intersectionRatio > 0) {
                        pagerDispatch({type: 'ADVANCE_PAGE'});
                    }
                });
            }).observe(node);
        }, [pagerDispatch]);

    useEffect(() => {
        if (bottomBoundaryRef.current) {
            scrollObserver(bottomBoundaryRef.current);
        }
    }, [scrollObserver, bottomBoundaryRef]);

    console.log('InfiniteFeed: render, queryParams, loading: ', queryParams, loading)


    return (
        <React.Fragment>
            {loading &&
            <Loading/>
            }
            {(feed && moodiksValid) &&
            feed.map((entity, i) => (
                <React.Fragment key={i.toString()}>
                    {isFunction(children) &&
                    children(entity, i, hideShare, {...other, key: i, moodiks: moodiks})
                    }
                </React.Fragment>
            ))
            }
            <div id='page-bottom-boundary' style={{border: '1px solid white'}} ref={bottomBoundaryRef}></div>
        </React.Fragment>

    );

}

InfiniteFeedBase.propTypes = {
    classes: PropTypes.object,
    query: PropTypes.func.isRequired,
    mapper: PropTypes.object.isRequired,
    queryParams: PropTypes.object,
    suppressQuery: PropTypes.bool,
    onEmpty: PropTypes.func
}


const InfiniteFeed = compose(
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
)(InfiniteFeedBase);

export default InfiniteFeed;