import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import i18n from 'i18next'
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import * as ROUTES from '../../constants/routes';
import AppBar from "@material-ui/core/AppBar";
import MobileStepper from "@material-ui/core/MobileStepper";
import Container from "@material-ui/core/Container";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "@material-ui/core/Button";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import MyFormikTextField from "../MyFormikTextField";
import MyFormikDatePicker from "../MyFormikDatePicker";
import {doTestNickUnique, doVerifyInvite, getMoodiks} from "../Moodiks";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import {Link, Snackbar} from '@material-ui/core';
import theme from "../../Theme";
import {differenceInYears} from 'date-fns/esm'
import Alert from "@material-ui/lab/Alert";
import {TextPopUp} from "../TextPop";
import Loading from "../Loading";
import Paper from "@material-ui/core/Paper";
import LazyImage from "../LazyImage";
import MyFormikPasswordField from "../MyFormikPasswordField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useAuthUserContext} from "../Session/context";

const legal_pages = require('../../constants/legal_pages.json');


const terms_text_prefix = "https://moodiks1.fra1.digitaloceanspaces.com/legal/";

export const styles = {
    root: {
        padding: theme.spacing(1, 1)
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        elevation: 0,
    },
    paper: {
        paddingTop: 15,
        paddingBottom: 50, // prevent end of form disappearing under bar
    },
    logo: {
        maxWidth: '80%',
        maxHeight: '80%',
        width: 'auto',
        height: 'auto',
        padding: theme.spacing(1, 0)
    },
    input_field: {
        paddingBottom: 20
    },
    terms: {
        width: '100%',
        height: '20rem'
    },
    img_wrapper: {},
    image: {
        width: '100%',
    },
};


const StyledLink = styled(Link)`
text-decoration: none;
    &:visited{
        text-decoration: none;
        color: blue;
    }
`;

const useStyles = makeStyles((theme) => (styles));

const SignUpPage = () => (
    <>
        <SignUpForm/>
    </>
);


const legal_link = (doc_name, legislation = 'eu', lang) => {
    const index = [doc_name, legislation, lang].join('.');
    // const lookup = legal_pages;
    const lookup = legal_pages[0];
    const filename = lookup[index];
    console.log(lookup);
    debugger;
    return terms_text_prefix + filename;
}

const staticImageUrl = (image_name) => {
    //need this because this url is from the email link and the browser thinks this is the base of the site
    return process.env.PUBLIC_URL + "../static/" + image_name;
}
export const DisplayFormikState = props =>
    <div style={{margin: '1rem 0'}}>
        <h3 style={{fontFamily: 'monospace'}}/>
        <pre
            style={{
                background: '#f6f8fa',
                fontSize: '.65rem',
                padding: '.5rem',
            }}
        >
      <strong>props</strong> ={' '}
            {JSON.stringify(props, null, 2)}
    </pre>
    </div>;


const ErrorFields = (props) => {
    const {formikProps} = props;

    return (
        <React.Fragment>
            {Object.keys(formikProps.errors).map((field) => (
                <div>{field},{formikProps.errors[field]}</div>
            ))}
        </React.Fragment>
    )
};

const SignUpBase = (props) => {
    const {
        classes,
        match,
        firebase,
        history,
        t
    } = props;


    const [activeStep, setActiveStep] = useState(0);
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(true);
    const [email, setEmail] = useState(null);
    const [legislation, setLegislation] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});
    // const terms_text = legislation ? legal_link('sign_up', legislation, t('lang')):'';
    const [termsText, setTermsText] = useState('');


    const textDefaults = {
        className: classes.input_field,
        variant: "outlined",
        margin: "normal",
        required: true,
        fullWidth: true,
        ns: 'common'
    };


    const steps = [
        (
            <React.Fragment>

                <MyFormikTextField inputProps={{readonly: "readonly"}} name="email" label={t("Email Address")}
                                   autoComplete="email"
                                   autoFocus {...textDefaults}/>
                <MyFormikPasswordField name="passwordOne" label={t("Password")} autoComplete="new password"
                                       helperText={t('Password criteria')}
                                       type="password" {...textDefaults}/>
                <MyFormikPasswordField name="passwordTwo" label={t("PasswordTwo")}
                                       autoComplete="new password" type="password" {...textDefaults}/>
                <Typography variant='caption'>{t('No data is stored until you hit SIGN UP')}</Typography>
            </React.Fragment>
        ),
        (
            <React.Fragment>
                <MyFormikTextField name="first_name" label={t('first name')} autoComplete="name"
                                   {...textDefaults}/>
                <MyFormikTextField name="second_name" label={t('second name')} autoComplete="given-name"
                                   {...textDefaults}/>
            </React.Fragment>
        ),
        (
            <MyFormikTextField name="nickname" label={t('nickname')} autoComplete="nickname"
                               {...textDefaults}/>
        ),
        (
            <React.Fragment>
                <MyFormikDatePicker name="birthday" label={t('birthday')} format={t("MM/dd/yyyy")} autoComplete="bday"
                                    variant="dialog" required/>
            </React.Fragment>
        ),
        (
            <React.Fragment>
                <h4>{t('Terms of service')}</h4>
                <Box className={classes.terms} component="iframe" src={termsText}/>
                <p><Link href={termsText} target='_blank'>{t('link to terms')}</Link></p>

                <FormControlLabel
                    control={<Field name="confirm_age" type="checkbox"/>}
                    label={t('I am 16 years old or older')}
                />
                <FormControlLabel
                    control={<Field name="confirm_terms" type="checkbox"/>}
                    label={t('I have read the terms and agree')}
                />

            </React.Fragment>
        ),
    ];

    const max_steps = steps.length;
    const last_step = max_steps - 1;
    const is_last_step = (activeStep === last_step);

    const minimum_age = (bday, min_years) => {
        return differenceInYears(new Date(), bday) >= min_years;
    }

    const nick_test = (nick) => (
        doTestNickUnique(nick).then(() => {
            return true
        }, () => {
            return false
        })
    );

    const schemas = [
            {
                email: Yup.string().required(),
                passwordOne: Yup.lazy(value => {
                    if (!value) return Yup.string().required();
                    if (value.length < 20) {
                        return Yup.string().required().matches(
                            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
                    } else {
                        return Yup.string().required().min(20, "Must contain at least 20 Characters")
                    }
                }),
                passwordTwo: Yup.string().required().oneOf([Yup.ref('passwordOne'), null], "Passwords do not match"),
            },
            {
                first_name: Yup.string().required(),
                second_name: Yup.string().required(),
            },
            {
                nickname: Yup.string().required().test("nickname", t("sorry, that name is taken"), (value, context) => nick_test(value))
            },
            {
                birthday: Yup.date().required().test('min age', 'minimum age is 16', (value, context) => minimum_age(value, 16))
            },
            {
                confirm_age: Yup.boolean().required().test('age', 'you must be at least 16 years old', (value, context) => value === true),
                confirm_terms: Yup.boolean().required().test('terms', 'you cannot sign up if you do not accept the terms', (value, context) => value === true)
            }
        ]
    ;

    const signUpValues = {
        email: email,
        passwordOne: '',
        passwordTwo: '',
        first_name: '',
        second_name: '',
        nickname: '',
        birthday: null,
        confirm_age: false,
        confirm_terms: false,
    };

    const extract_profile = (formValues) => {
        return {
            nickname: formValues.nickname,
            first_name: formValues.first_name,
            second_name: formValues.second_name,
            birthday: formValues.birthday,
            avatar_url: null,
            legislation: legislation
        };
    };

    const DisplayForm = (props) => {
        const {
            activeStep,
            formikProps
        } = props;

        const {isValid, setFieldValue, values} = formikProps;

        if (!values.email && email) {
            debugger;
            setFieldValue("email", email);
        }


        return (
            <React.Fragment>
                {!is_last_step &&
                    <div>
                        <LazyImage src={staticImageUrl("moodiks_logo.jpg")} alt="friends" className={classes.image}/>
                    </div>
                }

                {/*display the active step*/}
                {steps[activeStep]}

                {is_last_step &&
                    <React.Fragment>

                        <Button disabled={!isValid} type="submit"
                                fullWidth variant="contained"
                                color="primary" className={classes.submit}>
                            {t("sign up")}
                        </Button>
                    </React.Fragment>
                }

            </React.Fragment>
        )
    }

    const MyStepper = (props) => {
        const {
            formikProps
        } = props;

        const {isSubmitting} = formikProps;

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        return (
            <AppBar position="sticky" color="default" className={classes.appBar}>
                <MobileStepper
                    steps={max_steps} activeStep={activeStep}
                    postion="static" variant="dots"
                    nextButton={
                        <Button size="small" type="submit"
                                disabled={isSubmitting || is_last_step}>
                            {t('next')}
                            <KeyboardArrowRight/>
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft/>
                            {t('back')}
                        </Button>
                    }
                />
            </AppBar>
        );
    }


    const initialize_signup = useCallback((ret_val) => {
        debugger;
        setValid(true);
        setLoading(false);

        const lang = ret_val['lang'];
        const email = ret_val['email'];
        const legislation = ret_val['legislation'];
        setEmail(email);
        setTermsText(legal_link('sign_up', legislation, t('lang')));
        i18n.changeLanguage(lang);
    }, [t]);

    useEffect(() => {
        console.log('SignUp: in useEffect');
        debugger;

        if (!referralId) {
            console.log('SignUp: in useEffect -  referral_id not set');
            let {id} = match.params;
            if (id === null || id === ":id") {
                if (firebase.auth && firebase.auth.invite_id) {
                    id = firebase.auth.invite_id;
                    console.log('SignUp: get id from firebase: ', id);
                } else {
                    setValid(false);
                    setLoading(true);
                    console.log('SignUp: no invite_id found')
                    return;
                }
            }
            setValid(false);
            setLoading(true);
            doVerifyInvite(id).then(d => {
                setReferralId(id);
                initialize_signup(d)
            }, r => {
                setValid(false);
                setLoading(false);
            })
        }
    }, [firebase.auth, initialize_signup, match.params, referralId]);


    const submitForm = async (values) => {
        const resolve_referrer = (moodiks, referralId) => {
            return new Promise(resolve => {
                moodiks.doSaveReferral(referralId)
                    .then((d) => {
                        const invited_by = d["referrer"];
                        console.log("SignUp:submitForm: d", d);
                        console.log('successfully referred from :', invited_by);
                        resolve(invited_by)
                    })
                    .catch((e) => {
                        console.log("error: ", e);
                        setSnackBar({
                            open: true,
                            message: t("There was a problem saving your invitation"),
                            severity: "error"
                        })
                    });
            })
        }

        firebase.doCreateUserWithEmailAndPassword(values.email, values.passwordOne)
            .then(() => {
                getMoodiks({firebase: firebase})
                    .then(async (moodiks) => {

                        const invited_by = await resolve_referrer(moodiks, referralId)
                        const profile = extract_profile(values);
                        moodiks.doSaveUserProfile(profile, invited_by)
                            .then(() => {
                                handleContinue()
                            })
                            .catch((e) => {
                                setSnackBar({
                                    open: true,
                                    message: t("There was a problem saving your profile"),
                                    severity: "error"
                                })
                            })
                    })
            })
            .catch(e => setSnackBar({
                open: true,
                message: "unable to create user, probably duplicate",
                severity: "error"
            }))
    }


    const handleSubmit = (values, actions) => {
        if (is_last_step) {
            submitForm(values);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const handleContinue = () => {
        history.push(ROUTES.INTRODUCTION_INSTALL)
    }

    const onReset = () => {
    }

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }

    const bad_invite = !loading && !valid;

    return (
        <Container>
            {loading &&
                <Loading/>
            }
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            {bad_invite &&
                <TextPopUp handler={handleContinue} headline={t('bad invite')} body={t('explain bad invite')}/>
            }
            {valid &&
                <Paper elevation={0} className={classes.paper}>
                    <Formik
                        innerRef={formRef}
                        initialValues={signUpValues}
                        validationSchema={Yup.object().shape(schemas[activeStep])}
                        validateOnMount={true}
                        isInitialValid={false}
                        initialErrors={Object.keys(signUpValues)}
                        onSubmit={handleSubmit}
                        onReset={(values, bag) => onReset(values, bag)}
                    >
                        {(formikProps) => (
                            <React.Fragment>
                                <Form className={classes.form}>
                                    <DisplayForm activeStep={activeStep} formikProps={formikProps}/>
                                    {/*<DisplayFormikState {...formikProps}/>*/}
                                    {/*<ErrorFields formikProps={formikProps}/>*/}
                                    <MyStepper formikProps={formikProps}/>
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                </Paper>
            }
        </Container>
    )
}


SignUpBase.propTypes = {
    classes: PropTypes.object.isRequired,
}

const signUpUrl = (invitedBy) => {
    const hostname = window.location.hostname;
    const server_name = hostname === "localhost" ? 'http://' + '127.0.0.1' + ':4433' : 'https://' + hostname + '/api'
    console.log('SignUpLink: hostname, server_name, invitedBy: ', hostname, server_name, invitedBy);

    return server_name + '/sign_up?invite_id=' + invitedBy;
}

const SignUpRedirect = () => {
    debugger;
    const {invitedBy} = useAuthUserContext();
    window.location.href = signUpUrl(invitedBy)
}

const SignUpLink = () => {

    const {invitedBy} = useAuthUserContext();
    const {t} = useTranslation(['common']);
    const classes = useStyles();


    return (
        <p>
            {t("no account")} <StyledLink className={classes.link}
                                          href={signUpUrl(invitedBy)}>{t("sign up")}</StyledLink>
        </p>
    );
};


const SignUpForm = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
)
(SignUpBase);


export default SignUpPage;
export {SignUpForm, SignUpLink, SignUpRedirect};
