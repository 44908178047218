import React, {useCallback} from "react";
import i18n from "i18next";

const FilterContext = React.createContext(null);
const useFilterContext = () => {
    return React.useContext(FilterContext)
}

const filterReducer = (state, msg) => {
    switch (msg.type) {
        case 'ADD_FILTER':
            console.log('FilterContext: ADD_FILTER: state, msg', state, msg)
            debugger;
            return {...state, filters: [...state.filters, msg.payload.filter]}
        case 'CHANGE_FILTER':
            const changed_index = state.filters.findIndex((f) => f.id === msg.payload.filter.id)
            state.filters[changed_index] = msg.payload.filter;
            console.log('FilterContext: CHANGE_FILTER: ', changed_index, state.filters)
            return {...state, filters: [...state.filters]}
        case 'SET_FILTERS':
            console.log('FilterContext: SET_FILTERS: ', {...state, filters: [...msg.payload.filters]})
            return {...state, filters: [...msg.payload.filters]}
        case 'DELETE_FILTER':
            const new_list = state.filters.filter((f) => f.id !== msg.payload.id)
            return {...state, filters: [...new_list]}
        case 'DELETE_FOLLOWING':
            const deleted_following = state.filters.filter((f) => f.follow_uid !== msg.payload.user_id);
            console.log('FilterContext: DELETE_FOLLOWING: ', {...state, filters: [...deleted_following]})
            return {...state, filters: [...deleted_following]}
        case 'DELETE_FOLLOWING_TAG':
            const deleted_following_tag = state.filters.filter((f) => f.name !== msg.payload.name);
            console.log('FilterContext: DELETE_FOLLOWING_TAG: ', {...state, filters: [...deleted_following_tag]})
            return {...state, filters: [...deleted_following_tag]}
        case 'SET_MOODIKS':
            console.log('FilterContext: setting moodiks');
            return {...state, moodiks: msg.payload.moodiks}
        case 'SET_QUERY_PARAMS':
            console.log('FilterContext: SET_QUERY_PARAMS queryParams', msg.payload.queryParams);
            return {...state, queryParams: msg.payload.queryParams}
        default:
            return state;
    }
}


const useFilters = (initial_filters, initial_query_params) => {

    const initialFilters = React.useRef(initial_filters);

    const [state, dispatch] = React.useReducer(filterReducer,
        {
            filters: initial_filters,
            moodiks: null,
            queryParams: initial_query_params
        });


    const setFilters = useCallback((filters) => {
        if (!!filters)
            dispatch({type: 'SET_FILTERS', payload: {filters: filters}})
    }, []);

    const addFilter = useCallback((filter) => {
        debugger;
        dispatch({type: 'ADD_FILTER', payload: {filter: filter}})
    }, []);

    const changeFilter = useCallback((filter) => {
        dispatch({type: 'CHANGE_FILTER', payload: {filter: filter}})
    }, []);


    const toggleFollow = useCallback(async (user_id, is_following) => {
        const props = {user_id: user_id, is_following: is_following}
        let result;
        try {
            const data = await state.moodiks.doSetIsFollowing(props.user_id, props.is_following)
            const is_following = data['is_following'];
            if (is_following) {
                const filter = data['filter']
                dispatch({type: 'ADD_FILTER', payload: {filter: filter}})
            } else {
                dispatch({type: 'DELETE_FOLLOWING', payload: {user_id: user_id}})
            }
            result = {success: true, message: ''}
        } catch (e) {
            result = {success: false, message: e}
        }
        console.log('FilterContext: toggleFollow result: ', result);
        return result
    }, [state.moodiks]);


    const toggleFollowTag = useCallback((filter, is_following) => {
        let result;
        try {
            console.log('FilterContext: toggleFollowTag: filter: ', filter)
            if (is_following) {
                dispatch({type: 'ADD_FILTER', payload: {filter: filter}})
            } else {
                dispatch({type: 'DELETE_FOLLOWING_TAG', payload: {name: filter.name}})
            }
            result = {success: true, message: ''}
        } catch (e) {
            result = {success: false, message: e}
        }
        console.log('FilterContext: toggleFollowTag result: ', result);
        return result
    }, [])


    const deleteFilter = useCallback(async (id) => {
        const delete_id = (typeof id === 'string') ? toString(id) : id;
        try {
            debugger;
            await state.moodiks.doDeleteFilter(delete_id);
            debugger;
            dispatch({type: 'DELETE_FILTER', payload: {id: delete_id}})
        } catch (e) {
        }
    }, [state.moodiks]);

    const setMoodiks = useCallback((moodiks) => {
        dispatch({type: 'SET_MOODIKS', payload: {moodiks: moodiks}})
    }, []);

    const setQueryParams = useCallback((queryParams) => {
        dispatch({type: 'SET_QUERY_PARAMS', payload: {queryParams: queryParams}})
    }, []);

    const ctx = {
        filters: state.filters,
        queryParams: state.queryParams,
        setFilters,
        addFilter,
        changeFilter,
        toggleFollow,
        toggleFollowTag,
        deleteFilter,
        setMoodiks,
        setQueryParams
    }
    console.log('FilterContext: useFilters state: ', state);
    return ctx
}

export {FilterContext};
export {useFilters};
export {useFilterContext}