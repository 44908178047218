import MoodiksClient from "./Moodiks";


const getMoodiks = async ({firebase}) => {
    const token = await firebase.doGetAccessTokenAsync(true);
    const moodiks = new MoodiksClient(token);
    console.log('--> moodiks created!');
    return moodiks;
}


export {getMoodiks};