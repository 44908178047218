import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import {RecommendationCard} from "./RecommendationCard";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import IconButton from "@material-ui/core/IconButton";
import theme from "../../Theme"
import TagView from "../TagView";
import RollingRoutes, {Route} from "../RollingRoutes";

export const styles = {
    tagField: {
        marginBottom: '10px'
    },
    tag: {
        margin: '2px'
    },
    locationField: {
        marginBottom: '10px',
        verticalAlign: "middle",
        display: 'flex',
    },
    text: {
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block'
    },
    startIcon: {
        position: 'relative',
        top: '5px',
        bottom: '5px',
        marginRight: 16
    },
};

const url_from_location = (address) => {
    const apple_url = 'http://maps.apple.com/?address=';
    const google_url = 'https://wwww.google.com/maps/search/?api=1&query=';
    const base_url = (navigator.userAgent.indexOf('Safari') > 1) ? apple_url : google_url;
    return base_url + address;
}

const make_attribute_list = (recommendation, input_fields) => {
    const evaluate = {
        MENU: (field, value) => {
            // eslint-disable-next-line-eqeqeq
            const option = field.options.filter(opt => opt.id == value);
            return option[0] ? option[0].text : "";
        },
        FREE_TEXT: (field, value) => {
            return value;
        },
    }

    return !!input_fields ?
        input_fields.map((field) => {
            const attribute = {};
            attribute.name = field.name;
            const id = field.name_id.toString();
            const value = recommendation[id];
            attribute.value = evaluate[field.data_type](field, value);
            return attribute;
        })
        : [];
}


const RecommendationViewCardBase = React.forwardRef(function RecommendationViewCardBase(props, ref) {
    const {
        recommendation,
        images,
        attributeGroup = {},
        canEdit = false,
        classes,
        ...other
    } = props;


    const {attributes} = attributeGroup;
    const routes = {
        Keyword: false
    };

    const attribute_list = make_attribute_list(recommendation, attributes);

    const handleTagClick = (routeState, text) => {
        routeState.openRoute('Keyword', {text: text})
    }


    return (
        <React.Fragment>
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <React.Fragment>
                        <RecommendationCard
                            recommendation={recommendation} images={images} showAvatar
                            canEdit={canEdit}
                            subjectClickable={false} experienceExpandable={false}
                            {...other}
                        >
                            <Box className={classes.tagField}>
                                {recommendation.tags.map((tag, i) => (
                                    <Chip className={classes.tag} variant='outlined' key={i.toString()}
                                          label={'#' + tag}
                                          onClick={() => handleTagClick(routeState, tag)}
                                    />
                                ))}
                            </Box>

                            {recommendation.location &&
                            <span className={classes.locationField}>
                                <span className={classes.startIcon}>
                                    <IconButton color='primary'
                                                href={url_from_location(recommendation.location.display_name)}
                                                target='_blank'>
                                        <LocationOnOutlinedIcon/>
                                    </IconButton>
                                </span>
                                <span className={classes.text}>{recommendation.location.display_name}</span>
                            </span>
                            }
                            <Grid>
                                {attribute_list.map((attr, i) => (
                                    <Grid item key={i.toString()}>
                                        <span>{attr.name} </span> <span> : </span><span>{attr.value}</span>
                                    </Grid>
                                ))}
                            </Grid>
                        </RecommendationCard>
                        <Route dialog='Keyword'>
                            <TagView {...routeState.props}/>
                        </Route>
                    </React.Fragment>
                )}
            </RollingRoutes>

        </React.Fragment>
    )

})
RecommendationViewCardBase.propTypes = {
    classes: PropTypes.object.isRequired,
    recommendation: PropTypes.object.isRequired
}


const RecommendationViewCard = withStyles(styles)(RecommendationViewCardBase);
export {RecommendationViewCard};
