import axios from 'axios';
import {toJSON} from "lodash/seq";


// client always uses origin +/api  as BASE_URI
const BASE_URI = window.location.hostname === 'localhost'
    ? 'http://127.0.0.1:4433'
    : "https://" + window.location.hostname + '/api'; //hardcoded!!

const client = axios.create({
    baseURL: BASE_URI,
});

const fileClient = axios.create({
    baseURL: BASE_URI,
});

const doVerifyInvite = (id) => {
    const resource = "/test_referral/" + id;
    return doTestAnon(resource);
};

const doTestNickUnique = (nick) => {
    const resource = "/user_nick_unique_anon/" + nick;
    return doTestAnon(resource);

}

const doTestAnon = (resource) => {
    const method = 'get';
    return client({
        method: method,
        url: resource,
    }).then(resp => {
        return resp.data ? resp.data : [];
    })
}

const remove_brackets = (str) => {
    return str.replace(/[[\]]/g, '')
}

class MoodiksClient {

    constructor(id_Token) {
        this.id_Token = id_Token;
    }

    doGetQuestions(q_name) {
        const resource = `/questionnaire/?name=${q_name}&data_type=questions`;
        console.log(resource);
        return this.perform('get', `/questionnaire/?name=${q_name}&data_type=questions&resume=true`);
    }

    doSaveReferral(id) {
        const resource = "/referral/" + id;
        return this.perform('post', resource);
    }

    doSaveQResponses(questionnaire_id, responses) {
        const resource = "/q_response/" + questionnaire_id.toString();
        return this.perform('post', resource, responses);
    }

    doGetResults(questionnaire_id) {
        const resource = questionnaire_id ? "/q_response/" + questionnaire_id.toString() : "/q_response";
        return this.perform('get', resource);
    }

    doSaveSocDemResults(userData) {
        const response = {"soc_dem_response": userData};
        const resource = "/soc_dem";
        return this.perform('post', resource, response);

    }

    doGetInviteUrl() {
        const resource = "/invitation_url";
        return this.perform('get', resource);
    }

    doGetSocDemResults() {
        return this.perform('get', '/soc_dem');
    }

    doGetUserProfile(strict = false, uid = 0) {
        const uid_query = uid ? "&uid=" + JSON.stringify(uid) : ""
        const resource = "/user_profile/?strict=" + JSON.stringify(strict) + uid_query
        return this.perform('get', resource);
    }

    doGetUserOpenQuestionnaire() {
        return this.perform('get', '/user_open_questionnaire');
    }

    doSaveUserProfile(user_profile, invited_by) {
        let response;
        if (typeof invited_by !== "undefined" && invited_by !== null)
            response = {"user_profile": user_profile, "invited_by": invited_by}
        else
            response = {"user_profile": user_profile};
        const resource = "/user_profile/";
        return this.perform('post', resource, response);
    }

    doTestNickUnique(nick) {
        const resource = "/user_nick_unique/" + nick;
        return this.perform('get', resource)
    }

    doGetOnboardingTasks() {
        const resource = "/onboarding_tasks";
        return this.perform('get', resource)
    }

    doGetNotificationCount() {
        const resource = "/get_notification_count"
        return this.perform('get', resource)
    }

    doSetNotificationsRead(notification_ids) {
        const id_list = {id_list: notification_ids}
        const resource = "/set_notifications_read"
        return this.perform('post', resource, id_list)
    }

    doDeleteNotifications(notification_ids) {
        const id_list = {id_list: notification_ids}
        const resource = "/delete_notifications"
        return this.perform('post', resource, id_list)
    }

    doAnalyseUrl(url) {
        const resource = "/analyse_url?url=" + url;
        return this.perform('get', resource)
    }

    doGetIsFollowing(uid) {
        const resource = "/is_following?follow_uid=" + uid;
        return this.perform('get', resource)
    }

    doSetIsFollowing(uid, status) {
        const resource = "/is_following?follow_uid=" + uid + "&status=" + status;
        return this.perform('post', resource)
    }

    doGetIsFollowingTag(text) {
        const resource = "/is_following_tag?text=" + text;
        return this.perform('get', resource)
    }

    doSetIsFollowingTag(text, status) {
        const resource = "/is_following_tag?text=" + text + "&status=" + status;
        return this.perform('post', resource)
    }

    doPostFilter(f) {
        const filter = {filter: f};
        const resource = "filter";
        return this.perform('post', resource, filter)
    }

    doDeleteFilter(id) {
        const resource = "delete_filter?filter_id=" + id;
        return this.perform('post', resource)
    }

    doSetLike(id, status) {
        const resource = "/like?recommendation_id=" + id + "&status=" + status;
        return this.perform('post', resource)
    }

    doSetBookmark(id, status) {
        const resource = "/bookmark?recommendation_id=" + id + "&status=" + status;
        return this.perform('post', resource)
    }

    doPostComment(recommendation_id, text, responding_to, edit_id) {
        const response_part = responding_to ? "&responding_to=" + responding_to : '';
        const edit_id_part = edit_id ? "&edit_id=" + edit_id : '';
        const resource = "/add_comment?recommendation_id=" + recommendation_id.toString() + "&text=" + text + response_part + edit_id_part;
        return this.perform('post', resource)
    }

    doPostRecommendation(r) {
        const recommendation = {"recommendation": r};
        const resource = "/recommendation";
        return this.perform('post', resource, recommendation);
    }

    doPostResponse(recommendation, responding_id) {
        const response = {"recommendation": recommendation, "respondingId": responding_id};
        const resource = "/recommendation";
        return this.perform('post', resource, response);
    }

    doPostProblem(p) {
        const report = {"problem": p};
        const resource = "/report_problem";
        return this.perform('post', resource, report);
    }

    async doGetCategories(query, callback, endpoint) {
        let resource = "/" + endpoint + "/?";
        Object.keys(query).forEach((param, index) => {
            const join = index ? '&' : ''
            resource = resource + join + param + '=' + query[param]
        })
        callback(await this.perform('get', resource));
    }

    async doGetAutoComplete(query, callback, endpoint) {
        let resource = "/" + endpoint + "/?";
        Object.keys(query).forEach((param, index) => {
            const join = index ? '&' : ''
            resource = resource + join + param + '=' + query[param]
        })
        callback(await this.perform('get', resource));
    }

    doGetTagIds(w) {
        const resource = '/get_tag_ids';
        const wordlist = {"wordlist": w}
        return this.perform('post', resource, wordlist);
    }


    doGetAttributes(ids) {  //takes [int] as parameter!
        const resource = "/category_attributes/?tag_ids=" + remove_brackets(JSON.stringify(ids));
        return this.perform('get', resource)
    }

    doGetMatches() {
        const resource = "/get_matches"
        return this.perform('get', resource)
    }

    doSaveHistory(h) {
        console.log('doSaveHistory', h)
        const history = {'history': h}
        const resource = '/save_history'
        return this.perform('post', resource, history)
    }

    doDeleteHistory(h_id) {
        const id = {'id': h_id}
        const resource = '/delete_history'
        return this.perform('post', resource, id)
    }

    doGetUserStats(uid = 0) {
        const resource = "user_stats/?uid=" + JSON.stringify(uid)
        return this.perform('get', resource)
    }

    doGraphql(query) {
        const resource = "/graphql";
        const body = {"query": query}
        return this.perform('post', resource, body);

    }


    doSaveImage(file, filename) {
        const resource = "/save_image";
        console.log('doSaveImage: file, filename: ', file, filename);
        return this.performFile(resource, file, filename)
    }

    doGetFile(url) {
        return this.getFile(url)
    }

    async getFile(url) {
        return fileClient({
            method: 'get',
            url: url,
            headers: {
                Authorization: `Bearer ${this.id_Token}`
            },
            responseType: 'blob'
        }).then(response => {
            if (response) {
                return URL.createObjectURL(new Blob([response.data], {type: 'image/png'}))
            }
            return Promise.reject('An unknown error occurred');
        });
    }

    async performFile(resource, file) {
        const body = new FormData();
        body.append('image', file);
        return fileClient({
            method: 'post',
            data: body,
            url: resource,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${this.id_Token}`
            }
        }).then(resp => {
            return resp.data ? resp.data : [];
        })
    }

    async perform(method, resource, data) {
        debugger;
        return client({
            method: method,
            url: resource,
            data: data,
            headers: {
                Authorization: `Bearer ${this.id_Token}`
            }
        }).then(resp => {
            return resp.data ? resp.data : [];
        })
    }

}

export {doVerifyInvite, doTestNickUnique}
export {MoodiksClient as default};