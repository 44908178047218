//Provides routing via opening the new route in a fullscreen Dialog
// usage:
//
// import RollingRoutes, {Route} from "../RollingRoutes";
//
// <RollingRoutes initialState={routes}>
//     {(routeState)} =>{
//              <React.Fragment>
//                  <Button onClick={routeState.openRoute('dialog'),{...props}/>
//              </React.Fragment>
// }
//
//             <RollingRoute dialog='dialog'>
//                 <RenderRouteContent
//                      {...routeState.props}
//                 />
//             </RollingRoute>
//
//             or
//             <RollingRoute dialog='dialog' noTopBar>
//                 <RenderRouteContent
//                      {...routeState.props}
//                      onClose={()=>{routeState.closeRoute('dialog')}}
//                 />
//             </RollingRoute>
//     </RollingRoutes>
//
//  <RollingRoutes> takes a function prop as its only children argument exposing the routeState
//
// By default <RollingRoute> provides a top bar with a backButton and the displays any children.
// Any content that provides its own TopBar *must* call
// ()=>{routeState.closeRoute('dialog')}
// passed to an onClose prop


import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import TopBar from "../TopBar";
import ExitButton from "../ExitButton/ExitButton";

export const styles = {};
var fastDeepEqual = require('fast-deep-equal');

const isFunction = (obj) => typeof obj === 'function';


const RollingRouteContext = React.createContext(null);
const RollingRouteProvider = RollingRouteContext.Provider;

const useRollingRouteContext = () => {
    const context = React.useContext(RollingRouteContext);
    if (context === undefined)
        throw new Error('attempt to use context outside InfiniteFeed')
    return context
}

const stateReducer = (state, msg) => {
    switch (msg.type) {
        case 'OPEN':
            // console.log('RollingRoutes: openRoute: returnState: ', {
            //     open: {...state.open, [msg.payload.dialog]: true},
            //     context: msg.payload.context
            // })
            return {open: {...state.open, [msg.payload.dialog]: true}, context: msg.payload.context};
        case 'CLOSE':
            // console.log('RollingRoutes: closeRoute: returnState: ', {
            //     open: {...state.open, [msg.payload.dialog]: false},
            //     context: {}
            // })
            return {open: {...state.open, [msg.payload.dialog]: false}, context: {}};
        default:
            return state;
    }
}


const useRollingRoute = (props) => {
    const {
        initialState: initialStateProp
    } = props;

    const initialState = React.useRef(initialStateProp)

    const [state, dispatch] = React.useReducer(stateReducer,
        {
            open: initialStateProp,
            context: {}
        });


    const openRoute = (dialog, context) => {
        console.log('RollingRoutes: openRoute: dialog, context', dialog, context);
        dispatch({type: 'OPEN', payload: {dialog: dialog, context: context}})
    }
    const closeRoute = (dialog) => {
        console.log('RollingRoutes: closeRoute: dialog: ', dialog);
        dispatch({type: 'CLOSE', payload: {dialog: dialog}})
    }
    const routeContext = (element) => {
        return state.context[element]
    }

    const ctx = {
        open: state.open,
        props: state.context,
        openRoute,
        closeRoute,
        routeContext
    };
    return ctx
}


const RollingRoutes = React.forwardRef(function RollingRouteBase(props, ref) {
    const {
        initialState,
        children,
    } = props;
    const state = useRollingRoute({'initialState': initialState});


    return (
        <RollingRouteProvider value={state}>
            {isFunction(children) &&
            children(state)
            }
        </RollingRouteProvider>
    )
});

RollingRoutes.propTypes = {
    children: PropTypes.func.isRequired,
    initialState: PropTypes.object.isRequired,
}

const RouteBase = React.forwardRef(function RollingRouteBase(props, ref) {
    const {
        dialog,
        children,
        noTopBar = false,
    } = props;
    const {open, closeRoute: handleCloseRoute} = useRollingRouteContext();

    const element_open = open[dialog];
    if (typeof open[dialog] === 'undefined') {
        console.error('RollingRoutes: undefined ', dialog);
        debugger;
        console.trace()
    }

    return (
        <Dialog fullScreen open={element_open}>
            {element_open &&
            <React.Fragment>
                {!noTopBar &&
                <TopBar leftButton={
                    <ExitButton
                        onExit={() => {
                            handleCloseRoute(dialog)
                        }}
                    />}
                />}
                {children}
            </React.Fragment>
            }
        </Dialog>
    )
});

const Route = withStyles(styles)(RouteBase);


Route.propTypes = {
    dialog: PropTypes.string,
    noTopBar: PropTypes.bool,
}


export default RollingRoutes;
export {useRollingRoute, useRollingRouteContext, Route}
