import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import theme from "../../Theme";
import {query_string} from "../../hooks/gql_helpers";

export const styles = {
    gridRoot: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        marginTop: '12px',
        marginBottom: '12px'
    }
};

const TagViewTypeSelectorBase = React.forwardRef(function TagViewTypeSelectorBase(props, ref) {
    const {
        classes,
        onClick,
        queryParams
    } = props;

    const [value, setValue] = React.useState(0);

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0 :
                onClick({...queryParams, related: query_string('False')})
                break;
            case 1:
                onClick({...queryParams, related: query_string('True')})
                break;
            default:
                onClick({...queryParams, related: query_string('False')})
                break
        }
    };

    const {t} = useTranslation(['recommendation']);
    return (
        <div className={classes.gridRoot}>
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab  {...a11yProps(0)} label={'#' + t('tag')} />
                <Tab  {...a11yProps(0)} label={'#' + t('related')} />
                }
            </Tabs>
        </div>
    )

})
TagViewTypeSelectorBase.propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    queryParams: PropTypes.object
}


const TagViewTypeSelector = withStyles(styles)(TagViewTypeSelectorBase);
export {TagViewTypeSelector};
