import React from "react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/styles/withStyles";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import IconButton from "@material-ui/core/IconButton";
import {LocationAPI} from "./LocationAPI";

export const styles = {};

const LocationButton = React.forwardRef(function LocationButton(props, ref) {
    const {
        onClick,
    } = props;

    const handleClick=()=>{
        console.log('LocationButton: handleClick')
        LocationAPI((position)=>{
            onClick(position)
        });
    }


    return (
        <IconButton onClick={handleClick} >
            <MyLocationIcon/>
        </IconButton>
    )
})

LocationButton.propTypes={
    onClick: PropTypes.func,
}

export default withStyles(styles)(LocationButton);