import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Avatar from "@material-ui/core/Avatar";
import {Badge} from "@material-ui/core";
import theme from "../../Theme";
import clsx from "clsx";

export const styles = {
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginBottom: theme.spacing(2)
    },
    editButton: {
        backgroundColor: theme.palette.primary.main,
    },
    editButtonSize: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
};


const AvatarActionBase = React.forwardRef(function AvatarActionBase(props, ref) {
    const {
        src,
        alt,
        icon,
        className,
        classes,
        onClick,
        disabled
    } = props;


    const EditButton = (props) => {
        const {
            className,
            classes,
            icon,
            onClick,
            disabled
        } = props;
        return (
            <ButtonBase onClick={onClick} disabled={disabled}>
                <Avatar className={clsx(className, classes.editButtonSize)}>
                    {icon}
                </Avatar>
            </ButtonBase>
        )
    }

    return (
        <Badge overlap='circular'
               anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
               badgeContent={
                   <EditButton className={classes.editButton} classes={classes} onClick={onClick} icon={icon}
                               disabled={disabled}
                   />
               }
        >
            <Avatar src={src} alt={alt}
                    className={clsx(className, classes.avatar)} />
        </Badge>
    )

})
AvatarActionBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const AvatarAction = withStyles(styles)(AvatarActionBase);
export default AvatarAction;
