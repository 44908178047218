import React from "react";
import throttle from 'lodash/throttle';
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useField} from "formik";
import Chip from "@material-ui/core/Chip";
import {TextField} from "@material-ui/core";

const parse = require('autosuggest-highlight/parse');
const match = require('autosuggest-highlight/match');

export const styles = {
    root: {}
};


const TagAutoCompleteBase = React.forwardRef(function TagAutoCompleteBase(props, ref) {
    const {
        classes,
        name,
        moodiks,
        maxValues = 20,
        maxOptions = 5,
        variant,
        label,
        required,
        helperText,
        saveState,
        onBlur = null,
        onChange = null,
        ...other
    } = props;

    const [field, meta, helpers] = useField(name);
    const {setValue} = helpers;
    const {error} = meta;
    const {value} = field;  //list
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                moodiks.doGetAutoComplete(request, callback, "tags");
            }, 200),
        [moodiks],
    );
    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length > 1)
            fetch({q: inputValue, max_values: maxOptions}, (results) => {

                console.log('TagAutoComplete:useEffect: result_list: ', results);
                if (active) {
                    setOptions(results);
                }
            });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, maxOptions]);

    const handleMoveToFront = (tag) => {
        const new_value = [tag, ...value.filter(t => t !== tag)];
        console.log('TagAutoComplete: handleMoveToFront: setValue: ', new_value);

        setValue(new_value);
    }

    const parseInput = (str) => {
        let trimmed = str.trim();
        if (trimmed.charAt(0) === '#')
            trimmed = trimmed.substring(1)
        console.log('TagAutoComplete:parseInput: str, trimmed: ', str, str.length, trimmed, trimmed.length);
        return trimmed;
    }

    const handleInputChange = (event, newInputValue) => {
        let newInput = parseInput(newInputValue);
        console.log('TagAutoComplete: handleInputChange: newInput, event: ', newInput, event);
        if (newInput.length > 0) {
            if (newInput.slice(-1) === '#') {
                newInput = newInput.slice(0, -1).trim();
                console.log('TagAutoComplete: handleInputChange: setValue: ', newInput);
                const NewValue = [...value, newInput]
                setValue(NewValue);
                setInputValue('')
                if (onChange)
                    onChange(NewValue);
            }
        }
        // if (newInputValue.length > 0)
        setInputValue(newInput);
    }

    const handleBlur = (event, blurFun) => {
        console.log('TagAutoComplete: handleBlur event, inputValue: ', event, inputValue);

        if (inputValue.length > 0) {
            const newValue = [...value, inputValue]
            setValue(newValue);
            if (onChange) onChange(newValue)
        }
        if (blurFun)
            blurFun(event)
    }

    const filterOptions = (T) => {
        if (inputValue.length > 1)
            return T
        else
            return []
    }


    return (
        <React.Fragment>
            <Autocomplete
                name={name}
                multiple
                freeSolo
                options={options}
                limitTags={4}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip onClick={() => handleMoveToFront(option)} variant='outlined'
                              label={'#' + option} {...getTagProps({index})}/>
                    ))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            spellCheck: 'true',
                            required: required ? value.length === 0 : false,
                            onBlur: (event) => handleBlur(event, onBlur)
                        }}
                        required={required}
                        variant={variant}
                        error={error} label={label} helperText={helperText}
                    />
                )}
                renderOption={(option, {inputValue}) => {
                    if (!option || !inputValue || typeof (option) !== 'string' || typeof (inputValue) !== 'string')
                        return;

                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    console.log('TagAutoComplete: renderOption: option:', option);
                    return (
                        <React.Fragment>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        color: 'black',
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                              {part.text}
                            </span>
                            ))}
                        </React.Fragment>
                    );
                }}
                onChange={(event, newValue, reason) => {
                    if (value.length < maxValues || value.length > newValue.length) {
                        setOptions(newValue ? [newValue, ...options] : options);
                        console.log('TagAutoComplete: onChange: newValue:', newValue);
                        setValue(newValue);
                        setInputValue("");
                        if (onChange) onChange(newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    handleInputChange(event, newInputValue)
                }}
                // onClose={handleClose}
                filterOptions={(x) => filterOptions(x)}
                inputValue={inputValue}
                value={value}
                {...other}
            />
        </React.Fragment>
    )

})

//Autocomplete based tag input; using Formik for value and setValue
//each tag is either completed with 'enter' or tags can be entered continueosly and preceded with '#'
//closes on blur
// output is an array of string

TagAutoCompleteBase.propTypes = {
    classes: PropTypes.object.isRequired,
    moodiks: PropTypes.object.isRequired,
    maxValues: PropTypes.number,        //maximum tag that can be entered
    maxOptions: PropTypes.number,       //maximum number of options to be fetched and shown in pop up
    variant: PropTypes.string,          //variant of TextInput
    label: PropTypes.string,            //label for TextInput
    required: PropTypes.bool,
    helperText: PropTypes.string,       //helperText for TextInput
    saveState: PropTypes.object         //dummy to suppress error
}


const TagAutoComplete = withStyles(styles)(TagAutoCompleteBase);
export {TagAutoComplete};
