import React, {useState} from "react";
import withStyles from "@material-ui/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import TimeAgo from "timeago-react";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ReplyIcon from "@material-ui/icons/Reply";
import List from "@material-ui/core/List";
import {PostComment} from "./PostComment"
import {CommentContext} from "./Comments";


export const styles = theme => ({
    indent: {
        paddingLeft: '10px'
    },
    list_item: {
        paddingRight: '96px'
    },
    inline: {
        display: 'inline'
    },
    icon: {
        transform: ' scale(0.8)'
    },
    secondary_action: {
        top: '0%',
        marginTop: '35px',
    },

});

const RenderTreeBase = (props) => {
    const {
        classes,
        comment,
        scrollBottom
    } = props;

    const context = React.useContext(CommentContext)
    const {
        commentsLookup,
        currentUid,
    } = context;


    const [responseInputOpen, setResponseInputOpen] = useState(false);
    const [editInputOpen, setEditInputOpen] = useState(false);

    const handleResponseButton = () => {
        setResponseInputOpen(!responseInputOpen);
        scrollBottom()
    }
    const handleEditButton = () => {
        setEditInputOpen(!editInputOpen)
    }

    const is_self = (currentUid.toString() === comment.uid);
    const replying = comment.comment_id
        ? ' @' + commentsLookup[comment.comment_id].nickname
        : '';



    return (
        <React.Fragment>
            <ListItem
                alignItems="flex-start"
                className={classes.list_item}
                key={comment.id}
            >
                <ListItemAvatar>
                    <Avatar alt={comment.nickname} src={comment.avatar_url}/>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <Typography
                                component='span'
                                color='primary'
                                variant='subtitle2'
                                className={classes.inline}
                            >
                                {comment.nickname}
                            </Typography>
                            <Typography
                                component='span'
                                color='textPrimary'
                                variant='body2'
                                className={classes.inline}
                            >
                                {replying}
                                <span> </span>
                                <TimeAgo datetime={comment.posted_at} locale={comment.lang}/>
                            </Typography>

                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                component='span'
                                color='textPrimary'
                                variant='body2'
                                className={classes.inline}
                            >
                                {comment.text}
                            </Typography>
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction className={classes.secondary_action}>
                    {is_self &&
                    <IconButton onClick={handleEditButton}>
                        <EditIcon className={classes.icon}/>
                    </IconButton>
                    }
                    <IconButton onClick={handleResponseButton}>
                        <ReplyIcon className={classes.icon}/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            {responseInputOpen &&
            <PostComment respondingTo={comment.id} nickname={comment.nickname} handleClose={handleResponseButton}
            />
            }
            {editInputOpen &&
            <PostComment respondingTo={comment.id} nickname={comment.nickname} handleClose={handleEditButton}
                         editing commentId={comment.id}
            />
            }
            {Object.entries(comment.responses).length !== 0
                ? Object.values(comment.responses).map((c) => (
                    <List component='nav' disablePadding className={classes.indent}>
                        <RenderTree
                            comment={c} scrollBottom={scrollBottom}
                        />
                    </List>
                ))
                : null}
        </React.Fragment>
    )
};

const RenderTree = withStyles(styles)(RenderTreeBase)
export {RenderTree}
