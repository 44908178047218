import React, {useCallback, useEffect, useRef, useState} from "react";
import * as ROUTES from '../../constants/routes';
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {useHistory, withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {AuthUserContext, withAuthorization} from "../Session";
import TopBar from "../TopBar";
import {NavigationHideable} from "../Navigation";
import InfiniteFeed from "../InfiniteFeed/InfiniteFeed";
import {getMoodiks} from "../Moodiks";
import Loading from "../Loading"
import {useAsync} from "react-async";
import {query_string, useLazyQuery} from "../../hooks/gql_helpers";
import {get_feed, remapRecommendations} from "./Query";
import {filter_query, remapFilters} from "./FilterQuery";
import {DisplayFilters} from "./DisplayFilters"
import {FilterContext, useFilters} from "./FilterContext";
import Grid from "@material-ui/core/Grid";
import {RecommendationCard} from "../Recommendation";
import {myRecommendationStyles} from "../Recommendation";
import {parse_filter_list} from "../CategoryFilter";
import theme from "../../Theme";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {Badge, IconButton, Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import fastDeepEqual from "fast-deep-equal";


export const styles = {
    root: {
        overflowX: 'hidden',
        backgroundColor: theme.palette.grey["100"],
    },
    infiniteFeed: {
        width: "100%"
    },
    circle: {
        transform: "scale(0.7)"
    },
    icon: {
        transform: "scale(1.2)"
    },
    RECOMMENDATION: myRecommendationStyles.RECOMMENDATION,
    REQUEST: myRecommendationStyles.REQUEST
};


const extractPagination = (raw) => {
    return raw.data.recommendationsActiveFiltered.pageInfo;
}

const mapper = {'data': remapRecommendations, 'pagination': extractPagination}
const filter_mapper = {'data': remapFilters}

const FeedBase = (props) => {
    const {
        classes,
        firebase,
    } = props;

    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});

    const [inviteValid, setInviteValid] = useState(false);
    const {invitedBy, setInviteId} = React.useContext(AuthUserContext);
    const [{data, loading_filters, filters_error, pagination}, getFilters] = useLazyQuery(firebase, filter_mapper);
    const [filtersValid, setFiltersValid] = useState(false);

    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;
    const [notificationCount, setNotificationCount] = useState(null);
    const notificationCountRef = useRef(null)

    const filterState = useFilters([], {fType: query_string('CATEGORY')});
    const {queryParams, setFilters, setMoodiks} = filterState;
    const {t} = useTranslation(['recommendation']);


    const NotificationButton = (props) => {
        const {
            notificationCount = 0
        } = props;
        const {total, unread} = notificationCount;

        const no_text = !unread || unread === 0;
        const no_messages = !total || total === 0;

        const history = useHistory();
        const icon = no_messages ? <NotificationsNoneIcon/> : <NotificationsIcon/>
        const onClick = no_messages
            ? () => {
                setSnackBar({open: true, message: t('no alerts'), severity: 'info'})
            }
            : () => {
                history.push(ROUTES.NOTIFICATION_CENTER)
            }

        return (
            <IconButton
                onClick={onClick}>
                <Badge color="secondary" badgeContent={unread.toString()} invisible={no_text}>
                    {icon}
                </Badge>
            </IconButton>
        )
    }

    const getAlarms = useCallback(() => {
        moodiks.doGetNotificationCount()
            .then((data) => {
                console.log('Feed: notificationCount: ', data);
                const {count} = data;
                if (!fastDeepEqual(count, notificationCountRef.current)) {
                    notificationCountRef.current = count;
                    setNotificationCount(count)
                }
            })
    }, [moodiks])


    console.log('Feed: filter_state: ', filterState);
    useEffect(() => {
        if (moodiksValid && !invitedBy)
            moodiks.doGetInviteUrl()
                .then((data) => {
                    const url = data['url'];
                    const uid = data['uid'];
                    firebase.setUid(uid);
                    console.log('Feed: firebase.SetUid: ', uid, typeof uid);
                    const param_string = url.split('?')[1];
                    const params = new URLSearchParams(param_string);
                    const invite_id = params.get('invite_id');

                    console.log('Feed: inviteId: ', invite_id);
                    setInviteId(invite_id);
                    setInviteValid(true);
                });
    }, [firebase, invitedBy, moodiks, moodiksValid, setInviteId]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (moodiksValid) getAlarms()
        }, 2 * 60000)
        if (moodiksValid) getAlarms()

        return () => clearInterval(interval)
    }, [getAlarms, moodiksValid])

    useEffect(() => {
        setMoodiks(moodiks);
    }, [moodiks, setMoodiks])

    useEffect(() => {
        getFilters(filter_query({})).then(() => {
            setFiltersValid(true);
            console.log('Feed: useEffect: got data!: ');
        }).catch((e) => console.log('Feed: error fetching filters: ', e))
    }, [getFilters]);


    useEffect(() => {
        console.log('Feed: useEffect: data, filters: ', data, parse_filter_list(data));
        setFilters(parse_filter_list(data))
    }, [data, setFilters])

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }

    const valid = inviteValid && filtersValid;
    const rightButton = notificationCount ? <NotificationButton notificationCount={notificationCount}/> : null;
    return (
        <div>
            <TopBar
                rightButton={rightButton}
            />
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            {!valid &&
            <div>
                <Loading/>
            </div>
            }
            {valid &&
            <div className={classes.root}>
                <FilterContext.Provider value={filterState}>
                    <DisplayFilters/>
                    <Grid container direction="column" position="static" component="main"
                          className={classes.infiniteFeed}>
                        <InfiniteFeed
                            query={get_feed} mapper={mapper} showAvatar={true}
                            queryParams={queryParams}
                        >
                            {(recommendation, i, hideShare, props) => (
                                <Grid item key={props.key}>
                                    <RecommendationCard
                                        recommendation={recommendation}
                                        images={recommendation.images}
                                        key={i.toString()}
                                        hideShare={hideShare}
                                        {...props}
                                    />
                                </Grid>
                            )}
                        </InfiniteFeed>
                    </Grid>
                </FilterContext.Provider>
            </div>
            }
            <NavigationHideable/>
        </div>
    )
}


FeedBase.propTypes = {
    classes: PropTypes.object.isRequired,
}

const if_authorized = authUser => !!authUser;
const Feed = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(FeedBase);


export default Feed;
