import app from 'firebase/app';
import 'firebase/auth';
import config from "./firebaseConfig";


class Firebase {
    constructor() {
        app.initializeApp(config);
        this.auth = app.auth();
    }

    doSignInAnonymously = () =>
        this.auth.signInAnonymously();

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doEmailUpdate = (email) =>
        this.auth.currentUser.updateEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    //doGetAccessToken = () => this.auth.currentUser;
    doGetAccessToken = (save_id_Token) => this.auth.currentUser.getIdToken(true)
        .then(function (id_Token) {
            save_id_Token(id_Token);
        })

    doGetAccessTokenAsync = forceRefresh => this.auth.currentUser.getIdToken(forceRefresh);

    isAnonymous = () => {
        return this.auth.currentUser.isAnonymous;
    }

    setUid = (uid) => {
        if (typeof (uid) === "number")
            this.auth.currentUser.moodiks_uid = uid
        else
            this.auth.currentUser.moodiks_uid = parseInt(uid)
    }

    getUid = () => {
        try {
            return this.auth.currentUser.moodiks_uid
        } catch (e) {
            return null
        }
    }

}

export default Firebase;


