import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

export const styles = {
        root: {
            zIndex: "10000",
            overflow: "hidden",
            position: "absolute",
            width: "100 %",
            height: "100 %",
            display: "flex",
            justifyContent: "center",/*centers items on the line (the x-axis by default)*/
            alignItems: "center"/*centers items on the cross-axis (y by default)*/
        },
        child: {
            position: "fixed",
            top: "50%",
            left: " 50%",
            webkitTransform: "translate(-50%, -50%)",
            transform: "translate(-50 %, -50 %)"
        },
    }
;

const LoadingBase = React.forwardRef(function LoadingBase(props, ref) {
    const {
        classes
    } = props;


    return (
        <Box  className={classes.root}>
            <CircularProgress className={classes.child}/>
        </Box>
    )
})
LoadingBase.propTypes = {}


const Loading = withStyles(styles)(LoadingBase);
export default Loading;
