import React from "react";

const TextEllipsesWords = (props) => {
    const {
        text,
        maxWords,
        component,
        renderEllipses,
        ignore = false
    } = props;


    let Component = component;

    const trunc_text = (text, max_words) => {
        const words = text.split(" ");
        const trunc_words = words.slice(0, max_words);
        return trunc_words.join(" ")
    }


    const display_text = ignore ? text : trunc_text(text, maxWords);
    return (
        <Component>
            {display_text}
            {!ignore && renderEllipses()}
        </Component>
    )
}

export {TextEllipsesWords}