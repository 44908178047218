import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTranslation} from "react-i18next";
import React from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import TopLine from "../TopBar";
import ExitButton from "../ExitButton/ExitButton";
import GridList from "@material-ui/core/GridList";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ImageListItem from "@material-ui/core/ImageListItem";
import LazyImage from "../LazyImage";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../Theme";


import {Field, useField,} from 'formik';
import Button from "@material-ui/core/Button";
import {uploadEventHandler} from "../../utils/ImageUploads"
import ImageList from "@material-ui/core/ImageList";


const styles = {
    back_button: {
        position: 'absolute',
        top: '1%',
        left: '1%',
    },
    gridList: {
        transform: 'translateZ(0)'
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        height: 'auto',
    },
    input: {
        display: "none"
    },
    tickSize: {
        transform: "scale(2.0)",
    },

    title: {
        color: theme.palette.background.paper,
        fontSize: '0.7rem'
    },
    title_button: {
        color: theme.palette.background.paper,
    },

    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },

}


const useStyles = makeStyles((theme) => (styles));

const UploadButton = (props) => {
    const {
        className,
        name,
        onUpload
    } = props;

    const [field, meta, helpers] = useField(name);
    const {value} = field;
    const {setValue} = helpers;
    const classes = useStyles();

    async function handleUploadClick(event) {
        const result = await uploadEventHandler(event);
        onUpload(result, value, setValue);
    }

    return (
        <div className={className}>
            <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                style={{display: 'none'}}
                type="file"
                onChange={handleUploadClick}
            />
            <label htmlFor="contained-button-file">
                <Button component="span">
                    <AddAPhotoIcon/>
                </Button>
            </label>
        </div>
    )
}

const ImagePicker = (props) => {
    const {
        title,
        startIcon = null,
        images,
        open,
        onClose,
        onUpload,
        name
    } = props

    const isPhone = useMediaQuery('(max-width:600px)')
    const num_cols = isPhone ? 2 : 3;
    const classes = useStyles();
    const {t} = useTranslation(['recommendation']);
    const [field, meta, helpers] = useField(name);
    const {value} = field;

    console.log(value);


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });


    return (
        <Dialog fullScreen open={open}>
            <TopLine
                leftButton={<ExitButton className={classes.back_button} onExit={onClose}/>}
                startIcon={startIcon}
                title={t(title)}
                rightButton={<UploadButton name={name} onUpload={onUpload}/>}
            />
            <ImageList cols={num_cols} className={classes.gridList}>
                {images.map((image, i) => (
                    <ImageListItem key={`${i}`} style={{height: 'auto'}}>
                        <LazyImage className={classes.image} src={image.src} alt={image.alt}/>
                        <ImageListItemBar
                            title={image.alt}
                            classes={{root: classes.titleBar, title: classes.title}}
                            actionIcon={
                                <Field type="checkbox" className={classes.tickSize} name={name} value={`${i}`}
                                />
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Dialog>
    );
}


export default ImagePicker;
export {UploadButton};
