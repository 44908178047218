import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {useField} from "formik";
import Box from "@material-ui/core/Box";
import ChipFactory from "../ChipFactory";
import {moodiksColors} from "../Styles/palette";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";


const options_sonja = [
    'Home',
    'Unterhaltung',
    'Kunst',
    'Wissen',
    'Business',
    'Handwerk',
    'Soziales Engagement',
    'Musik',
    'Mode Beauty',
    'Sport',
    'Essen',
    'Reisen',
    'Gesundheit',
    'Sonstiges',
];


const options = [
    'sports & entertainment',
    'fashion & beauty & health',
    'education & art & culture',
    'house & garden & DIY',
    'business',
    'charity & community',
    'food & travel',
    'other'
];
//
// const options = [
//     'Sport & Unterhaltung',
//     'Mode & Beauty & Gesundheit',
//     'Bildung & Kunst & Kultur',
//     'Haus & Garten & Handwerk',
//     'Business',
//     'Soziales Engagement',
//     'Essen & Reisen',
//     'Sonstiges'
// ];

export const styles = {
    root: {},
    chip: {
        margin: 1
    }
};

const SuperCategoryMenuBase = React.forwardRef(function SuperCategoryMenuBase(props, ref) {
    const {
        name,
        className,
        classes,
        maxChecked = 2,
        variant,
        label,
        required = false,
        ns = 'recommendation'
    } = props;

    const [field, meta, helpers] = useField(name);
    const {setValue} = helpers;
    const {error} = meta
    const {value = []} = field;  //list
    const BlueChip = ChipFactory(moodiksColors.moodiks_blue);
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation([ns]);

    const get_options=(options)=>{
        return options.map((o)=>t(o))
    }

    const optionsRef = React.useRef(get_options(options));
    // if (!fastDeepEqual(optionsRef.current, options)) {
    //     optionsRef.current = options
    // }


    const handleTagDelete = (key) => {
        const newValue = value.filter((category) => category !== key);
        setValue(newValue);
        setOpen(false);
    }


    const handleChange = (event) => {
        if (value.length >= maxChecked)
            return;

        const {target} = event;
        const {value: new_value} = target;
        setValue(
            // On autofill we get a the stringified value.
            typeof new_value === 'string' ? new_value.split(',') : new_value,
        );
        setOpen(false);
    };


    const isSelected = (tag, value) => {
        return value.indexOf(tag) === -1
            ? (<BlueChip label={tag} color='default'
                         className={classes.chip}
            />)
            :
            (<BlueChip label={tag} color='default'
                       className={classes.chip}
                       onDelete={() => handleTagDelete(tag)}
            />)

    }

    const onOpen = () => {
        setOpen(true)
    };
    const onClose = () => setOpen(false);

    return (
        <React.Fragment>
            <FormControl
                fullWidth
                variant={variant}
                className={clsx(classes.root, className)}
                sx={{backgroundColor: 'white'}}
                required={required}
                error={!!error}
            >
                <InputLabel id='aria-label'>{label}</InputLabel>

                <Select
                    name={name}
                    multiple
                    value={value}
                    input={<OutlinedInput label={label}/>}
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((tag, i) => (
                                <BlueChip key={tag} label={tag}
                                          color='default' className={classes.chip}
                                />
                            ))}
                        </Box>
                    )}
                >
                    {optionsRef.current.map((tag, i) => (
                        <MenuItem
                            key={tag}
                            value={tag}
                        >
                            {isSelected(tag, value)}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{t(error)}</FormHelperText>
            </FormControl>
        </React.Fragment>
    )

})
SuperCategoryMenuBase.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    maxChecked: PropTypes.number,
    variant: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    ns: PropTypes.string
}


const SuperCategoryMenu = withStyles(styles)(SuperCategoryMenuBase);
export default SuperCategoryMenu;
