import React from "react";
import {compose} from "recompose";
import withStyles from "@material-ui/styles/withStyles";
import {styled} from '@material-ui/styles'
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {withRouter} from 'react-router-dom';
import theme from "../../Theme";
import Square from "../Square";
import Box from "@material-ui/core/Box";
import {TextEllipses} from "../TextUtils";

const LinkButton = styled(Button)(({theme}) => ({
    '&:disabled, &.Mui-disabled': {
        color: 'black'
    },
    padding: '0px'
}));

export const styles = {
    /* Styles applied to the root element. */
    root: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
    },
    /* Styles applied to the avatar element. */
    avatar: {
        flex: '0 0 auto',
    },
    /* Styles applied to the action element. */
    action: {
        flex: '0 0 auto',
        alignSelf: 'flex-start',
        marginTop: -8,
        marginRight: -8,
    },
    /* Styles applied to the content wrapper element. */
    content: {
        flex: '1 1 auto',
        width: "200px",
    },

    button: {
        padding: 0,
        width: '100%',
        whiteSpace: 'normal',
        display: 'block',
    },
    /* Styles applied to the subheader  element. */
    subheader: {
        color: "black",
        fontWeight: 600,
        fontSize: "0.75rem",
        lineHeight: 1.66,
        letterSpacing: "0.03333em",
        textAlign: "center",
        justifyContent: 'center',
    },
    /* Styles applied to the tile element, if titleBottom==true */
    titleBottom: {
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center",
        justifyContent: 'center',
    }
};


const RecommendationCardHeaderBase = React.forwardRef(function RecommendationCardHeader(props, ref) {
    const {
        avatar: avatar_prop,
        classes,
        deactivated = false,
        component: Component = 'div',
        topLine: topLineProp = '',
        actionColor,
        subheader: subheaderProp = '',
        title: titleProp,
        titleBottom = false,
        noActions = true,
        subjectClickable = true,
        handleOpen = null,
    } = props;

    const center_width = '180px';
    const avatar = avatar_prop
        ? avatar_prop
        : (<Box sx={{minWidth: '50px'}}/>)

    const disabled = noActions || !subjectClickable;
    console.log('RecommendationCardHeader: subjectClickable, disabled: ', subjectClickable, disabled);
    let title = titleProp;
    if (title != null && title.type !== Typography) {
        title = (
            <LinkButton
                onClick={handleOpen}
                disabled={disabled} variant='text'
            >
                <TextEllipses
                    width={center_width}
                    sx={{
                        color: deactivated ? "textSecondary" : "textPrimary",
                        typography: avatar ? 'h5' : 'h4',
                        textTransform: 'none',
                        textDecoration: disabled ? 'none' : 'underline',
                        textAlign: 'center',
                        overflow: disabled ? 'visible' : 'hidden',
                        whiteSpace: disabled ? 'normal' : 'nowrap'
                    }}
                >
                    {titleProp}
                </TextEllipses>
            </LinkButton>
        )
    }

    let subheader = subheaderProp;
    if (subheader != null && subheader.type !== Typography) {
        subheader = (
            <TextEllipses
                width={center_width}
                className={classes.subheader}
                sx={{
                    typography: avatar ? 'caption' : "body1"
                }}
            >
                {subheaderProp}
            </TextEllipses>
        );
    }

    let topLine = topLineProp;
    if (topLine != null && topLine.type !== Typography) {
        topLine = <TextEllipses
            width={center_width}
            className={classes.subheader}
            sx={{
                typography: avatar ? 'caption' : "body1"
            }}
        >
            {topLineProp}
        </TextEllipses>
    }

    return (
        <Component>
            <Box sx={{display: 'grid', gridTemplateColumns: 'repeat(3,1fr)'}}
                 className={classes.root}>
                {avatar &&
                <Box className={classes.avatar}>
                    {avatar}
                </Box>
                }
                <Box className={classes.content}>
                    <Box sx={{justifyContent: 'center', display: 'grid', gridTemplateRows: '15px 1fr 20px'}}>
                        {topLine}
                        {!titleBottom && title}
                        {subheader}
                    </Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Square size={5} color={actionColor}/>
                </Box>

            </Box>
            {titleBottom &&
            <Box className={classes.titleBottom}>{title}</Box>}
        </Component>
    );
});

const RecommendationCardHeader = compose(
    withStyles(styles),
    withRouter
)(RecommendationCardHeaderBase);

export default RecommendationCardHeader;