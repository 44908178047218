import axios from 'axios';
import {template,remap, look_up} from "../../utils/TransformData";


const provider = {
    location: {
        name: "location_iq autocomplete",
        client: axios.create({
            baseURL: 'https://eu1.locationiq.com',
            json: true
        }),
        access_token: "d78067f5a73f4e",
        search: template`/v1/autocomplete.php?key=${'access_token'}&q=${'q'}&limit=${'limit_str'}`,
        display: {
            line1: 'display_place',
            line2: 'display_address',
            display: 'display_name'
        },
        location_template: {
            lat: 'lat',
            lon: 'lon',
            display_name: 'display_name',
            address: {
                road: ["address.road", "address.name"],
                house_number: "address.house_number",
                city: ["address.city", "address.name", "address.state", "address.suburb", "address.name"],
                state: "address.state",
                postcode: "address.postcode",
                country: "address.country"
            }
        }
    },
    reverse_geocode: {
        name: "location_iq reverse geocode",
        client: axios.create({
            baseURL: 'https://eu1.locationiq.com',
            json: true
        }),
        access_token: "d78067f5a73f4e",
        search: template`/v1/reverse.php?key=${'access_token'}&lat=${'latitude'}&lon=${'longitude'}&format=json`,
        display: {
            line1: 'display_address',
            line2: 'address.city',
            display: 'display_name'
        },
        location_template: {
            lat: 'lat',
            lon: 'lon',
            display_name: 'display_name',
            address: {
                road: "address.road",
                house_number: "address.house_number",
                city: ["address.city", "address.state", "address.suburb"],
                state: "address.state",
                postcode: "address.postcode",
                country: "address.country"
            }
        }
    },
    web: {
        name: "bing search",
        client: axios.create({
            baseURL: 'https://westeurope.api.cognitive.microsoft.com/bing/v7.0/search',
            json: true
        }),
        access_token: "d78067f5a73f4e",
        key1: "7035a22f085042fc8bc6f0b9477a6cf9",
        key2: "da4fd92b6ff34dc28cada6d3127ca340",
        subscriptionID: "509c40c0-b3ff-46e9-9cec-9c018cd03d34",
        search: template`?q=${'q'}&count=${'limit_str'}&SafeSearch=strict`,
        headers: {
            "Ocp-Apim-Subscription-Key": "509c40c0-b3ff-46e9-9cec-9c018cd03d34",
        },
        display: {
            line1: 'display_place',
            line2: 'display_address',
            display: 'display_name'
        }
    }
}

class SearchApi {
    constructor(service, lang) {
        this.service = provider[service];
        this.lang = lang;
    }

    build_query = (request, access_token) => {
        const q = request.q;
        const limit_str = request.limit ? request.limit.toString() : "10";
        return (this.service.search({'access_token': access_token, 'q': q, 'limit_str': limit_str}));
    }
    build_reverse_geocode = (request, access_token) => {
        const latitude = request.latitude;
        const longitude = request.longitude;
        return (this.service.search(
            {
                'access_token': access_token,
                'latitude': latitude,
                'longitude': longitude,
                'accept-language': this.lang + ",en"
            }
        ));
    }

    async autocomplete(request, callback) {
        const access_token = this.service.access_token;
        const headers = this.service.headers;
        let resource = "";
        if ('q' in request) {
            resource = this.build_query(request, access_token);
        } else {
            resource = this.build_reverse_geocode(request, access_token);
        }
        return this.perform('get', resource, headers, callback);
    }


    display = (data, field_name) => {
        return look_up(data, this.service.display[field_name]);
    }

    location_object = (data) => {
        return remap(data, this.service.location_template);
    }

    async perform(method, resource, headers, callback, data) {
        return this.service.client({
            method: method,
            url: resource,
            data: data,
            headers: headers
        }).then(resp => {
                callback(resp.data ? resp.data : []);
            }, reason => {
                console.log(reason);
            }
        )
    }

}

export {SearchApi as default}
