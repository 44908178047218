import i18next from "i18next";

const profile_template = {
    nickname: 'nickname',
    avatar_url: 'avatarUrl',
}
const remap_user_list = (user_list, template) => {
    if (user_list && user_list.edges)
        return user_list.edges.map((l) => {
            const user = remap(l.node, template);
            user.uid = parseInt(l.node.uid)
            return user
        })
    else return null;
}
const likes = (liked_by) => {
        return remap_user_list(liked_by, profile_template)
}

const bookmarks = (bookmarked_by) =>{
    return remap_user_list(bookmarked_by, {})
}

const tag_text = (tag) => {
    const lang = i18next.language;
    const keywords = tag.keywords;

    for (let k in keywords) {
        if (keywords[k].lang === lang)
            return keywords[k].text
    }
    return ''
}
const category_text = (category) => {
    return tag_text(category)
}
const attribute_values = (attr_list) => {
    const result = [];
    attr_list.forEach(a => {
        const att = {};
        att.name = a.attributeName.id;
        att.value = a.valueText ? a.valueText : a.value.id;
        result.push(att)
    })
    return result
}
const look_up_one = (data, field_name) => {
    if (!data || !field_name || field_name === "")
        return "";
    const path = field_name.split('.');
    if (!([path[0]] in data) || data[path[0]] == null) return;
    return path.reduce((a, v) => a[v], data);
}
const look_up_list = (data, field_name_list) => {
    for (let i = 0; i < field_name_list.length; i++) {
        const result = look_up_one(data, field_name_list[i]);
        if (result && result !== "") return result;
    }
    return "";
}
const look_up = (data, field_name) => {
    if (!data) return "";
    if (typeof (field_name) === 'string')
        return look_up_one(data, field_name)
    else return look_up_list(data, field_name);
}
const remap = (data, template) => {
    const traverse = (obj) => {
        const r = {};
        for (let field in obj) {
            if (typeof obj[field] == 'object' && !Array.isArray(obj[field]) && obj[field] !== null) {
                r[field] = traverse(obj[field]);
            } else {
                r[field] = look_up(data, obj[field]);
            }
        }
        return r;
    };
    return traverse(template);
}

function template(strings, ...keys) {
    return (function (...values) {
        let dict = values[values.length - 1] || {};
        let result = [strings[0]];
        keys.forEach(function (key, i) {
            let value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    });
}

export {template};
export {remap};
export {look_up};
export {attribute_values};
export {category_text};
export {likes};
export {bookmarks};