import {moodiksColors} from "../Styles/palette"

export const recommendationStyles = {
    RECOMMENDATION: {
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: moodiksColors.moodiks_green,
        background: "",
        color: "black"
    },
    REQUEST: {
        // background: "linear-gradient(0deg, rgba(174,0,255,0.6) 0%, rgba(242,0,255,0.6) 66% )",
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: moodiksColors.moodiks_magenta,
        background: "",
        color: "black"
    },
    RESPONSE: {
        // background: "linear-gradient(0deg, rgba(174,0,255,0.6) 0%, rgba(242,0,255,0.6) 66% )",
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: moodiksColors.moodiks_green,
        background: "",
        color: "black"
    }
};

const myRecommendationStyles = {
    RECOMMENDATION: {
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: moodiksColors.moodiks_green,
        background: "",
    },
    REQUEST: {
        borderStyle: 'solid',
        borderWidth: '3px',
        borderColor: moodiksColors.moodiks_magenta,
        color: "black",
        background: "",
    },
    DEACTIVATED: {
        borderStyle: 'none',
        color: "textSecondary",
        background: "",
    }
};

export {myRecommendationStyles};