import React from "react";
import {compose} from "recompose";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Avatar from "@material-ui/core/Avatar";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import clsx from "clsx";
import {TextEllipses} from "../TextUtils";

const avatar_width = 60;

export const styles = {
    button_root: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: "center",
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        maxWidth: avatar_width,
        textAlign: "center",
    },
    avatar: {
        flex: '0 0 auto',
    },
    text: {
        textOverflow: 'ellipsis',
        textAlign: 'center',
    },
};

const useStyles = makeStyles((theme) => (styles));

const AvatarButtonBase = React.forwardRef(function AvatarButton(props, ref) {
    const {
        avatarUrl,
        nickname,
        onClick,
        classes,
        className
    } = props;


    return (
        <ButtonBase onClick={onClick} className={clsx(classes.button_root, className)}>
            <MoodiksAvatar
                avatar={<Avatar src={avatarUrl} alt={nickname}/>}
                text={nickname}
            />
        </ButtonBase>
    );
})

AvatarButtonBase.propTypes = {
    classes: PropTypes.object,
    avatarUrl: PropTypes.string,
    nickname: PropTypes.string.isRequired,
}

const MoodiksAvatar = React.forwardRef(function MoodiksAvatar(props, ref) {
    const {
        avatar,
        component: Component = 'div',
        text: textProp = '',
        textTypographyProps = {},
        disableTypography = false,
        direction = 'column',
        justify = 'flex-start',
        alignItems = 'center'
    } = props;

    const classes = useStyles();


    let text = textProp;
    if (text != null && text.type !== Typography && !disableTypography) {
        text = (
            <TextEllipses
                width={avatar_width}
                className={classes.text}
                sx={{
                    color: "textSecondary",
                    typography: avatar ? 'body2' : 'h5'
                }}
                {...textTypographyProps}
            >
                {text}
            </TextEllipses>
        );
    }

    return (
        <Component className={classes.root}>
            <Grid container
                  direction={direction}
                  justifyContent={justify}
                  alignItems={alignItems}
            >
                {avatar && <Grid item className={classes.avatar}>{avatar}</Grid>}
                {text && <Grid item zeroMinWidth className={classes.text}>{text}</Grid>}
            </Grid>
        </Component>
    );

});

MoodiksAvatar.propTypes = {
    avatar: PropTypes.object,
    text: PropTypes.string,
    direction: PropTypes.string,
    justify: PropTypes.string,
    alignItems: PropTypes.string
}

const AvatarButton = compose(
    withRouter,
    withStyles(styles)
)(AvatarButtonBase);

export default {MoodiksAvatar};
export {AvatarButton};