function LocationAPI(positionCallback) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(positionCallback, positionError, {
            enableHighAccuracy: false,
            timeout: 15000,
            maximumAge: 0
        });
    } else{
        console.error("Location information is unavailable.");
    }
}

/*
    ### EXAMPLE CALLBAcK ####


function setCurrentPosition(position) {
    document.querySelector('.accuracy').innerHTML = position.coords.accuracy;
    document.querySelector('.altitude').innerHTML = position.coords.altitude;
    document.querySelector('.altitudeAccuracy').innerHTML = position.coords.altitudeAccuracy;
    document.querySelector('.heading').innerHTML = position.coords.heading;
    document.querySelector('.latitude').innerHTML = position.coords.latitude;
    document.querySelector('.longitude').innerHTML = position.coords.longitude;
    document.querySelector('.speed').innerHTML = position.coords.speed;
}
*/

function positionError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            console.error("User denied the request for Geolocation.");
            break;
        case error.POSITION_UNAVAILABLE:
            console.error("Location information is unavailable.");
            break;
        case error.TIMEOUT:
            console.error("The request to get user location timed out.");
            break;
        default:
            console.error("An unknown error occurred.");
            break;
    }
}

export {LocationAPI};