import {gql, graphID_to_int, point_to_coords, resolve_path, reduce_tags} from "../../hooks/gql_helpers";
import {attribute_values, category_text, likes, bookmarks, remap} from "../../utils/TransformData";


const get_recommendation = gql`{
    recommendationsById(id: ${'id'}) {
    edges {
      node {
        id
        url
        type
        deactivated
        lang
        createdAt
        images{
            url
            alt
         }
        subject
        supercategories{
          keywords{
            lang
            text
          }
        }
        category {
          keywords {
            lang
            text
          }
        }
        tags {
          keywords {
            lang
            text
          }
        }
        location {
          coords
          displayName
          road
          houseNumber
          city
          state
          postcode
          country
        }
        experience
        attributeValues{
          valueText
          attributeName{
            id
          }
          value{
            id
          }
        }
      }
    }
  }
}`

const recommendation_template = {
    url: 'url',
    subject: 'subject',
    deactivated: 'deactivated',
    type: 'type',
    lang: 'lang',
    created_at: 'createdAt',
    location: {
        display_name: 'location.displayName',
        address: {
            road: "location.road",
            house_number: "location.houseNumber",
            city: "location.city",
            state: "location.state",
            postcode: "location.postcode",
            country: "location.country",
        },
    },
    num_comments: 'comments.totalCount',
    num_likes: 'likedBy.totalCount',
    num_bookmarks: 'bookmarkedBy.totalCount',
    num_responses:'relatedRecommendations.totalCount',
    experience: 'experience'
};

const image_template = {
    src: 'url',
    alt: 'alt'
}


const remapRecommendations = (raw, prefix) => {
    const offset = (prefix && prefix.length != 0) ? prefix : 'data.recommendationsById.edges';
    const startpoint = resolve_path(offset, raw);

    return startpoint.map((r) => {
        const mapped = remap(r.node, recommendation_template);

        mapped.id = graphID_to_int(r.node.id);
        if (r.node.location) {
            const coords = point_to_coords(r.node.location.coords)
            mapped.location.lat = coords.lat;
            mapped.location.lon = coords.lon;
        } else {
            mapped.location = null;
        }
        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.supercategories = reduce_tags(r.node.supercategories, r.node.lang);
        console.log('remap: category, tags, supercategories', mapped.category, mapped.tags, mapped.supercategories)
        if (r.node.attributeValues !== undefined && r.node.attributeValues.length !== 0) {
            const a_values = attribute_values(r.node.attributeValues);
            a_values.forEach(attribute_value => mapped[attribute_value.name] = attribute_value.value)
        }
        mapped.liked_by = likes(r.node.likedBy);
        mapped.bookmarked_by = bookmarks(r.node.bookmarkedBy);
        mapped.images = r.node.images.map((i) => {
            return remap(i, image_template);
        });
        return mapped
    });
}

export {get_recommendation, remapRecommendations};