import {useCallback, useRef, useState} from "react";
import MoodiksClient from "../components/Moodiks";
import fastDeepEqual from "fast-deep-equal";


function gql(strings, ...keys) {
    return (function (...values) {
        let dict = values[values.length - 1] || {};
        let result = [strings[0]];
        keys.forEach(function (key, i) {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        console.log('gql: ',result.join(''))
        return result.join('');
    });
}

const reduce_tags=(tag_objs, lang)=> {
    const tag_reducer = (tags, keywords) => {
        console.log(keywords);
        const keyword_l = keywords.keywords.filter((keyword) => keyword.lang === lang);
        if(keyword_l.length===1)
            return [...tags, keyword_l[0].text]
        else
            return tags;
    }
    return !!tag_objs ? tag_objs.reduce(tag_reducer,[]) : null;
}

const point_to_coords = (point) => {
    const value_str = point.match(/\((.*?)\)/)[1];
    const values = value_str.split(" ");
    const lat = parseFloat(values[0]);
    const lon = parseFloat(values[1]);
    return {lat: lat, lon: lon}
}

const graphID_to_num_str = (id) => {
    return '"' + atob(id).split(':')[1] + '"'
}

const graphID_to_int = (id) => {
    return parseInt(atob(id).split(':')[1])
}

const int_to_num_str = (id) => {
    return '"' + id.toString() + '"';
}

function resolve_path(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator)
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

const query_string = (query_param) => {
    switch (typeof query_param) {
        case 'object':
            if (Array.isArray(query_param)) {
                return JSON.stringify(query_param.map((category) => category.name_id.toString()));
            } else {
                console.log('query_string not defined for object')
                break
            }
        case 'number':
            return JSON.stringify(query_param.toString());
        default:
            return JSON.stringify(query_param);
    }
}

const useLazyQuery = (firebase_param, mapper) => {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pagination, setPagination] = useState(null);
    const remapRef= useRef(mapper);
    const [firebase, setFirebase] = useState(firebase_param);
    const [moodiks, setMoodiks] = useState(null);

     if (!fastDeepEqual(remapRef.current, mapper)) {
            remapRef.current = mapper
    }


    async function getData(query) {
        const is_authorized = !!firebase.auth;
        if (!is_authorized) return;
        console.log('getData: data : ', data);
        setLoading(true);

        let client = moodiks;
        if (!client) {
            console.log('acquiring token');
            await firebase.doGetAccessToken(
                (token) => {
                    client = new MoodiksClient(token);
                    setMoodiks(client);
                }
            ).catch((e) => {
                console.log('getData: error: ', e)
            })
        }

        const result = await client.doGraphql(query)
            .catch((e) => {
                console.log('getData: error!', e.errors);
                setData(null);
                setPagination(null);
                setLoading(false);
                setError(e.errors);
            });
        let ok_to_map = !!result
        //if mapper.prefix present check if feed empty and return []
        if (ok_to_map && remapRef.current.prefix && remapRef.current.prefix.length !== 0) {
            const start_point = resolve_path(remapRef.current.prefix, result);
            if (!start_point || start_point.length === 0) {
                console.log('getData: prefix not found ', remapRef.current.prefix, result);
                ok_to_map = false
            }
        }
        const remapped = ok_to_map ? remapRef.current.data(result, remapRef.current.prefix) : [];
        console.log('getData: result, remapped :', result, remapped);
        setData(remapped);
        if (ok_to_map && remapRef.current.pagination) {
            setPagination(remapRef.current.pagination(result));
        }
        setError(null);
        setLoading(false);
        console.log('getData: done :');

    }


    return [{data, loading, error, pagination}, useCallback(getData, [])]
};


export {gql};
export {reduce_tags}
export {useLazyQuery};
export {point_to_coords};
export {graphID_to_num_str};
export {graphID_to_int};
export {int_to_num_str};
export {query_string};
export {resolve_path};