import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as ROUTES from '../../constants/routes';
import theme from "../../Theme";
import TopBar from "../TopBar";
import ExitButton from "../ExitButton/ExitButton";
import images from "./intro_images";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import {useTranslation} from "react-i18next";
import LazyImage from "../LazyImage";


const styles = {
    root: {
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        height: '100vh'
    },
    baseGrid: {
        width: '100%'
    },
    card: {
        maxWidth: '90%',
        minWidth: '300px',
        marginLeft: '5%',
        margin: 0,
    },
    text:{

    },
    heading: {
        padding: 2,
    },
    image: {
        maxWidth: '80%',
        padding: 2,
    }
}


const useStyles = makeStyles((theme) => (styles));

const Heading = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.heading} variant="h2">
            {props.children}
        </Typography>
    )
}

const SubHeading = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.heading} variant="h6">
            {props.children}
        </Typography>
    )
}

const Text = (props) => {
    const classes = useStyles();
    return (
        <Typography className={classes.text} variant="subtitle1">
            {props.children}
        </Typography>
    )
}

const MyCard = ({style, ...props}) => {
    const classes = useStyles();

    return (
        <Grid item xs>
            <Card style={style} className={classes.card}>
                <CardContent>
                    {props.children}
                </CardContent>
            </Card>
        </Grid>
    )
}

const Install = () => {
    const classes = useStyles();
    const {t} = useTranslation(['introduction']);

    return (
        <MyCard
            style={{background: "linear-gradient(0deg, rgba(255,107,0,1) 0%, rgba(242,0,255,1) 100%)"}}
        >
            <Heading>{t('Install the App')}</Heading>
            <SubHeading>{t('No more logging in!')}</SubHeading>
            <Text>{t("To install the MOODIKS App to your home screen, tap 'share'")}.</Text>
            <LazyImage className={classes.image} src={images['install']}/>
            <Text>{t("Then tap 'add to home screen'")}</Text>
            <LazyImage className={classes.image} src={images['add_to_home_page']}/>
            <Text>{t("Yes, it's an App!")}</Text>
        </MyCard>
    )
}

const MyAccount = () => {
    const classes = useStyles();
    const {t} = useTranslation(['introduction']);
    return (
        <MyCard>
            <Heading>{t("Manage your account")}</Heading>
            <LazyImage className={classes.image} src={images['my_account']}/>
            <Heading>{t("Customize your profile")}</Heading>
            <LazyImage className={classes.image} src={images['manage_profile']}/>
        </MyCard>
    )
}

const SocDem = () => {
    const classes = useStyles();
    const {t} = useTranslation(['introduction']);
    return (
        <MyCard>
            <Heading>{t("About you")}</Heading>
            <Text>{t("To match your")}</Text>
            <LazyImage className={classes.image} src={images['soc_dem']}/>
        </MyCard>
    )
}

const Questionnaire = () => {
    const classes = useStyles();
    const {t} = useTranslation(['introduction']);
    return (
        <MyCard style={{background: "linear-gradient(0deg, rgba(255,107,0,1) 14%, rgba(120,255,0,1) 100%)"}}>
            <Heading>{t("Questionnaire")}</Heading>
            <SubHeading>{t("The Questionnaire is the basis of matching")}</SubHeading>
            <Text>{t("Choose between")}</Text>
            <LazyImage className={classes.image} src={images['questionnaire']}/>
        </MyCard>
    )
}


const InviteFriends = () => {
    const classes = useStyles();
    const {t} = useTranslation(['introduction']);
    return (
        <MyCard style={{background: "linear-gradient(0deg, rgba(97,227,255,1) 0%, rgba(0,138,226,1) 66% )"}}>
            <Heading>{t("Invite Friends")}</Heading>
            <LazyImage className={classes.image} src={images['invite_friends']}/>
        </MyCard>
    )
}


const Introduction_install = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div style={{backgroundColor: '#f9f9f9'}}>
            <TopBar leftButton={<ExitButton onExit={() => {
                history.push(ROUTES.FEED)
            }}/>}/>
            <Grid className={classes.baseGrid} container spacing={2}>
                <Install/>
            </Grid>
        </div>
    )
}


const Introduction = (props) => {
    const classes = useStyles();
    return (
        <div style={{backgroundColor: '#f9f9f9'}}>
            <TopBar leftButton={<ExitButton onExit={props.history.goBack}/>}/>
            <Grid className={classes.baseGrid} container spacing={2}>
                <Install/>
                <MyAccount/>
                <SocDem/>
                <Questionnaire/>
                <InviteFriends/>
            </Grid>
        </div>
    )
}



export default Introduction;
export {Introduction_install}