import React from "react";

import { responsiveFontSizes,} from "@material-ui/core";
import createTheme from "@material-ui/core/styles/createTheme";


let theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#2196f3",
        },
        secondary: {
            main: "#f50057",
        },
        background: {
            default: 'white',
        },


        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    typography: {
        breakpoints: {
            values: {
                xs: 0,
                phone: 400,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
        selectOne: {
            fontSize: '2rem',
        },
    },
});



theme = responsiveFontSizes(theme, {factor:6});

/*

theme.typography.h1 = {
    fontsize: '0.1rem',
    [theme.breakpoints.down('phone')]: {
        fontSize: '0.2rem',
    },
    [theme.breakpoints.up('phone')]: {
        fontSize: '1.75rem',
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '3.5rem',
    },
   [theme.breakpoints.up('md')]: {
        fontSize: '4.7rem',
    },
    [theme.breakpoints.up('xl')]: {
        fontSize: '5.3rem',
    },

};

*/


export default theme;