import {gql} from "../../hooks/gql_helpers";
import {remapRecommendations} from "../Recommendation/QueryById";

const get_list = gql`{
    recommendationsByTag( keyword: ${'keyword'}, related: ${'related'}, lang: ${'lang'}, first: ${'first'}, after: ${'after'}) {
    edges {
      node {
        id
        url
        type
        deactivated
        lang
        createdAt
        images{
            url
            alt
         }
        subject
        supercategories {
          keywords {
            text
            lang
          }
        }
        category {
          keywords {
            text
            lang
          }
        }
        tags {
          keywords {
            text
            lang
          }
        }
        location {
          coords
          displayName
          road
          houseNumber
          city
          state
          postcode
          country
        }
        comments {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        likedBy{
          totalCount
          edges{
             node{
               uid
               nickname
               avatarUrl
             }
          }
        }
        experience
        relatedRecommendations{
            totalCount
            edges{
              node{
                id
              }
            }
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`

const extractPagination = (raw) => {
    return raw.data.recommendationsByTag.pageInfo;
}


const mapper = {
    'data': remapRecommendations,
    'pagination': extractPagination,
    'prefix': "data.recommendationsByTag.edges"
}

export {get_list}
export {mapper}