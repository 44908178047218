import React, {useEffect, useRef, useState} from "react";
import {useAsync} from "react-async";
import {compose} from "recompose";
import {withTranslation} from 'react-i18next';
import {withFirebase} from "../Firebase";
import {useHistory} from "react-router-dom";
import {withAuthorization} from "../Session";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Form, Formik,} from 'formik';
import * as yup from 'yup';
import theme from "../../Theme";
import {withStyles} from '@material-ui/core/styles';
import {getMoodiks} from "../Moodiks";
import Button from "@material-ui/core/Button";
import {Snackbar} from '@material-ui/core';
import {FormikSwitch} from "@dccs/react-formik-mui/lib";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import TopBar from "../TopBar";
import ImagePicker from "./ImagePicker"
import {UploadButton} from "./ImagePicker"
import Divider from "@material-ui/core/Divider";
import ExternalSearch from "../ExternalSearch"
import Alert from "@material-ui/lab/Alert";
import {images_obj_to_array} from "../../utils/ImageUploads"
import {HelpText} from "./HelpText"
import RollingInput from "./RollingInput";
import * as TYPES from '../../constants/recommendation_types';
import ExitButton from "../ExitButton/ExitButton";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Box from "@material-ui/core/Box";
import ImageGallery from "./ImageGallery";
import SuperCategoryMenu from "../SuperCategoryMenu";
import SearchAutocomplete from "../Search";
import {TagAutoComplete} from "./TagAutoComplete";
import {CategoryAutoComplete} from "./CategoryAutoComplete";
import {RecommendationCard} from "./RecommendationCard";
import Square from "../Square"
import {recommendationStyles} from "./recommendationStyles";
import ImageCrop from "../ImageCrop";

const styles = {

    form: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    link: {
        color: 'black',
    },
    grid: {
        flexGrow: 0,
        padding: '3px 4px',
        backgroundColor: theme.palette.grey[100]
    },
    recommendation_card: {},
    input_field: {
        backgroundColor: 'white',
        borderWidth: "1px",
        borderColor: "#CCCC",
        borderRadius: '15px',
        '& fieldset': {
            borderRadius: '15px'
        }
    },
    helper_text:{
        marginLeft: '5px',
        marginRight: '30px'
    },
    line: {
        flexGrow: 1,
    },
    error_message: {
        color: 'red',
        fotStyle: 'italic',
    },
    save_button: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 50,
        width: '10rem',
    },
    startIcon: {
        position: 'relative',
        top: '2px',
        bottom: '10px',
        marginRight: 8
    },
    RECOMMENDATION: recommendationStyles.RECOMMENDATION,
    REQUEST: recommendationStyles.REQUEST
};


//reactivate for debugging
const ErrorFields = (props) => {
    const {formikProps} = props;

    return (
        <React.Fragment>
            {Object.keys(formikProps.errors).map((field) => (
                <div>{field},{formikProps.errors[field]}</div>
            ))}
        </React.Fragment>
    )
};
//
// export const DisplayFormikState = props =>
//     <div style={{margin: '1rem 0'}}>
//         <h3 style={{fontFamily: 'monospace'}}/>
//         <pre
//             style={{
//                 background: '#f6f8fa',
//                 fontSize: '.65rem',
//                 padding: '.5rem',
//             }}
//         >
//       <strong>props</strong> ={' '}
//             {JSON.stringify(props, null, 2)}
//     </pre>
//     </div>;


// new_recommendation.images holds the list of selected images as the string of the image index
// state.images holds the array of images

const empty_location = {
    display_name: '',
    address: {
        country: '',
        city: ''
    }
}

const empty_recommendation = (type_prop) => {
    return {
        type: type_prop,
        images: [],
        subject: '',
        supercategories: [],
        category: '',
        tags: [],
        url: '',
        location: empty_location,
        experience: '',
        deactivated: false,
    }
}

const recommendation_schema_base = {
    images: yup.array().when("type", {
        is: 'RECOMMENDATION',
        then: yup.array().min(1, 'no image').required('images missing')
    }),
    subject: yup.string().min(1, 'field too short').max(40, 'field to long').required('subject missing'),
    supercategories: yup.array().min(1, 'supercategory missing').max(2, 'no more than 2 categories').required('supercategory missing'),
    category: yup.string().required('category missing'),
    tags: yup.array().of(yup.string()),
    url: yup.string().url('not a url').nullable(),
    location: yup.object().nullable(),
    experience: yup.string().min(5, 'field too short').max(1000, 'field to long').required("experience missing"),
    deactivated: yup.boolean()
};


const getSelectedImages = (recommendation, images) => {
    if (recommendation.images.length === 0) {
        return []
    }
    const selected_indices = recommendation.images.filter((field) => {
        return field !== false
    });
    return selected_indices.map(index => {
        return images[parseInt(index)]
    });
}


async function saveUploadedImages(images, moodiks) {
    return await Promise.all(
        images.map(async (image) => {
            if (image.src.startsWith('blob') || image.src.startsWith('data')) {
                const saved_url = await moodiks.doSaveImage(image.file);
                return {src: saved_url, alt: image.alt}
            } else {
                return {src: image.src, alt: image.alt}
            }
        }))
}

const FormikImageGallery = (props) => {
    const {
        values,
        images
    } = props;

    const selectedImages = getSelectedImages(values, images);
    return (<ImageGallery fullscreen={false} images={selectedImages}/>)
}


const ifNotDeactivated = (defaultSchema) => {
    return yup.lazy((recommendation) => {
        if (!recommendation.deactivated) {
            return defaultSchema
        } else {
            return yup.mixed().notRequired()
        }
    });
}

//New Recommendations and edits of all types
//
const RecommendationEditBase = (props) => {
        const {
            t,
            recommendation: recommendation_prop = null,
            type: recommendationType = TYPES.RECOMMENDATION,
            handleClose,
            firebase,
            classes
        } = props;


        // const recommendation_type = recommendation_prop ? recommendation_prop.type : recommendation_type_prop;

        const initial_recommendation = recommendation_prop ? recommendation_prop : empty_recommendation(recommendationType);
        console.log('RecommendationEdit: initial_recommendation: type ', initial_recommendation, recommendationType);
        const initial_images = recommendation_prop && recommendation_prop.type === TYPES.RECOMMENDATION
            ? [...recommendation_prop['images']]
            : [];
        const initial_selected_images = recommendation_prop
            ? initial_images.map((image, i) => {
                return `${i}`
            })
            : [];
        if (initial_recommendation) {
            initial_recommendation.images = initial_selected_images;
        }

        const id = recommendation_prop ? recommendation_prop['id'] : null; //id of the recommendation


        const history = useHistory();

        const formRef = useRef();

        const [respondingRequest, setRespondingRequest] = useState(null); //Object holding the request to respond to if any
        const [recommendation, setRecommendation] = useState(initial_recommendation); //Object holding recommendation of any type


        const [images, setImages] = useState(initial_images);
        const [userImages, setUserImages] = useState([]);
        const [imagesFound, setImagesFound] = useState(false);

        const recommendationSchema = ifNotDeactivated(yup.object().shape(recommendation_schema_base));


        const [imagePickerOpen, setImagePickerOpen] = useState(false);
        const [imageToCrop, setImageToCrop] = useState(null);
        const [loading, setLoading] = useState(false);
        const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});
        const [waitingServerResponse, setWaitingServerResponse] = useState(false);


        const [valid, setValid] = useState(true);  //valid to display
        const [needToSetResponse, setNeedToSetResponse] = useState(recommendationType === TYPES.RESPONSE);


        const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
            promiseFn: getMoodiks,
            firebase
        });
        const moodiksValid = !!moodiks && !moodiksIsPending;


        const analyseURL = (moodiks, formikProps, errorMsg, url_param) => {
            const url = url_param ? url_param : formikProps.values.url;
            if (!url || url.length === 0) return;

            const {
                setErrors,
                values,
                setFieldValue
            } = formikProps;
            const {images: selected_images} = values;

            setLoading(false);
            setSnackBar({open: true, message: t('analyzing url'), severity: "info"});

            moodiks.doAnalyseUrl(url).then((data) => {
                    console.log("RecommendationEdit: analyseURL: data: ", {...data});
                    debugger;
                    const url_images = data['images'];
                    setLoading(false);
                    setImages([...images, ...url_images]);
                    setImagesFound(url_images.length > 0);
                    const unselected_images = new Array(url_images.length).fill(false);
                    setFieldValue('images', [...selected_images, ...unselected_images], false);
                    if (url_images.length > 0)
                        setSnackBar({open: true, message: t('url analysed, check for images'), severity: "success"})
                    else
                        setSnackBar({open: true, message: t('no images found'), severity: "info"});
                },
                reason => {
                    setErrors({url: errorMsg});
                    setSnackBar({open: true, message: t('unable to analyse') + reason, severity: "info"})
                    setLoading(false)
                }
            )
        }


        const addUserFiles = (uploads, selected, setSelected) => {
            setImageToCrop(null);
            // const user_images = Object.assign({}, userImages, uploads);
            const user_images = {...userImages, ...uploads};
            const new_images = images_obj_to_array(uploads);
            const merged_images = images.concat(new_images);
            const newSelected = [];
            for (let i = merged_images.length - new_images.length; i < merged_images.length; i++) {
                newSelected.push(`${i}`);
            }
            setUserImages(user_images);
            setImages(merged_images);

            setSelected(selected.concat(newSelected));
            console.log('RecommendationEdit: addUserFiles: userImages, images, selected', user_images, merged_images, selected.concat(newSelected));
        }

        const cropUploads = (uploads, selected, setSelected) => {
            console.log("RecommendationEdit: cropUploads: uploads imageToCrop: ", uploads, Object.entries(uploads)[0][1].src);
            setImageToCrop(uploads);  // uploads is an obj indexed by img names, get first image
        }


        useEffect(() => {
            if (id && needToSetResponse) {
                const response = empty_recommendation(TYPES.RECOMMENDATION);
                console.log('setting response category')
                response.category = {...recommendation.category};
                setRespondingRequest(recommendation);
                setRecommendation(response);
                setNeedToSetResponse(false);
                setValid(true)
            }
        }, [id, needToSetResponse, recommendation]);


        const onSubmit = async (recommendation, formikBag) => {
            setWaitingServerResponse(true);
            setSnackBar({open: true, message: t('saving ...'), severity: "info"});

            const {validateForm} = formikBag;
            const errors = await validateForm();

            if (Object.keys(errors).length !== 0 && !recommendation.deactivated) {
                //Probably this is never executed and can go
                {
                    const error_list = Object.keys(errors).map((field) => t(field) + ': ' + t(errors[field]));
                    const error_msg = error_list.join('\n');
                    setSnackBar({open: true, message: error_msg, severity: "error"});
                }
                return;
            }
            const selected_images = getSelectedImages(recommendation, images);
            let saved_images;
            try {
                saved_images = await saveUploadedImages(selected_images, moodiks);
            } catch (err) {
                setSnackBar({open: true, message: t("error saving images"), severity: "error"});
            }
            console.log('RecommendationEdit: onSubmit: ', recommendation);
            debugger;
            const {location: loc = null} = recommendation;
            console.log('RecommendationEdit: onSubmit location: ', loc);
            // eslint-disable-next-line no-mixed-operators
            const location = loc && (loc.display_name || loc.address && (loc.address.country || loc.address.city))
                ? loc : null;
            debugger;
            let r = {...recommendation, location: location};
            r.images = saved_images;
            console.log('RecommendationEdit: onSubmit: before try');
            try {
                console.log('RecommendationEdit: onSubmit: in try');
                if (recommendationType === TYPES.RESPONSE) {
                    console.log('RecommendationEdit: onSubmit: posting: ', recommendationType)
                    const result = await moodiks.doPostResponse(r, respondingRequest.id)
                    console.log('RecommendationEdit: onSubmit: posted!: ', recommendationType, result)
                } else {
                    console.log('RecommendationEdit: onSubmit: posting: ', recommendationType)
                    const result = await moodiks.doPostRecommendation(r)
                    console.log('RecommendationEdit: onSubmit: posted!: ', recommendationType, result)
                }
                setSnackBar({open: true, message: t('saved !'), severity: "success"});
                setWaitingServerResponse(false)
                onReset(true)
            } catch (err) {
                setSnackBar({open: true, message: t("error saving"), severity: "error"});
                setWaitingServerResponse(false)
            }
        }

        const onReset = (changed) => {  //(values, formikBag)
            if (handleClose)
                handleClose(changed)
            else
                history.goBack();
        }

        const closeSnackbar = () => {
            setSnackBar({open: false, message: '', severity: 'success'});
        }
        const handle_open_image_select = () => {
            setImagePickerOpen(true);
        }
        const handle_close_image_select = () => {
            setImagePickerOpen(false);
        }

        const is_response = !!respondingRequest;
        const is_recommendation = recommendationType === TYPES.RECOMMENDATION;
        const new_item = recommendation.id == null;
        const save_text = new_item
            ? is_response
                ? 'answer'
                : recommendationType === TYPES.RECOMMENDATION
                    ? 'recommend' : 'request'
            : 'save';
        const experience_text = is_response
            ? 'detail'
            : 'experience'
        const showDeactivate = !new_item;
        const recommendation_or_response = recommendationType === TYPES.RECOMMENDATION || recommendationType === TYPES.RESPONSE;
        const showUrl = moodiksValid && recommendation_or_response;
        const showAnalysing = loading;
        const header = id
            ? is_response
                ? 'your response' : 'edit'
            : recommendationType === TYPES.RECOMMENDATION
                ? 'Recommendation' : 'Request';
        const headerIcon = (<Square size={20} color={recommendationStyles[recommendation.type].borderColor}/>)


        const has_images = images.length > 0;


        return (
            <div>
                <Typography component='div'>
                    < Grid container direction="column"
                           justifyContent="space-evenly"
                           alignItems="center"
                           className={classes.grid}
                    >
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            open={snackBar.open}
                            autoHideDuration={2000}
                            onClose={closeSnackbar}
                        >
                            <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                                {snackBar.message}
                            </Alert>
                        </Snackbar>

                        {valid && moodiksValid &&
                        <Formik
                            innerRef={formRef}
                            initialValues={recommendation}
                            validationSchema={recommendationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            onSubmit={(values, bag) => onSubmit(values, bag)}
                            onReset={(values, bag) => onReset(false)}
                        >
                            {(formikProps) => (
                                <React.Fragment>
                                    <Form className={classes.form} autoComplete="off">
                                        <TopBar title={t(header)} startIcon={headerIcon}
                                            // classes={{startIcon: classes.startIcon}}
                                                leftButton={
                                                    <ExitButton
                                                        className={classes.exit_button} onExit={onReset}
                                                        icon={<ClearIcon/>}
                                                    />
                                                }
                                                rightButton={
                                                    <Button
                                                        edge="end"
                                                        type="submit"
                                                        size="small"
                                                        variant="contained"
                                                        style={{textTransform: 'none', borderRadius: 20}}
                                                        // disabled={!formikProps.isValid && !formikProps.values.deactivated}
                                                        disabled={waitingServerResponse}
                                                        color='primary'
                                                    >
                                                        {t(save_text)}
                                                    </Button>
                                                }
                                        />

                                        <ImagePicker name='images'
                                                     images={images}
                                                     title={t('select images')}
                                                     startIcon={headerIcon}
                                                     open={imagePickerOpen}
                                                     onClose={handle_close_image_select}
                                                     onUpload={cropUploads}
                                        />
                                        <ImageCrop name='images'
                                                   title={t('crop')}
                                                   startIcon={headerIcon}
                                                   open={!!imageToCrop}
                                                   imageSrc={imageToCrop}
                                                   onClose={addUserFiles}
                                                   onCancel={() => {
                                                       setImageToCrop(null)
                                                   }}
                                                   cropMsg={t('calculating image..')}

                                        />
                                        <Grid item container direction='row' justifyContent='space-between'>
                                            <Grid item className={classes.helper_text}>
                                                <HelpText recommendationType={recommendationType}/>
                                            </Grid>
                                            {has_images &&
                                            <Grid item>
                                                <Button onClick={handle_open_image_select}>
                                                    <AddAPhotoIcon/>
                                                </Button>

                                            </Grid>
                                            }

                                        </Grid>
                                        {is_response &&
                                        <Grid item>
                                            <RecommendationCard
                                                recommendation={respondingRequest}
                                                hideShare
                                                noActions
                                                noActionsChildren
                                                subjectClickable={false}
                                            />
                                            <Box sx={{minHeight: '10px'}}/>
                                        </Grid>
                                        }
                                        {(is_recommendation || is_response) &&
                                        <Grid item>
                                            {has_images &&
                                            <FormikImageGallery values={formikProps.values} images={images}/>
                                            }
                                            {!has_images &&
                                            <React.Fragment>
                                                <Box sx={{
                                                    width: '100%', height: '14rem',
                                                    background: theme.palette.grey[200],
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <UploadButton name='images'
                                                                  onUpload={cropUploads}
                                                    />
                                                </Box>
                                                {formikProps.errors.images &&
                                                <Typography color='secondary' variant='caption'>
                                                    {t(formikProps.errors.images)}
                                                </Typography>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid>
                                        }
                                        <Grid item>
                                            <RollingInput name='subject' label={t('subject') + ' *'}
                                                          isRolling={false}
                                                          params={{
                                                              helper_text: "subject_helper",
                                                              variant: 'outlined', fullWidth: true, margin: 'normal',
                                                              required: false
                                                          }}

                                                          className={classes.input_field}/>
                                        </Grid>
                                        <Grid item>
                                            <RollingInput
                                                name='supercategories' label={t('supercategory') + ' *'}
                                                className={classes.input_field}
                                                isRolling={false}
                                                params={{
                                                    moodiks: moodiks,
                                                    variant: 'outlined',
                                                    fullWidth: true,
                                                    margin: 'normal',
                                                    required: false
                                                }}
                                                component={SuperCategoryMenu}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <RollingInput name='category' label={t('category') + ' *'}
                                                          isRolling={false}
                                                          component={CategoryAutoComplete}
                                                          className={classes.input_field}
                                                          params={{
                                                              maxValues: 1,
                                                              moodiks: moodiks,
                                                              helper_text: "title_helper",
                                                              variant: 'outlined',
                                                              fullWidth: true,
                                                              margin: 'normal',
                                                              required: false
                                                          }}
                                            />

                                        </Grid>


                                        <Grid item>
                                            <RollingInput name='tags' label={t('tags')}
                                                          isRolling={false}
                                                          component={TagAutoComplete}
                                                          className={classes.input_field}
                                                          params={{
                                                              moodiks: moodiks,
                                                              max_values: 20,
                                                              helper_text: "subject_helper",
                                                              variant: 'outlined',
                                                              fullWidth: true,
                                                              margin: 'normal',
                                                          }}
                                            />
                                        </Grid>
                                        {showUrl &&
                                        <Grid item>

                                            <ExternalSearch
                                                className={classes.input_field}
                                                name="url" label={t('url')}
                                                error={formikProps.errors.url}
                                                helperText={formikProps.errors.url}
                                                variant="outlined" margin="normal" fullWidth
                                                onClose={(url) => {
                                                    analyseURL(moodiks, formikProps, t('url not usable'), url)
                                                }}
                                            />

                                        </Grid>
                                        }
                                        {showAnalysing &&
                                        <Grid item>
                                            {t('analysing web site')}
                                        </Grid>
                                        }

                                        {imagesFound &&
                                        <Grid item>
                                            <Button onClick={handle_open_image_select}
                                                    endIcon={(<ChevronRightIcon/>)}
                                            >
                                                {t('Select web images')}
                                            </Button>
                                        </Grid>
                                        }

                                        <Grid item>
                                            <RollingInput name='location' label={t('location')}
                                                          isRolling={false}
                                                          component={SearchAutocomplete}
                                                          params={{
                                                              limit: 10,
                                                              variant: 'outlined', fullWidth: true,
                                                              margin: 'normal',
                                                          }}
                                                          className={classes.input_field}/>
                                        </Grid>
                                        <Grid item>
                                            <RollingInput name='experience' label={t(experience_text) + ' *'}
                                                          isRolling={false}
                                                          params={{
                                                              helper_text: "what_for_helper",
                                                              variant: 'outlined',
                                                              fullWidth: true,
                                                              multiline: true,
                                                              rows: 6,
                                                              margin: 'normal',
                                                              required: false
                                                          }}
                                                          className={classes.input_field}/>
                                        </Grid>

                                        {showDeactivate &&
                                        <Grid container direction="row" alignItems={"center"} item>
                                            <Grid item>
                                                <FormikSwitch name='deactivated'/>
                                            </Grid>
                                            <Grid item>
                                                {t("deactivate")}
                                            </Grid>
                                        </Grid>
                                        }

                                    </Form>
                                </React.Fragment>
                            )}
                        </Formik>
                        }
                        <Divider/>

                    </Grid>
                </Typography>

            </div>
        );
    }
;


const if_authorized = authUser => authUser && authUser.role === 'regular';
const RecommendationEdit = compose(
    withFirebase,
    withTranslation(['recommendation']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(RecommendationEditBase);


export {RecommendationEdit}
