import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import theme from "../../Theme";
import IconButton from "@material-ui/core/IconButton";
import {CardActionArea} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

export const styles = {
    root: {
        display: 'flex',
        padding: 16,
        alignItems: 'center',
    },
    delete_button: {
        marginLeft: 'auto',
        marginRight: 0
    },
    delete_button_icon: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white
    },
    divider: {
        border: 0,
        display: 'block',
        width: "90%",
        height: '1px',
        backgroundColor: theme.palette.common.black
    },
};

const TagCardBase = React.forwardRef(function TagCardBase(props, ref) {
    const {
        classes,
        tagId,
        text,
        onClick,
        onDelete = null
    } = props;

    console.log('tagCardBase: onDelete',onDelete)
    return (
        <Grid container direction='column'>
            <Grid item container direction='row' justifyContent={"space-between"}>
                <Grid item>
                    <CardActionArea>
                        <Box className={classes.root}>
                            <Chip label={'#' + text}
                                  variant="outlined"
                                  size="medium"
                                  onClick={() => onClick(tagId, text)}
                            />
                        </Box>
                    </CardActionArea>
                </Grid>
                {!!onDelete &&
                    <Grid item>
                        <IconButton
                            className={classes.delete_button}
                            onClick={() => {
                                onDelete(tagId)
                            }}
                        >
                            <HighlightOffTwoToneIcon className={classes.delete_button_icon}/>
                        </IconButton>
                    </Grid>
                }
            </Grid>
            <hr className={classes.divider}/>
        </Grid>

    )
})


TagCardBase.propTypes = {
    classes: PropTypes.object.isRequired,
    tagId: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func
}


const TagCard = withStyles(styles)(TagCardBase);
export {TagCard};
