import {query_string} from "../../hooks/gql_helpers";


const initial_query_params = {
    'categories': query_string(''),
    'country': query_string(''),
    'state': query_string(''),
    'city': query_string(''),
    'lat': query_string(''),
    'lon': query_string(''),
    'distance': query_string('')
}

const query_from_values = (values) => {
    const {
        tags, location, distance
    } = values;
    console.log('CategoryFilter: query_from_values: values: ', values)
    if (!!location) {
        switch (distance) {
            case 'country':
                return {
                    ...initial_query_params,
                    categories: query_string(tags),
                    country: query_string(location.address.country),
                    distance: query_string(distance)
                }
            case 'state':
                return {
                    ...initial_query_params,
                    categories: query_string(tags),
                    country: query_string(location.address.country),
                    state: query_string(location.address.state),
                    distance: query_string(distance)
                }
            case 'city':
                return {
                    ...initial_query_params,
                    categories: query_string(tags),
                    country: query_string(location.address.country),
                    city: query_string(location.address.city),
                    distance: query_string(distance)
                }
            case '500':
            case '2000':
            case '5000':
            case '20000':
            case '50000':
                return {
                    ...initial_query_params,
                    categories: query_string(tags),
                    lat: query_string(location.lat),
                    lon: query_string(location.lon),
                    distance: query_string(distance)
                }
            default:
                return {...initial_query_params, categories: query_string(tags)}
        }

    } else
        return {...initial_query_params, categories: query_string(tags)}
}

export {query_from_values}