import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    useHistory,
    BrowserRouter as Router,
    Link, useRouteMatch
} from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import {AuthUserContext} from '../Session'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SearchIcon from '@material-ui/icons/Search';

// import PresentToAllTwoToneIcon from '@material-ui/icons/PresentToAllTwoTone';
// import PresentToAllOutlinedIcon from '@material-ui/icons/PresentToAllOutlined';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {RecommendOutline} from '../../icons/RecommendOutline'
import {RecommendOutlineFilled} from '../../icons/RecommendOutlineFilled'
import {BrainOutline} from '../../icons/BrainOutline'
import Square from "../Square";
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';


import theme from "../../Theme";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {Drawer, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import List from "@material-ui/core/List";
import {moodiksColors} from "../Styles/palette";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({


    appBar: {
        top: 'auto',
        bottom: 0,
        elevation: 0,
    },
    grow: {
        flexGrow: 1,
    },
    icon: {
        '& svg': {
            fontSize: 30
        }
    },
    drawer_heading: {
        paddingTop: "0.5rem",
        height: "2rem",
        color: "black",
        fontWeight: 400,
        textAlign: "center"
    }
}));


const Navigation = () => {
    const {authUser} = React.useContext(AuthUserContext);
    const unAuthorized = !authUser;

    console.log('Navigation: authUser, unAuthorized: ', authUser, unAuthorized);
    return (
        <div>
            <ThemeProvider theme={theme}>
                {unAuthorized
                    ? <NavigationNonAuth/>
                    : {
                        'regular': <NavigationAuth/>,
                        'anonymous': <NavigationAnonymous/>
                    }[authUser.role]
                }
            </ThemeProvider>
        </div>
    )
};

const NavButton = (props) => {
    const {route, unselectedIcon, selectedIcon, edge} = props;
    const classes = useStyles();
    const icon = useRouteMatch({path: route, strict: true, sensitive: true}) ? selectedIcon : unselectedIcon;

    return (
        <Grid item>
            <Link to={route}>
                <IconButton className={classes.icon} edge={edge}
                            color="default">
                    {icon}
                </IconButton>
            </Link>
        </Grid>
    );
}
NavButton.propTypes = {
    route: PropTypes.string,
    unselectedIcon: PropTypes.node,
    selectedIcon: PropTypes.node,
    edge: PropTypes.string
}

const DrawerButton = (props) => {
    const {
        header, routes, unselectedIcon, selectedIcon, edge
    } = props;
    const classes = useStyles();
    const history = useHistory()
    const [drawerState, setDrawerState] = useState(false);

    const toggleDrawer = () => {
        setDrawerState(previous => !previous)
    }

    const select_route = (route) => {
        history.push(route)
    }

    const icon = unselectedIcon;

    const list = () => (
        <div>
            <List>
                {routes.map((route) => (
                    <ListItem button onClick={() => select_route(route.route)} key={route.text}>
                        <ListItemIcon>{route.icon}</ListItemIcon>
                        <ListItemText>{route.text}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    )


    return (
        <React.Fragment>
            <Grid item>
                <IconButton className={classes.icon} edge={edge} onClick={toggleDrawer}>
                    {icon}
                </IconButton>
            </Grid>
            <Drawer anchor='bottom' open={drawerState} onClose={toggleDrawer}>
                 <Typography variant='h5' className={classes.drawer_heading}>{header}</Typography>
                {list()}
            </Drawer>
        </React.Fragment>
    );
}

DrawerButton.propTypes = {
    header: PropTypes.string,
    routes: PropTypes.array,
    unselectedIcon: PropTypes.node,
    selectedIcon: PropTypes.node,
    edge: PropTypes.string
}


const NavigationAnonymous = () => {
    const classes = useStyles();
    console.log('Navigation: NavigationAnonymous');

    return (
        <>
            <AppBar position="sticky" color="default" className={classes.appBar}>
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <NavButton route={ROUTES.LANDING} edge="start" selectedIcon={<HomeIcon/>}
                                   unselectedIcon={<HomeOutlinedIcon/>}/>

                        <NavButton route={ROUTES.INTRODUCTION} selectedIcon={<InfoIcon/>}
                                   unselectedIcon={<InfoOutlinedIcon/>} edge="end"/>
                        {/*<NavButton route={ROUTES.ACCOUNT} edge="end" selectedIcon={<AccountCircleIcon/>}*/}
                        {/*           unselectedIcon={<AccountCircleOutlinedIcon/>}/>*/}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>
    );
};


const NavigationNonAuth = () => {
    const classes = useStyles();

    return (
        <>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar>
                    <div>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                            <NavButton route={ROUTES.LANDING} edge="start" selectedIcon={<HomeIcon/>}
                                       unselectedIcon={<HomeOutlinedIcon/>}/>

                            <Link to={ROUTES.SIGN_IN}>
                                <IconButton className={classes.icon} disabled={false} edge="end"
                                            color="primary.dark">
                                    <AccountCircleIcon/>
                                </IconButton>
                            </Link>
                        </Grid>
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>
    );
};


const NavigationAuth = () => {
    const classes = useStyles();
    const {t} = useTranslation('recommendation');
    console.log('Navigation: NavigationAuth');

    const add_recommendation = [
        {icon: <Square color={moodiksColors.moodiks_green}/>, route: ROUTES.RECOMMENDATION, text: t('Recommendation')},
        {icon: <Square color={moodiksColors.moodiks_magenta}/>, route: ROUTES.REQUEST, text: t('Request')},
    ];


    return (
        <>
            <AppBar position="fixed" color="default" className={classes.appBar}>
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <NavButton route={ROUTES.FEED} edge="start" selectedIcon={<HomeIcon/>}
                                   unselectedIcon={<HomeOutlinedIcon/>}/>
                        <NavButton route={ROUTES.SEARCH_VIEW} selectedIcon={<FindInPageIcon/>}
                                   unselectedIcon={<SearchIcon/>}/>
                        <DrawerButton header={t('Create')} routes={add_recommendation} selectedIcon={<AddBoxIcon/>}
                                      unselectedIcon={<AddBoxOutlinedIcon/>}/>
                        <NavButton route={ROUTES.MATCH_MENU} selectedIcon={<BrainOutline/>}
                                   unselectedIcon={<BrainOutline/>}/>
                        <NavButton route={ROUTES.MY_RECOMMENDATIONS} edge="end" selectedIcon={<AccountCircleIcon/>}
                                   unselectedIcon={<AccountCircleOutlinedIcon/>}/>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </>
    )
        ;
};


function HideOnScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction="up" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function NavigationHideable(props) {

    return (
        <React.Fragment>
            <HideOnScroll>
                <span>
                <Navigation {...props}/>
                </span>
            </HideOnScroll>
        </React.Fragment>
    );
}


export default Navigation;
export {NavigationHideable}
export {NavButton}