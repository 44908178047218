import React from 'react';
import withStyles from "@material-ui/styles/withStyles";
import Chip from '@material-ui/core/Chip';
import {emphasize} from "@material-ui/core";
import {alpha} from '@material-ui/core/styles'


const ChipFactory = (color = null, deleteIconColorParam = null) => {
    const styles = theme => {
        const backgroundColor = color;
        // const backgroundColor = emphasize(color || theme.palette.background.default, 0.24);
        let deleteIconColor;
        deleteIconColor = alpha(theme.palette.text.primary || deleteIconColorParam, 0.26);
        return {
            root: {
                color: theme.palette.getContrastText(backgroundColor),
                backgroundColor,
            },
            outlined: {
                backgroundColor: backgroundColor,
                borderWidth: '1px',
                borderColor: theme.palette.common.black,
                '&:hover, &:focus': {

                }
            },
            clickable: {
                cursor: 'pointer',
                '&:hover, &:focus': {
                    backgroundColor: backgroundColor,
                    // backgroundColor: emphasize(backgroundColor, 0.08),
                },
                '&:active': {
                    backgroundColor: backgroundColor,
                    // backgroundColor: emphasize(backgroundColor, 0.12),
                },
            },
            deletable: {
                '&:focus': {
                    backgroundColor: backgroundColor,
                    // backgroundColor: emphasize(backgroundColor, 0.08),
                },
            },
            deleteIcon: {
                color: deleteIconColor,
                '&:hover': {
                    color: alpha(deleteIconColor, 0.4),
                },
            },
        };
    };

    const MyChip = ({classes, ...props}) =>
        <Chip classes={classes} {...props} />;

    return withStyles(styles)(MyChip);
};

export default ChipFactory;