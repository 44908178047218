import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Cropper from "react-easy-crop";
import {CssBaseline, Dialog, Slider, Snackbar} from "@material-ui/core";
import TopBar from "../TopBar";
import CropRotateIcon from '@material-ui/icons/CropRotate';
import ClearIcon from '@material-ui/icons/Clear';
import Box from "@material-ui/core/Box";
import {styles} from "./styles"
import Typography from "@material-ui/core/Typography";
import {getCroppedImg} from "../../utils/ImageUploads";
import {useField} from "formik";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import clsx from "clsx";


const ClearButton = ({onClear}) => {
    return (
        <IconButton
            edge="end"
            type="submit"
            size="small"
            variant="contained"
            onClick={onClear}
        >
            <ClearIcon/>
        </IconButton>
    )
}


const SaveButton = ({onSave, saveText}) => {
    return (
        <Button
            edge="end"
            size="small"
            variant="contained"
            style={{textTransform: 'none', borderRadius: 20}}
            onClick={onSave}
            color='primary'
        >
            {saveText}
        </Button>
    )
}

const ImageCropBase = React.forwardRef(function ImageCropBase(props, ref) {
    const {
        classes,
        className,
        name,
        open,
        onCancel,
        onClose,
        title,
        startIcon,
        cropShape = 'rect',
        showGrid = true,
        cropMsg = 'calculating',
        errorMsg = 'error cropping',
        imageSrc: image,
        aspect: aspect_prop = 4 / 3,
        ...other
    } = props;


    const [field, meta, helpers] = useField(name);
    const {value} = field;
    const {setValue} = helpers;

    const imageSrc = !!image ? Object.entries(image)[0][1].src : null;
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});
    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }

    const handleCancel = () => {
        if (onCancel)
            onCancel()
    }
    const handleClose = useCallback(async () => {
        if (cropMsg) setSnackBar({open: true, message: cropMsg, severity: "info"});
        try {
            const image_name = Object.keys(image)[0]

            //getCroppedImage returns a moodiks image object
            const croppedImage = await getCroppedImg(
                imageSrc,
                image_name,
                croppedAreaPixels,
                rotation,
                aspect_prop
            )
            if (onClose)
                onClose(croppedImage, value, setValue)
        } catch (e) {
            if (errorMsg) setSnackBar({open: true, message: errorMsg, severity: "error"});
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation]);

    useEffect(() => {
        if (!open) {
            setCrop({x: 0, y: 0});
            setRotation(0);
            setCroppedAreaPixels(null);
            console.log('ImageCrop: useEffect: resetting')
        }
    }, [open]);


    const {t} = useTranslation(['common']);
    return (
        <React.Fragment>
            <CssBaseline/>
            {open && imageSrc &&
            <Dialog fullScreen open={open}>
                <TopBar
                    startIcon={startIcon}
                    endIcon={<CropRotateIcon/>}
                    title={title}
                    leftButton={<ClearButton onClear={handleCancel}/>}
                    rightButton={<SaveButton onSave={handleClose} saveText={t('save')}/>}
                />
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={snackBar.open}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                >
                    <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>

                {/*<Box className={clsx(classes.root, className)}>*/}
                <Box>
                    <Box className={classes.cropContainer}>
                        <Box className={classes.cropper}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={aspect_prop}
                                cropShape={cropShape}
                                showGrid={showGrid}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onZoomChage={setZoom}
                                onCropComplete={onCropComplete}
                                {...other}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.controls}>
                        <Box className={classes.sliderContainer}>
                            <Typography
                                variant="overline"
                                classes={{root: classes.sliderLabel}}
                            >
                                {t('Zoom')}
                            </Typography>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                classes={{root: classes.slider}}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Box>
                        {/*<Box className={classes.sliderContainer}>*/}
                        {/*    <Typography*/}
                        {/*        variant="overline"*/}
                        {/*        classes={{root: classes.sliderLabel}}*/}
                        {/*    >*/}
                        {/*        {t('Rotation')}*/}
                        {/*    </Typography>*/}
                        {/*    <Slider*/}
                        {/*        value={rotation}*/}
                        {/*        min={0}*/}
                        {/*        max={360}*/}
                        {/*        step={1}*/}
                        {/*        aria-labelledby="Rotation"*/}
                        {/*        classes={{root: classes.slider}}*/}
                        {/*        onChange={(e, rotation) => setRotation(rotation)}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                    </Box>
                </Box>
            </Dialog>
            }
        </React.Fragment>
    )

})
ImageCropBase.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.string,
    startIcon: PropTypes.element,
    cropMsg: PropTypes.string,
    errorMsg: PropTypes.string,
    imageSrc: PropTypes.object,
    aspect: PropTypes.number
}


const ImageCrop = withStyles(styles)(ImageCropBase);
export default ImageCrop;
