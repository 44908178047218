import {useTranslation} from "react-i18next";
import withStyles from "@material-ui/styles/withStyles";
import React, {useRef, useState} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import {query_string} from "../../hooks/gql_helpers";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Grid from "@material-ui/core/Grid";
import {AvatarButton} from "../Recommendation/MoodiksAvatar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import theme from "../../Theme";
import DialogContent from "@material-ui/core/DialogContent";
import UserPage from "../UserPage";
import CategoryFilter from "../CategoryFilter";
import {moodiksColors} from "../Styles/palette";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RollingRoutes, {Route} from "../RollingRoutes";
import * as ROUTES from '../../constants/routes';
import {useFilterContext} from "./FilterContext";
import ChipFactory from "../ChipFactory";
import TagView from "../TagView";


export const styles = {
    gridRoot: {
        width: '100%',
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
    },
    chipList: {
        display: 'flex',
        height: '100px',
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipListItem: {
        root: {
            display: 'flex',
            height: '100px',
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            whiteSpace: 'nowrap',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        item: {
            position: 'relative',
            display: 'block', // In case it's not rendered with a div.
            overflow: 'hidden',
            height: '50%'
        }
    },
    user_text: {
        display: 'flex',
        height: '100px',
    },
    chip: {
        margin: theme.spacing(0.5),
        textOverflow: "ellipsis",
        maxWidth: 130,
        minWidth: 129
    },
    tile: {
        alignItems: 'center'
    }
};

const truncate = (input, max) => {
    if (input.length > max - 3) {
        return input.substring(0, max - 3) + '...'
    }
    return input
}


const AlertDialog = (props) => {
    const {
        open,
        onClose,
        title,
        children
    } = props;

    const {t} = useTranslation(['recommendation'])


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => {
                    onClose(false)
                }}>{t('cancel')}</Button>
                <Button variant='contained' color="secondary" onClick={() => {
                    onClose(true)
                }}>{t('delete')}</Button>
            </DialogActions>
        </Dialog>
    );
}


const DisplayFiltersBase = (props) => {
    const {
        classes,
        history
    } = props;
    const {t} = useTranslation(['recommendation'])
    const [value, setValue] = React.useState(0);
    const [dialog, setDialog] = React.useState({open: false, text: '', context: null});
    const {filters, setQueryParams, changeFilter, deleteFilter} = useFilterContext();
    const BlueChip = ChipFactory(moodiksColors.moodiks_blue);
    console.log('DisplayFilters: ', filters);

    const routes = {
        categoryFilter: false,
        userPage: false,
        keyword: false
    };

    //Alert Category Delete Dialog
    const handleCategoryDelete = (filter) => {
        setDialog({open: true, text: filter.name, context: filter.id});
    }

    const handleDialogClose = (answer) => {
        setDialog(dialog => ({...dialog, open: false}));
        if (answer)
            deleteFilter(dialog.context)
    };

    //Tab change handlers
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                setQueryParams({fType: query_string('CATEGORY')})
                break;
            case 1:
                setQueryParams({fType: query_string('USER')})
                break;
            default:
                setQueryParams({fType: query_string('CATEGORY')})
        }
    };

    const handleChangeTabIndex = (index) => {
        setValue(index);
    };


    const handleAddCategoryFilters = () => {
        history.push(ROUTES.SEARCH_VIEW);
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }


    const TabPanel = (props) => {
        const {
            value,
            index,
            children
        } = props;
        return (
            <div
                role={'tabpanel'}
                hidden={value !== index}
                id={`tab-panel-${index}`}
            >
                {children}
            </div>
        )
    }


    // filter list by filter type
    const select = (list, selector) => {
        return list.filter(f => f.f_type === selector)
    }

    const user_filters = select(filters, 'USER');
    const category_filters = select(filters, 'CATEGORY');

    console.log('DisplayFilters: user_filters: ', user_filters)
    return (
        <div className={classes.gridRoot}>
            <AlertDialog open={dialog.open} onClose={handleDialogClose} title={dialog.text}/>
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab label={t("categories")} {...a11yProps(0)}/>
                <Tab label={t("user")} {...a11yProps(1)}/>
            </Tabs>
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <React.Fragment>
                        <SwipeableViews axis='x' index={value} disabled onChangeIndex={handleChangeTabIndex}>
                            <TabPanel value={value} index={0}>
                                {category_filters.length !== 0 &&
                                <ImageList rowHeight={100} className={classes.chipList} cols={2.7}>
                                    {category_filters.map((filter, i) => (
                                        <ImageListItem key={filter.id.toString()} className={classes.tile}>
                                            <BlueChip label={filter.name} color='default'
                                                      className={classes.chip}
                                                // variant='outlined'
                                                      onDelete={() => handleCategoryDelete(filter)}
                                                      onClick={() => routeState.openRoute(
                                                          'keyword', {'filter': filter}
                                                      )}
                                                // onClick={() => routeState.openRoute('categoryFilter', {'filter': filter})}
                                            />
                                        </ImageListItem>
                                    ))}s
                                </ImageList>}
                                {category_filters.length === 0 && false &&
                                <IconButton onClick={handleAddCategoryFilters}>
                                    <AddCircleIcon/>
                                </IconButton>}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {user_filters.length !== 0 &&
                                <ImageList rowHeight={100} className={classes.gridList} cols={4.5}>
                                    {user_filters.map((filter) => (
                                        <ImageListItem key={filter.id.toString()} className={classes.tile}>
                                            <Grid container alignItems={'center'} justifyContent={'center'}>
                                                <Grid item>
                                                    <AvatarButton avatarUrl={filter.avatar_url}
                                                                  nickname={truncate(filter.nickname, 11)}
                                                                  onClick={() => routeState.openRoute('userPage',
                                                                      {
                                                                          uid: filter.follow_uid,
                                                                          nickname: filter.nickname,
                                                                          avatar_url: filter.avatar_url
                                                                      })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                                }
                                {user_filters.length === 0 &&
                                <Grid container justifyContent='center' alignItems='center'
                                      className={classes.user_text}>
                                    <Grid item>{t('click on users to follow them')}</Grid>
                                </Grid>}
                            </TabPanel>
                        </SwipeableViews>

                        <Route dialog='userPage' noTopBar>
                            <UserPage uid={routeState.routeContext('uid')}
                                      avatarUrl={routeState.routeContext('avatar_url')}
                                      nickname={routeState.routeContext('nickname')}
                                      onClose={() => {
                                          routeState.closeRoute('userPage')
                                      }}
                                      noActionsChildren
                            />
                        </Route>
                        <Route dialog='categoryFilter'>
                            <CategoryFilter filter={routeState.routeContext('filter')}
                                            onClose={() => {
                                                routeState.closeRoute('categoryFilter')
                                            }}
                                            handleChangeFilter={changeFilter}
                                            noActionsChildren

                            />
                        </Route>
                        <Route dialog='keyword'>
                            <TagView {...routeState.props}/>
                        </Route>
                    </React.Fragment>
                )}
            </RollingRoutes>
        </div>
    )
}

const DisplayFilters = compose(
    withStyles(styles),
    withRouter,
)(DisplayFiltersBase);
export {DisplayFilters};