import React from "react";
import throttle from 'lodash/throttle';
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useField} from "formik";
import {TextField} from "@material-ui/core";

const parse = require('autosuggest-highlight/parse');
const match = require('autosuggest-highlight/match');

export const styles = {
    root: {}
};


const CategoryAutoCompleteBase = React.forwardRef(function CategoryAutoCompleteBase(props, ref) {
    const {
        className,
        classes,
        name,
        moodiks,
        maxValues = 20,
        maxOptions = 5,
        variant,
        label,
        required = false,
        helperText,
        fullWidth,
        endpoint = "tags",
        onClose,
        disabled,
    } = props;


    const [field, meta, helpers] = useField(name);
    const {setValue} = helpers;
    const {error} = meta;
    const {value} = field;  //list
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                moodiks.doGetAutoComplete(request, callback, endpoint);
            }, 200),
        [moodiks],
    );


    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        if (inputValue.length > 1)
            fetch({q: inputValue, max_values: maxOptions}, (results) => {

                console.log('CategoryAutoComplete:useEffect: result_list: ', results);
                if (active) {
                    setOptions(results);
                }
            });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, maxOptions]);


    const handleInputChange = (event, newInputValue) => {
        // event.preventDefault();
        setInputValue(newInputValue);
    }

    const filterOptions = (T) => {
        if (inputValue.length > 1)
            return T
        else
            return []
    }

    function waitFor(variable, callback) {
        const interval = setInterval(function () {
            if (window[variable]) {
                clearInterval(interval);
                callback();
            }
        }, 200);
    }

    const handleClose = (event, reason) => {
        console.log('CategoryAutoComplete: handleClose: reason: ', reason,', value, ', value, ', inputValue: ', inputValue);
        if (reason!=='select-option')
            setValue(inputValue)

        if (onClose)
            onClose()
    }


    return (
        <React.Fragment>
            <Autocomplete
                name={name}
                freeSolo
                selectOnFocus
                options={options}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            spellCheck: 'true'
                        }}
                        variant={variant}
                        error={!!error} label={label} helperText={helperText} required={required}
                    />
                )}
                renderOption={(option, {inputValue}) => {
                    if (!option || !inputValue || typeof (option) !== 'string' || typeof (inputValue) !== 'string')
                        return;

                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    console.log('CategoryAutoComplete: renderOption: option:', option);
                    return (
                        <React.Fragment>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        color: 'black',
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                              {part.text}
                            </span>
                            ))}
                        </React.Fragment>
                    );
                }}
                onClose={handleClose}
                onChange={(event, newValue) => {
                    console.log('CategoryAutoComplete: onChange: newValue: ', newValue);
                    setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    console.log('CategoryAutoComplete: onInputChange: newValue: ', newInputValue);
                    handleInputChange(event, newInputValue)
                }}
                filterOptions={(x) => filterOptions(x)}
                value={value}
                fullWidth={fullWidth}
                disabled={disabled}
                className={className}
                classes={classes}
            />
        </React.Fragment>
    )

})

//Autocomplete based tag input; using Formik for value and setValue
//closes on blur
//output is string

CategoryAutoCompleteBase.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    moodiks: PropTypes.object.isRequired,
    maxValues: PropTypes.number,
    maxOptions: PropTypes.number,
    variant: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    onClose: PropTypes.func,
    disabled: PropTypes.bool,
}


const CategoryAutoComplete = withStyles(styles)(CategoryAutoCompleteBase);
export {CategoryAutoComplete};
