import React, {useState} from "react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/styles/withStyles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ExitButton from "../ExitButton/ExitButton";
import TopLine from "../TopBar";
import theme from "../../Theme";

import Grid from "@material-ui/core/Grid";
import {useField} from "formik";
import {useTranslation} from "react-i18next";
import MyFormikTextField from "../MyFormikTextField";

export const styles = {
    back_button: {
        position: 'absolute',
        top: '1%',
        left: '1%',
    },
    root: {
        flexGrow: 1,
    },
    error: {
        color: theme.palette.error.main,
    },

}

function isEmpty(str) {
    return (!str || 0 === str.length);
}

const RollingInputField = React.forwardRef(function RollingInputField(props, ref) {
    const {
        name,
        label,
        open,
        onClose,
        classes,
        params,
        alignContent = "center",
        kids,
        component = MyFormikTextField,
        ...other
    } = props;

    let ComponentProp = component;


    return (
        <Dialog fullScreen open={open}>
            <TopLine
                leftButton={<ExitButton className={classes.back_button} onExit={onClose}/>}
                title={label}
            />
            <Grid container
                  direction="column" justify="center" alignItems="center" alignContent={alignContent}
                  className={classes.root}>
                <Grid item>
                    <ComponentProp ns="recommendation" name={name} label={label} {...params} children={kids}
                                   onClose={onClose}  {...other}/>
                </Grid>
            </Grid>
        </Dialog>
    )
})

const doReplaceLabel = (label, displayFun, value) => {
    if (!value || !displayFun ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.length === 0)) {
        return label
    } else {
        return displayFun(value)
    }
}


const RollingInput = React.forwardRef(function RollingInput(props, ref) {
    const {
        name,
        label,
        type,
        disabled,
        params = {},
        kids,
        className,
        classes,
        component,
        isRolling = true,
        replaceLabel = false,
        displayFun = null,
        onOpen,
        onClose,
        ...other
    } = props;

    const {t} = useTranslation(['recommendation']);
    const [field, meta, helpers] = useField(name);
    const {value} = field;
    const {error} = meta;
    const [componentState, setComponentState] = useState({})
    const state = {state: componentState, setState: setComponentState};


    const {helperText, ...otherParams} = params;
    const has_error = !isEmpty(error);


    const translatedError = has_error ? t(error) : null;
    const tH = helperText ? t(helperText) : null;
    const translatedHelperText = has_error ? translatedError : tH;


    const text_color = disabled ? theme.palette.grey : (has_error ? theme.palette.error.main : theme.palette.text.primary);
    const [open, setOpen] = useState(false);

    const handleOpen = (...args) => {
        setOpen(true);
        if (onOpen)
            onOpen(...args)
    }
    const handleClose = (...args) => {
        console.log("calling set close");
        debugger;
        if (isRolling)
            setOpen(false);
        if (onClose) {
            onClose(...args)
        }
    }

    const displayLabel = replaceLabel ? doReplaceLabel(label, displayFun, value) : label;


    console.log("displayLabel: ", displayLabel);

    let ComponentProp = component ? component : MyFormikTextField;
    const saveState = component ? {saveState:state}: {};
    console.log('RollingInput: component, saveState', component, saveState)
    const text_field_classes = (({root})=>({root}))(classes)


    return (
        <div>
            {isRolling &&
            <React.Fragment>
                <Button
                    endIcon={<ChevronRightIcon/>}
                    disabled={disabled}
                    onClick={handleOpen}
                    style={{color: text_color}}
                >
                    {displayLabel}
                </Button>

                <RollingInputField name={name} label={label} params={otherParams} kids={kids}
                                   className={className}
                                   classes={classes}
                                   helperText={translatedHelperText}
                                   open={open} onClose={handleClose} type={type} component={component}
                                   {...other}
                />
            </React.Fragment>
            }
            {
                !isRolling &&
                <React.Fragment>
                    <ComponentProp name={name} label={label} {...otherParams} children={kids}
                                   className={className}
                                   classes={text_field_classes}
                                   helperText={translatedHelperText}
                                   onClose={handleClose}
                                   type={type} component={component}
                                   ns="recommendation"
                                   {...saveState}
                                   disabled={disabled}
                                   {...other}
                    />
                </React.Fragment>
            }
        </div>
    )
});

RollingInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    params: PropTypes.object,
    kids: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    className: PropTypes.string,
    type: PropTypes.any,
    classes: PropTypes.object,
    isRolling: PropTypes.bool
}

RollingInputField.propTypes = {
    label: PropTypes.string,
    params: PropTypes.object,
    kids: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    classes: PropTypes.object
}

export default withStyles(styles)(RollingInput);