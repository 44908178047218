import * as React from 'react';
import MuiTextField, {} from '@material-ui/core/TextField';
import {useField} from 'formik';
import {useTranslation} from "react-i18next";



function MyFormikTextField(props) {
    const {
        name,
        children,
        onBlur,
        helperText,
        disabled,
        ns,
        ...other
    } = props;


    const {t} = useTranslation([ns]);
    const [field, meta, helpers] = useField(name);
    const {onBlur: fieldOnBlur} = field;
    const {touched, isSubmitting, error: fieldError} = meta;

    const showError = touched && !!fieldError;

    const new_props = {
        error: showError,
        helperText: showError ? t(fieldError) : helperText,
        disabled: disabled ?? isSubmitting,
        onBlur:
            onBlur ??
            function (e) {
                fieldOnBlur(e ?? field.name);
            },
        ...field,
        ...other,
    }


    return (
            <MuiTextField {...new_props}>{children}</MuiTextField>
    );
}

MyFormikTextField.displayName = 'MyFormikMaterialUITextField';


export default MyFormikTextField;