import React, {useCallback, useEffect} from 'react';
import AuthUserContext from './context';
import {withFirebase} from '../Firebase';
import {useFirebaseContext} from "../Firebase/context";


const authenticationReducer = (state, msg) => {
    switch (msg.type) {
        case 'SET_AUTH_USER':
            return {...state, authUser: msg.payload.authUser}
        case 'SET_INVITE_ID':
            return {...state, inviteId: msg.payload.inviteId}
        case 'SET_INVITED_BY':
            return {...state, invitedBy: msg.payload.invitedBy}
        default:
            return state
    }
}

const useAuthentication = () => {
    const [state, dispatch] = React.useReducer(authenticationReducer,
        {
            authUser: null,
            inviteId: null,
            invitedBy: null
        });
    const firebase = useFirebaseContext();

    const setAuthUser = useCallback((authUser) => {
        dispatch({type: 'SET_AUTH_USER', payload: {authUser: authUser}})
    }, []);

    const setInviteId = useCallback((inviteId) => {
        dispatch({type: 'SET_INVITE_ID', payload: {inviteId: inviteId}})
    }, [])
     const setInvitedBy = useCallback((invitedBy) => {
        dispatch({type: 'SET_INVITED_BY', payload: {invitedBy: invitedBy}})
    }, [])


    useEffect(() => {
        const listener = firebase.auth.onAuthStateChanged(
            authUser => {
                if (authUser)
                    authUser.role = authUser.isAnonymous ? 'anonymous' : 'regular';
                authUser
                    ? setAuthUser(authUser)
                    : setAuthUser(null);
            },
        );

    }, [firebase.auth, setAuthUser])

    const ctx = {
        authUser: state.authUser,
        inviteId: state.inviteId,
        invitedBy: state.invitedBy,
        setInviteId,
        setInvitedBy
    }
    return ctx

}


const withAuthentication = Component => {
    console.log('withAuthentication: outer function')
    const WithAuthentication = (props) => {
        const state = useAuthentication();
        console.log('withAuthentication: props, state: ', {...props}, state);
        return (
            <AuthUserContext.Provider value={state}>
                <Component {...props} />
            </AuthUserContext.Provider>
        );
    }
    return withFirebase(WithAuthentication)
}


export default withAuthentication;