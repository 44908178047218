import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import { withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import {RecommendationEdit} from "./RecommendationEdit";
import Box from "@material-ui/core/Box";
import {useLazyQuery, graphID_to_num_str, int_to_num_str} from "../../hooks/gql_helpers";
import theme from "../../Theme";
import {get_recommendation, remapRecommendations} from "./QueryById";
import Loading from "../Loading";


const mapper = {'data': remapRecommendations};

export const styles = {
    root: {
        backgroundColor: theme.palette.background.paper,
    },
};


const RecommendationBase = (props) => {
    const {
        classes,
        firebase,
        match,
        id: id_prop,
        type: type_prop,
        handleClose
    } = props;

    //can be accessed via router or as component, so we need to figure out which params to use
    const id = !!id_prop ? id_prop : match.params.id;
    const initial_recommendation_type = !!id_prop ? type_prop :  match.params.type;
    const editing = !!id;
    const [type, setType] = useState(initial_recommendation_type);
    const [{data, loading, error, pagination}, getData] = useLazyQuery(firebase, mapper);
    const recommendation = data.length>0 ? data[0] : null;
    console.log('Recommendation: type_prop, initial_recommendation_type: ', type_prop, initial_recommendation_type, id_prop);

    useEffect(() => {
        if (id && !recommendation &&!loading) {
            console.log('Recommendation: useEffect: id: ', id);
            const id_str = typeof (id) ==='string'? graphID_to_num_str(id): int_to_num_str(id);
            getData(get_recommendation({id: id_str})).then(() =>{
                if (!initial_recommendation_type)
                    setType(data[0].type)
            } )
        } 
    }, [data, getData, id, initial_recommendation_type, loading, recommendation]);


    return <Box position='static' component='main' className={classes.root}>
        {loading && <Loading/>}

        {(!loading && !error && recommendation) &&
        <RecommendationEdit recommendation={recommendation} type={type} handleClose={handleClose}/>
        }

        {!editing &&
        <RecommendationEdit type={type}/>
        }
    </Box>
}


RecommendationBase.propTypes = {
id: PropTypes.number,
    type: PropTypes.string,
    handleClose: PropTypes.func
}


const if_authorized = authUser => authUser && authUser.role === 'regular';
const Recommendation = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(RecommendationBase);


export default Recommendation;
