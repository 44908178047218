import React, {useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import TopBar from "../TopBar/TopBar";
import ExitButton from "../ExitButton/ExitButton";
import Grid from "@material-ui/core/Grid";
import InfiniteFeed from "../InfiniteFeed";
import RollingRoutes, {Route} from "../RollingRoutes";
import TagView from "../TagView";
import theme from "../../Theme";
import {TagCard} from "./TagCard";
import Box from "@material-ui/core/Box";
// import {useCacheContext} from "../../hooks/use_cache";

export const styles = {
    root: {
        padding: '5px 5px',
    },
    background: {
        backgroundColor: theme.palette.common.white,
    },
    tag_root: {
        display: 'flex',
        padding: 16,
        alignItems: 'center',
    },
    divider: {}
};

const TagListBase = (props) => {
    const {
        classes,
        history,
        title,
        query,
        mapper,
        queryParams,
        moodiks = null,
        onClose = null
    } = props;

    console.log('TagList: query, queryParams: ', query, queryParams)
    const routes = {
        TagView: false
    };
    // const noOp =()=>{}

    // const {setCacheState = noOp} = useCacheContext() || {};
    const [counter, setCounter] = useState(0);

     const newQuery = () => {
        setCounter(c => c + 1)
    }

    const exitFun = () => {
        if (onClose) {
            onClose()
        } else history.goBack();
    };
    const {i18n} = useTranslation(['common']);
    const lang = i18n.language;

    const deleteFilter = (id) => {
        moodiks.doDeleteFilter(id);
        newQuery();
        // setCacheState(false)
    }

    console.log('TagList: moodiks: ', moodiks)

    return (
        <Box position='static' className={classes.root}>
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <div className={classes.background}>
                        <TopBar
                            title={title}
                            leftButton={(<ExitButton onExit={exitFun}/>)}
                        />
                        <Grid container position="static"
                              className={classes.background}>
                            <InfiniteFeed query={query} mapper={mapper}
                                          queryParams={{'counter': counter, ...queryParams}}>
                                {(item, i, hideShare, props) => (
                                    <TagCard
                                        tagId={item.tags[lang].tag_id}
                                        text={item.tags[lang].text}
                                        onClick={(tagId, text) => {
                                            routeState.openRoute('TagView',
                                                {
                                                    tagId: tagId,
                                                    text: text
                                                })
                                        }}
                                        onDelete={!!moodiks ? () => deleteFilter(item.id) : null}
                                    />
                                )}
                            </InfiniteFeed>
                        </Grid>
                        <Route dialog='TagView'>
                            <TagView {...routeState.props}/>
                        </Route>
                    </div>

                )}
            </RollingRoutes>
        </Box>
    )
}


TagListBase.propTypes = {
    classes: PropTypes.object.isRequired,
}

const if_authorized = authUser => !!authUser;
const TagList = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(TagListBase);


export default TagList;
