import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {FormikTextField} from "@dccs/react-formik-mui";
import MyFormikTextField from "../MyFormikTextField";
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from "@material-ui/core/IconButton";
import {useField} from "formik";
import Box from "@material-ui/core/Box";

export const styles = {};

const EndButtons = (props) => {
    const {
        url,
        setValue,
        onClose
    } = props;

    const doPaste = () => {
        navigator.clipboard.readText().then(
            clipText => {
                setValue(clipText);
                onClose(clipText)
            }
        )
    }

    const search = 'http://google.com/search?q=' + url;
    return (
        <React.Fragment>
            <IconButton href={search} target='_blank'>
                <SearchIcon/>
            </IconButton>
            <IconButton onClick={doPaste}>
                <FileCopyIcon/>
            </IconButton>
        </React.Fragment>
    )
}

const ExternalSearchBase = React.forwardRef(function ExternalSearchBase(props, ref) {
    const {
        onClose,
        ...other
    } = props;
    const [field, meta, helpers] = useField(props);
    const setValue = helpers.setValue;
    const value = field.value;


    const handleClose = (event) => {
        if (onClose) {
            onClose(null);
        }
    }

    return (
        <React.Fragment>
            <MyFormikTextField
                onBlur={handleClose}
                onKeyPress={(event)=>{
                    if(event.key==='Enter') handleClose(event)
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <EndButtons url={value} setValue={setValue} onClose={onClose}/>
                        </InputAdornment>
                    )
                }}
                {...other}/>
        </React.Fragment>
    )

})
ExternalSearchBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const ExternalSearch = withStyles(styles)(ExternalSearchBase);
export default ExternalSearch;
