import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import List from "@material-ui/core/List";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {RenderTree} from "./RenderTree";
import {PostComment} from "./PostComment";


export const styles = theme => ({
    list_root: {
        width: '100%',
    },
    grid: {
        flexGrow: 0,
        padding: '20px 10px',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        elevation: 0
    },
    toolbar: {
        padding: 0,
        margin: 0,
        width: '100%'
    }
});

const CommentContext = React.createContext(null);
const CommentsBase = React.forwardRef(function CommentsBase(props, ref) {
    const {
        classes,
        moodiks,
        comments,
        recommendationId,
        currentUid: uid_prop = null,
        handleCommentsChanged = () => {
        },
        gotoComments
    } = props;

    const [commentTree, setCommentTree] = useState({});
    const [firstTime, setFirstTime] = useState(true);
    const [commentsLookup, setCommentsLookup] = useState({});
    const [currentUid, setCurrentUid] = useState(uid_prop);
    const [valid, setValid] = useState(false);
    const commentsEndRef = useRef(null);
    const commentsStartRef = useRef(null);


    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    //scroll options not currently supported in Safari
    const scrollToBottom = () => {
        if (commentsEndRef.current)
            commentsEndRef.current.scrollIntoView({behavior: "smooth"})
    }

    const scrollToStart = () => {
        if (commentsStartRef.current)
            commentsStartRef.current.scrollIntoView(true)
        // commentsStartRef.current.scrollIntoView(true, {behavior: "smooth", block: "start"})
    }
    const add_level_to_tree = useCallback((tree, level, lookup) => {
        Object.values(lookup).forEach((c) => {
            // eslint-disable-next-line eqeqeq
            if (c.comment_id == level) {
                tree[c.id] = c;
                c.responses = {};
                add_level_to_tree(c.responses, c.id, lookup)
            }
        })
    }, []);


    const context = {
        commentsLookup: commentsLookup,
        commentTree: commentTree,
        setCommentTree: setCommentTree,
        setCommentsLookup: setCommentsLookup,
        moodiks: moodiks,
        recommendationId: recommendationId,
        currentUid: currentUid,
    };


    useEffect(() => {
        if (firstTime) {
            const temp_comments_by_id = {};
            comments.map((c) => temp_comments_by_id[c.id] = c);
            const comments_tree = {};
            add_level_to_tree(comments_tree, null, temp_comments_by_id);
            setCommentsLookup(temp_comments_by_id);
            setCommentTree(comments_tree);
            setValid(true);
        }
    }, [firstTime, add_level_to_tree, comments,]);


    useEffect(() => {
        debugger;
        if (gotoComments) {
            scrollToStart()
        }
    }, [gotoComments])

    const handleClose = () => {
        forceUpdate();
        scrollToBottom();
        if (handleCommentsChanged)
            handleCommentsChanged(Object.keys(commentsLookup).length)
    }

    //todo: fix scroll or convert fully to one input line at bottom of page

    const {t} = useTranslation(['recommendation']);
    const showBottomInput = Object.values(commentTree).length > 2;
    return <React.Fragment>
        <div ref={commentsStartRef}/>
        <Box ref={ref}>
            <Grid container direction='column'
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                  className={classes.grid}>
                <Grid item>
                    <h4 style={{margin: 0}}>{t('comments')}</h4>
                </Grid>
            </Grid>
        </Box>
        {valid &&
        <CommentContext.Provider value={context}>

            <List className={classes.list_root} component='div' disablePadding>
                {commentTree &&
                Object.values(commentTree).map((comment,i) =>
                    <RenderTree comment={comment} key={i}/>
                )}
            </List>
            <PostComment respondingTo={null} handleClose={handleClose}/>
            <div ref={commentsEndRef}/>

        </CommentContext.Provider>
        }
    </React.Fragment>

})
CommentsBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const Comments = withStyles(styles)(CommentsBase);
export default Comments;
export {CommentContext}
