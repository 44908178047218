import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import theme from "../../Theme";
import {Card} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import RollingRoutes, {Route} from "../RollingRoutes";
import UserList from "../UserList";
import {query_following, following_mapper, query_followers, followers_mapper, query_tags, tags_mapper} from "./queries";
import {query_string} from "../../hooks/gql_helpers";
import TagList from "../TagList";

export const styles = {
    root: {
        minHeight: '30px',
        margin: "7px 57px",
        background: theme.palette.background.paper,
        borderStyle: "solid",
        borderRadius: "4px",
        borderWidth: "1px",
        borderColor: "#CCCC",
        // boxShadow: "10px 10px 15px  lightgrey",
        backgroundColor: "white",
        position: 'relative'  // need this for absolute positioned children to work
    },
    button_text: {
        textTransform: "none"
    },
    button_root: {
        padding: 0
    },
    header: {
        fontSize: 12
    },
    value: {
        fontSize: 12
    }
};

const UserStatsBase = React.forwardRef(function UserStatsBase(props, ref) {
        const {
            classes,
            uid,
            stats,
            moodiks=null,
            markDirty = ()=>{}
        } = props;
        const {num_posts, num_followers, num_following, num_tags} = stats;
        const routes = {
            userList: false,
            tagsList: false
        }


        const Item = (props) => {
            const {
                header,
                value,
                onClick = null
            } = props;

            return (
                <Grid item xs={3} container direction='column' justifyContent="space-between" alignItems='center'
                      onClick={onClick}
                >
                    <Grid item className={classes.header}>
                        {header}
                    </Grid>
                    <Grid item className={classes.value}>
                        {value}
                    </Grid>
                </Grid>
            )
        }

        const showFollowing = (routeState) => {
            if (num_following > 0)
                routeState.openRoute('userList', {
                    title: t('following'),
                    query: query_following,
                    mapper: following_mapper,
                    queryParams: {user: query_string(uid)}
                })
        }

        const showFollowers = (routeState) => {
            if (num_followers > 0)
                routeState.openRoute('userList', {
                    title: t('followers'),
                    query: query_followers,
                    mapper: followers_mapper,
                    queryParams: {user: query_string(uid)}
                })
        }

        const showTags =(routeState) =>{
            if (num_tags>0)
                routeState.openRoute('tagsList',{
                    title: t('tags'),
                    query: query_tags,
                    mapper: tags_mapper,
                    queryParams: {user: query_string(uid)}
                })
        }


        const {t} = useTranslation(['recommendation']);

        return (
            <Box className={classes.root}>
                <RollingRoutes initialState={routes}>
                    {(routeState) => (
                        <React.Fragment>
                            <Grid container direction='row' justifyContent='space-between'>
                                <Item header={t('posts')} value={num_posts}/>
                                <Item header={t('followers')} value={num_followers} onClick={() => {
                                    showFollowers(routeState)
                                }}/>
                                <Item header={t('following')} value={num_following} onClick={() => {
                                    showFollowing(routeState)
                                }}/>
                                <Item header={t('tags')} value={num_tags} onClick={()=>{
                                    showTags(routeState)
                                }}
                                />
                            </Grid>
                            <Route dialog='userList'>
                                <UserList {...routeState.props}
                                          onClose={(dirty) => {
                                              markDirty(dirty);
                                              routeState.closeRoute('userList')
                                          }}
                                />
                            </Route>
                            <Route dialog='tagsList'>
                                <TagList {...routeState.props}
                                    moodiks={moodiks}
                                    onClose={()=>{
                                        routeState.closeRoute('tagsList')
                                    }}
                                    />
                            </Route>
                        </React.Fragment>
                    )}
                </RollingRoutes>
            </Box>
        )

    }
)
UserStatsBase.propTypes =
    {
        classes: PropTypes.object.isRequired,
    }


const UserStats = withStyles(styles)(UserStatsBase);
export default UserStats;
