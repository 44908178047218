import React, {useCallback, useEffect, useRef, useState} from "react";
import SearchApi from './SearchApi'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import theme from "../../Theme";
import throttle from 'lodash/throttle';
import {useField} from "formik";
import LocationButton from "../LocationAPI";
import {makeStyles} from "@material-ui/core/styles";
import Loading from "../Loading";
import {useTranslation} from "react-i18next";

var fastDeepEqual = require('fast-deep-equal');

/*
    SearchAutoComplete is controlled by the Formik value and setValue passed in props (only works as Formik child)
    Paste input is detected and a search search is suppressed
    search is performed by the SearchAPI, location is the default search mode
*/


const styles = {
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
};

const useStyles = makeStyles((theme) => (styles));


function SearchAutocomplete(props) {
    const {
        variant,
        fullwidth,
        limit = 5,
        closeOnPaste,
        onClose,
        onChange,
        service = 'location',
        label,
        className,
        initialInput: initial_input_prop = '' // only the text input, not the returned object
    } = props;

    const [field, meta, helpers] = useField(props);
    const setValue = helpers.setValue; // actual result for Formik -> object
    const value = field.value;
    console.log('SearchAutocomplete: value: ', value);
    const showMyLocation = navigator.geolocation;
    const classes = useStyles();

    const {i18n} = useTranslation();
    const {language} = i18n
    const [inputValue, setInputValue] = useState(''); //value in text field
    const [options, setOptions] = useState([]); // list of search results to select from
    const primaryService = useRef(new SearchApi(service, language));
    const secondaryService = useRef(new SearchApi('reverse_geocode', language));
    const initialInput = useRef('');
    const [initialFetch, setInitialFetch] = useState(null);
    const [loading, setLoading] = useState(false);


    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                primaryService.current.autocomplete(request, callback);
            }, 800),
        [primaryService.current],
    );

    if (!fastDeepEqual(initialInput.current, initial_input_prop)) {
        initialInput.current = initial_input_prop;
        console.log('SearchAutocomplete: changed initialValue, setting value to: ', initial_input_prop);
        setInputValue(initialInput.current);
        if (initialInput.current.length !== 0)
            setInitialFetch(initial_input_prop);
    }


    const handleSelect = useCallback((event, newValue) => {
        const newOptions = newValue ? [newValue, ...options] : options;
        if (service === "location") {
            const loc_obj = primaryService.current.location_object(newValue);
            if (onChange)
                onChange(event, loc_obj)
            setValue(loc_obj);
        } else {
            setValue(newValue);
            if (onChange)
                onChange(event, newValue)
        }
        setOptions(newOptions);
    }, [onChange, options, service, setValue])

    const fetch_once = useCallback((search_string) => {
        setInitialFetch('');
        primaryService.current.autocomplete({q: search_string, limit: limit}, (results) => {
            if (results) {
                const new_options = [...results];
                setOptions(new_options);
                const new_value = results[0];
                handleSelect(undefined, new_value);
                handleClose(undefined);
            }
        })
    }, [handleSelect, limit]);

    useEffect(() => {
        if (initialFetch) {
            console.log('SearchAutocomplete: initialFetch: ', initialFetch);
            fetch_once(initialFetch)
        }
    }, [fetch_once, initialFetch])

    React.useEffect(() => {
        if (initialFetch)
            return;
        console.log('SearchAutocomplete: useEffect input ', inputValue);

        let active = true;

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }
        fetch({q: inputValue, limit: limit}, (results) => {
            console.log('SearchAutocomplete: new results:', results);
            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });
        return () => {
            active = false;
        };
    }, [fetch, initialFetch, inputValue, limit, value]);


    //
    // React.useEffect(() => {
    //     let active = true;
    //
    //     if (inputValue === '') {
    //         setOptions([]);
    //         return undefined;
    //     }
    //     if (!preventLookup) {
    //         fetch({q: inputValue, limit: limit}, (results) => {
    //             console.log('SearchAutocomplete: new results:', results);
    //             if (active) {
    //                 let newOptions = [];
    //                 if (value) {
    //                     newOptions = [value];
    //                 }
    //                 if (results) {
    //                     newOptions = [...newOptions, ...results];
    //                 }
    //                 setOptions(newOptions);
    //             }
    //         });
    //     } else {
    //         console.log('SearchAutoComplete: prevent lookup')
    //         setValue(inputValue);
    //         console.log('SearchAutoComplete: setValue', inputValue)
    //         let newOptions = [inputValue];
    //         setOptions(newOptions);
    //         setPreventLookup(false)
    //     }
    //
    //     return () => {
    //         active = false;
    //     };
    // }, [fetch, inputValue, limit, preventLookup, setValue, value]);

    const handleClose = (event) => {
        if (onClose) {
            onClose(event);
        }
    }


    const handleInputChange = (event, newInputValue) => {
        //dont look up pasted values, to save money
        if (closeOnPaste && (event && event.nativeEvent.inputType === "insertFromPaste")) {
            setInitialFetch(newInputValue)
        }
        console.log('SearchAutoComplete: handleInputChange', newInputValue);
        setInputValue(newInputValue);
    }

    const handleCurrentLocation = (position) => {
        const query = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        };
        setLoading(true);
        console.log('SearchAutoComplete: handleLocation: ', position);
        secondaryService.current.autocomplete(query, (result) => {
            console.log('SearchAutoComplete: location lookup result: ', result)
            setLoading(false);
            if (result) {
                handleSelect(undefined, result);
            }
        })
    }

    return (
        <Grid container direction='row' alignItems="center" justifyContent="space-between">
            <Grid item>
                {primaryService.current &&
                <Autocomplete
                    style={{width: 300}}
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : primaryService.current.display(option, 'display')
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onClose={handleClose}
                    onChange={handleSelect}
                    onInputChange={handleInputChange}

                    renderInput={(params) => (
                        <TextField
                            {...params} label={label} variant={variant} fullwidth={fullwidth}
                            className={className}
                        />
                    )}
                    renderOption={(option) => {

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body1" color="textSecondary">
                                        {primaryService.current.display(option, 'line1')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {primaryService.current.display(option, 'line2')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                >

                </Autocomplete>
                }
            </Grid>
            {loading &&
            <Loading/>
            }
            {showMyLocation &&
            <Grid item>
                <LocationButton onClick={handleCurrentLocation}/>
            </Grid>
            }
        </Grid>
    )


}

export default SearchAutocomplete;