export const ACCOUNT = '/account';
export const ACCOUNTADMIN = '/accountadmin';
export const DEMOGRAPHICS = '/demographics';
export const EDIT_PROFILE = '/edit_profile';
export const EDIT_RECOMMENDATION = '/edit_recommendation/';
export const EDIT_RECOMMENDATIONS = '/edit_recommendation/:id/:type';
export const FEED = '/feed';
export const FILTER = '/filter'
export const INTRODUCTION_INSTALL = '/introduction_install';
export const INTRODUCTION = '/introduction'
export const INVITEFRIENDS ='/invitefriends';
export const LANDING = '/landing';
export const MATCH_MENU = '/match_menu';
export const MATCHES ='/matches';
export const MY_RECOMMENDATIONS = '/my_recommendations';
export const PASSWORD_FORGET = '/pw-forget';
export const QUESTIONNAIRE_BASE = '/questionnaire'
export const QUESTIONNAIRE = QUESTIONNAIRE_BASE + '/:name';
export const QUESTIONNAIRE_BUTTON = '/questionnaire_button'
export const RECOMMENDATION = '/recommendation/RECOMMENDATION';
export const RECOMMENDATION_BASE = '/recommendation/:type';
export const REPORT = '/report/:id'
export const REPORT_BASE = '/report/'
export const REQUEST = '/recommendation/REQUEST'
export const RESPONSES = '/responses/:id'
export const RESPONSES_BASE = '/responses/'
export const SEARCH_VIEW = '/search'
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const SIGN_UP = '/signup/:id';
export const SIGN_UP_REDIRECT = '/signup_redirect/:id';
export const USER_PAGE_BASE = '/user_page/';
export const USER_PAGE =USER_PAGE_BASE +':uid';
export const VIEW_RECOMMENDATION = '/view_recommendation/:id/:r'
export const VIEW_RECOMMENDATION_BASE = '/view_recommendation/'
export const NOTIFICATION_CENTER = '/notification_center'
