import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App/app';
import './i18n';
import Firebase, { FirebaseContext } from './components/Firebase';

console.log('public url: ', process.env.PUBLIC_URL)


ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
export {template} from "./utils/TransformData";
export {remap} from "./utils/TransformData";
export {look_up} from "./utils/TransformData";
export {attribute_values} from "./utils/TransformData";
export {category_text} from "./utils/TransformData";
export {likes} from "./utils/TransformData";