import React, {useEffect, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import TopBar from "../TopBar";
import Grid from "@material-ui/core/Grid";
import {RecommendationCard} from "../Recommendation";
import InfiniteFeed from "../InfiniteFeed/InfiniteFeed";
import {gql, graphID_to_int, graphID_to_num_str, reduce_tags, useLazyQuery} from "../../hooks/gql_helpers";
import ExitButton from "../ExitButton/ExitButton";
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import * as ROUTES from "../../constants/routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Loading from "../Loading";
import {RecommendationCardSmall} from "../Recommendation/RecommendationCardSmall";
import RollingRoutes, {Route} from "../RollingRoutes";
import ViewRecommendation from "../ViewRecommendation/ViewRecommendation";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import {category_text, remap} from "../../utils/TransformData";

export const styles = {
    root: {
        width: '100%'
    },
    tagField: {
        marginBottom: '10px'
    },
    tag: {
        margin: '2px'
    },
};


const get_recommendations = gql`{
  recommendationsById(id: ${'id'}) {
    edges {
      node {
        relatedRecommendations(first: ${'first'}, after: ${'after'}) {
          edges {
            node {
              id
              url
              uid
              lang
              createdAt
              deactivated
              userProfile{
                nickname
                avatarUrl
              }
              images {
                url
                alt
              }
              subject
              category {
                id
                keywords {
                  text
                  lang
                }
              }
              tags {
                keywords {
                  text
                }
              }
              location {
                displayName
                city
                state
                country
              }
              experience
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
}
`

const recommendation_template = {
    uid: 'uid',
    url: 'url',
    deactivated: 'deactivated',
    nickname: 'userProfile.nickname',
    avatar_url: 'userProfile.avatarUrl',
    subject: 'subject',
    created_at: 'createdAt',
    lang: 'lang',
    // category: 'category.keywords[0].text',
    category_id: 'category.id',
    location: {
        address: {
            city: 'location.city',
            country: 'location.country'
        },
    },
    experience: 'experience',
};

const image_template = {
    src: 'url',
    alt: 'alt'
}


const remapRecommendations = (raw) => {
    console.log(raw.data)
    return raw.data.recommendationsById.edges[0].node.relatedRecommendations.edges.map((r) => {
        const mapped = remap(r.node, recommendation_template);
        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.id = graphID_to_int(r.node.id);
        mapped.images = r.node.images.map((i) => {
            return remap(i, image_template);
        });
        return mapped
    });
}

const extractPagination = (raw) => {
    return raw.data.recommendationsById.edges[0].node.relatedRecommendations.pageInfo;
}

const mapper = {'data': remapRecommendations, 'pagination': extractPagination}


const get_request = gql`{
   recommendationsById(id: ${'id'}) {
    edges {
      node {
        id
        type,
        subject
        lang
        category {
          id
          keywords {
            text
            lang
          }
        }
        tags {
          keywords {
            text
            lang
          }
        }
        supercategories{
          keywords{
            lang
            text
          }
        }
        createdAt
        experience
        location {
          displayName
          city
          state
          country
        }
        deactivated
        userProfile{
          nickname
          avatarUrl
        }
      }
    }
  }
}`

const request_template = {
    subject: 'subject',
    category_id: 'category.id',
    lang: 'lang',
    type: 'type',
    created_at: 'createdAt',
    experience: 'experience',
    location: {
        address: {
            city: 'location.city',
            country: 'location.country'
        },
    },
    nickname: 'userProfile.nickname',
    avatar_url: 'userProfile.avatarUrl',
    deactivated: 'deactivated'
}

const remapRequest = (raw) => {
    debugger;
    return raw.data.recommendationsById.edges.map((r) => {
        const mapped = remap(r.node, request_template);
        mapped.id = graphID_to_int(r.node.id);
        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.supercategories = reduce_tags(r.node.supercategories, r.node.lang);
        return mapped
    });
}

const request_mapper = {data: remapRequest, pagination: null};

const filter = {
    deactivated: false
}


const ResponsePageBase = (props) => {
    const {
        classes,
        match,
        history,
        firebase,
        id: id_prop,
        onClose = null,
        canEdit = false
    } = props;

    const [counter, setCounter] = useState(0);
    const [queryParams, setQueryParams] = useState(null);
    const [request, setRequest] = useState(null);

    const [{data: requestData, loading, error, pagination}, getData] = useLazyQuery(firebase, request_mapper);
    const routes = {'viewRecommendation': false}

    useEffect(() => {
        const id = id_prop ? id_prop : match.params.id;
        if (id_prop && !onClose)
            console.error('ResponsePage: called wth id and no onClose provided.');

        console.log('ResponsePage:id:', id);
        console.log('ResponsePage:onClose:', onClose);
        if (id) {
            const id_str = '"' + id.toString() + '"';
            setQueryParams({id: id_str});
            getData(get_request({id: id_str}))
        } else {
            setQueryParams({})
        }
    }, [getData, match.params.id]);

    useEffect(() => {
        const result = requestData[0];
        console.log(result);
        setRequest(result);
    }, [requestData]);

    const handleAvatarClick = (userId) => {
        var id_string = userId.toString();
        const link = ROUTES.USER_PAGE_BASE + id_string;
        console.log(link);
        history.push(link)
    };

    const handleAddResponse = () => {
        //force re-render
        setCounter((c) => c + 1)
    }

    const handleClose = () => {
        if (onClose) {
            console.log('ResponsePage: calling onClose: ', onClose);
            onClose()
        } else {
            console.log('ResponsePage: calling history.goBack: ')
            history.goBack()
        }
    }


    const {t} = useTranslation(['recommendation']);
    return (
        <div className={classes.root}>
            <TopBar
                leftButton={(<ExitButton onExit={handleClose}/>)}
                title={t('Responses')} endIcon={<MailOutlineOutlinedIcon/>}
            />
            <CssBaseline/>
            {loading && <Loading/>}
            {!!request &&
            <Grid container position="static" component="main">
                <Grid item>
                    <RecommendationCard recommendation={request} hideShare noActions showAvatar canRespond
                                        onClose={handleAddResponse}
                                        subjectClickable={false}
                                        canEdit={canEdit}
                    >
                        <Box className={classes.tagField}>
                            {request.tags.map((tag, i) => (
                                <Chip className={classes.tag} variant='outlined' key={i.toString()}
                                      label={'#' + tag}/>
                            ))}
                        </Box>
                    </RecommendationCard>
                </Grid>
            </Grid>
            }
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <React.Fragment>
                        {queryParams &&
                        <Grid container position="static" component="main">

                            <InfiniteFeed query={get_recommendations} mapper={mapper} filter={filter}
                                          queryParams={{'counter': counter, ...queryParams}}
                                // onClick={handleAvatarClick}
                            >
                                {(recommendation, i, hideShare, props) => (
                                    <Grid item xs={6}>
                                        <RecommendationCardSmall
                                            recommendation={recommendation}
                                            onClick={() => {
                                                routeState.openRoute('viewRecommendation', {
                                                    'recId': recommendation.id, 'canEdit': false
                                                })
                                            }}
                                        />
                                    </Grid>
                                )}
                            </InfiniteFeed>
                        </Grid>
                        }
                        <Route dialog='viewRecommendation'>
                            <ViewRecommendation {...routeState.props}  />
                        </Route>
                    </React.Fragment>
                )}
            </RollingRoutes>

        </div>
    )
}


ResponsePageBase.propTypes = {
    id: PropTypes.number,
    onClose: PropTypes.func,
    canEdit: PropTypes.bool
}

const if_authorized = authUser => authUser && authUser.role === 'regular';

const ResponsePage = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(ResponsePageBase);


export default ResponsePage;
