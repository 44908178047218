import React from "react";
import PropTypes from "prop-types";
import theme from "../../Theme";
import clsx from "clsx";

import withStyles from "@material-ui/styles/withStyles";

export const styles = {
    root: {
        lineHeight: "100%",
        marginBottom: '0px',
        marginTop: '0px'
    }
};

const NewlineTextBase = React.forwardRef(function NewlineTextBase(props, ref) {
    const {
        className,
        classes,
        text
    } = props;

    if (!text) return (<p></p>)

    return (
        <React.Fragment>
            {text.split('\n').map((str, i) =>
                <p key={i.toString()}
                   className={clsx(classes.root, className)}
                >
                    {str}
                </p>
            )}
        </React.Fragment>
    )

})
NewlineTextBase.propTypes = {
    className: PropTypes.object,
    text: PropTypes.string.isRequired
}


const NewlineText = withStyles(styles)(NewlineTextBase);
export {NewlineText};
