import React, {useEffect, useReducer, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import TopBar from "../TopBar";
import Grid from "@material-ui/core/Grid";
import InfiniteFeed from "../InfiniteFeed/InfiniteFeed";
import {gql, graphID_to_int, query_string, reduce_tags} from "../../hooks/gql_helpers";
import ExitButton from "../ExitButton/ExitButton";
import theme from "../../Theme";
import Divider from "@material-ui/core/Divider";
import {useFilterContext} from "../Feed";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {RecommendationCardSmallRouting} from "../Recommendation/RecommendationCardSmall";
import RollingRoutes, {Route} from "../RollingRoutes";
import ViewRecommendation from "../ViewRecommendation";
import RecommendationTypeSelector from "../RecommendationTypeSelector";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';
import AvatarAction from "../AvatarAction";
import ResponsePage from "../ResponsePage";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import {category_text, likes, remap} from "../../utils/TransformData";
import {BioText} from "../TextUtils";
import {useAsync} from "react-async";
import {getMoodiks} from "../Moodiks";
import UserStats from "../UserStats";
import {Banner} from "../Banner/Banner";

export const styles = {
    infiniteFeed: {
        backgroundColor: theme.palette.grey["100"],
    },
    isDisabled: {
        backgroundColor: theme.palette.text.disabled
    },
    isFollowing: {
        backgroundColor: theme.palette.success.main
    },
    addFollow: {
        backgroundColor: theme.palette.secondary.main
    },
    stats: {
        width: '100%'
    }
};


const get_user_feed = gql`{
    recommendationsByUser(user: ${'user_id'}, rtype: ${'rtype'}, active: ${'active'}, first: ${'first'}, after: ${'after'}) {
    edges {
      node {
        id
        createdAt
        url
        subject
        type,
        experience
        lang
        images {
          alt
          url
        }
        category {
          keywords {
            text
            lang
          }
        }
        tags {
          keywords {
            lang
            text
          }
        }
        location {
          city
          country
        }
        likedBy{
          totalCount
          edges{
             node{
               uid
               nickname
               avatarUrl
             }
          }
        }
        comments {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        relatedRecommendations{
            totalCount
            edges{
              node{
                id
              }
            }
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`

const recommendation_template = {
    url: 'url',
    subject: 'subject',
    // category: 'category.keywords[0].text',
    tags: 'tags',
    type: 'type',
    created_at: 'createdAt',
    location: {
        address: {
            city: "location.city",
            country: "location.country",
        },
    },
    experience: 'experience',
    num_comments: 'comments.totalCount',
    num_likes: 'likedBy.totalCount',
    num_responses: 'relatedRecommendations.totalCount',
};

const image_template = {
    src: 'url',
    alt: 'alt'
}


const remapRecommendations = (raw) => {
    return raw.data.recommendationsByUser.edges.map((r) => {
        const mapped = remap(r.node, recommendation_template);
        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.id = graphID_to_int(r.node.id);
        mapped.images = r.node.images.map((i) => {
            return remap(i, image_template);
        });
        mapped.liked_by = likes(r.node.likedBy);
        return mapped
    });
}

const extractPagination = (raw) => {
    return raw.data.recommendationsByUser.pageInfo;
}

const mapper = {'data': remapRecommendations, 'pagination': extractPagination}


const UserPageBase = (props) => {
    const {
        classes,
        history,
        firebase,
        moodiks: moodiks_param,
        uid,
        nickname,
        avatarUrl,
        onClose = null,
    } = props;

    const {filters, toggleFollow} = useFilterContext() || {};
    const [isFollowingNoContext, setIsFollowingNoContext] = useState(null);
    const [isFollowingNoContextValid, setIsFollowingNoContextValid] = useState(false);

    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;

    const [profileValid, setProfileValid] = useState(false);
    const [statsValid, setStatsValid] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const [bio, setBio] = useState(null);
    const [bannerUrl, setBannerUrl] = useState(null);
    const [userStats, setUserStats] = useState({
        banner_url: undefined
    })

    let isFollowing = null;
    const [followStateChanged, setFollowStateChanged] = useState(false);

    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});
    console.log('UserPage: filters: ', filters, !!filters);

    const paramReducer = (params, msg) => {
        return {...params, ...msg}
    }
    const [queryParams, setParams] = useReducer(paramReducer, {
        rtype: query_string('RECOMMENDATION'),
        active: query_string('True'),
        user_id: query_string(uid)
    });

    console.log('UserPage isFollowingNoContextValid: ', isFollowingNoContextValid);

    if (!moodiks_param) {
        isFollowing = filters.findIndex((f) => f.follow_uid === uid) !== -1
        console.log('UserPage isFollowing (context): ', filters.findIndex((f) => f.follow_uid === uid) !== -1)
    } else {
        if (!isFollowingNoContextValid) {
            moodiks_param.doGetIsFollowing(uid).then((result) => {
                setIsFollowingNoContext(result['is_following']);
                setIsFollowingNoContextValid(true);
                isFollowing = result['is_following'];
                console.log('UserPage isFollowing (moodiks): ', result['is_following'])
            })
        }
    }
    const userIsSelf = firebase.getUid() === uid;
    console.log('UserPage: firebase.getUid(), uid , firebase', firebase.getUid(), uid, firebase);


    useEffect(() => {
        if (moodiksValid) {
            setProfileValid(false);
            setStatsValid(false);
            setLoadingProfile(true)
            setLoadingStats(true)
            moodiks.doGetUserProfile(true, uid).then((data) => {
                const {user_profile} = data;
                setBio(user_profile.bio);
                setBannerUrl(user_profile.banner_url);
                setLoadingProfile(false);
                setProfileValid(true)
            })
            moodiks.doGetUserStats(uid).then((data) => {
                const {user_stats} = data;
                setUserStats(user_stats)
                setLoadingStats(false)
                setStatsValid(true)
            })
        }

    }, [moodiks, moodiksValid, uid])


    const {t} = useTranslation(['recommendation']);


    const routes = {
        viewRecommendation: false,
        responses: false
    };

    const exitFun = () => {
        if (onClose) {
            console.log('UserPage:exitFun: followStateChanged: ', followStateChanged)
            onClose(followStateChanged)
        } else history.goBack();
    };

    const handle_toggle_follow = async (user_id, isFollowing) => {
        setSnackBar({open: true, message: t('saving'), severity: 'info'})
        let result;
        if (toggleFollow)
            result = await toggleFollow(uid, isFollowing)
        else {
            try {
                const data = await moodiks.doSetIsFollowing(user_id, isFollowing)
                const {is_following} = data;
                setIsFollowingNoContext(is_following)
                result = {success: true, message: ''}
            } catch (e) {
                result = {success: false, message: e}
            }
        }
        if (result.success) {
            setFollowStateChanged(true);
            setSnackBar({open: true, message: t('success'), severity: 'success'})
        }
        else
            setSnackBar({open: true, message: t('error: ') + result.message, severity: 'error'})
    }


    const showIsFollowing = isFollowingNoContextValid ? isFollowingNoContext : isFollowing;
    const followIcon = showIsFollowing
        ? (<CheckSharpIcon/>)
        : (<AddOutlinedIcon/>);

    const editButtonClass = userIsSelf
        ? classes.isDisabled
        : showIsFollowing
            ? classes.isFollowing
            : classes.addFollow;

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }


    return (
        <div>
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <div className={classes.infiniteFeed}>
                        <TopBar
                            title={nickname} endIcon={<AccountCircleOutlinedIcon/>}
                            leftButton={(<ExitButton onExit={exitFun}/>)}
                        />
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            open={snackBar.open}
                            autoHideDuration={2000}
                            onClose={closeSnackbar}
                        >
                            <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                                {snackBar.message}
                            </Alert>
                        </Snackbar>

                        <Grid container direction="column"
                              justifyContent="space-evenly"
                              alignItems="center">
                            <Grid item>
                                <Banner src={bannerUrl}>
                                    <AvatarAction
                                        src={avatarUrl} alt={nickname}
                                        icon={followIcon}
                                        onClick={() => {
                                            // noinspection JSIgnoredPromiseFromCall
                                            handle_toggle_follow(uid, !showIsFollowing)
                                        }}
                                        disabled={userIsSelf}
                                        classes={{editButton: editButtonClass}}
                                    />
                                </Banner>
                            </Grid>
                            {nickname}
                            <Divider/>
                            {statsValid &&
                                <Grid className={classes.stats} item>
                                    <UserStats uid={uid} stats={userStats}/>
                                </Grid>
                            }
                            {profileValid &&
                                <Grid item>
                                    <BioText text={bio}/>
                                </Grid>
                            }
                        </Grid>

                        {queryParams &&
                            <Grid container position="static"
                                  className={classes.infiniteFeed}>
                                <RecommendationTypeSelector
                                    onClick={(params, query) => setParams(params)}
                                />
                                <InfiniteFeed query={get_user_feed} mapper={mapper} queryParams={queryParams}>
                                    {(recommendation, i, hideShare, props) => (
                                        <Grid item xs={6}>
                                            <RecommendationCardSmallRouting
                                                recommendation={recommendation}
                                                keyProp={i.toString()}
                                                routeState={routeState}
                                                {...props}
                                            />
                                        </Grid>
                                    )}
                                </InfiniteFeed>
                            </Grid>
                        }
                        <Route dialog='viewRecommendation'>
                            <ViewRecommendation {...routeState.props}  />
                        </Route>
                        <Route dialog='responses'>
                            <ResponsePage {...routeState.props}  />
                        </Route>
                    </div>
                )}
            </RollingRoutes>
        </div>
    )
}


UserPageBase.propTypes = {
    classes: PropTypes.object,
    moodiks: PropTypes.object,
    uid: PropTypes.number.isRequired,
    nickname: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func
}

const if_authorized = authUser => authUser && authUser.role === 'regular';

const UserPage = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(UserPageBase);


export default UserPage;
