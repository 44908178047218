import React from "react";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from '@material-ui/core/MobileStepper';
import ImageList from "@material-ui/core/ImageList";
import LazyImage from "../LazyImage/LazyImage";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import theme from "../../Theme";
import ImageListItem from "@material-ui/core/ImageListItem";
import {useWidth} from "../../hooks/use_width";
import createTheme from "@material-ui/core/styles/createTheme";
import {autoPlay} from 'react-swipeable-views-utils';
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Box from "@material-ui/core/Box";
import {Dialog} from "@material-ui/core";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const my_theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 400,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    }
});


export const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageFrame: {
        height: 300,
        position: 'relative',
        width: '100%',
        maxWidth: '400',
    },
    image: {
        objectFit: 'cover',
        // width: '100%',
        // height: 'auto',
    },
    title: {
        color: theme.palette.background.paper,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    fullscreen_element: {
        fontSize: 30,
        color: theme.palette.grey[300],
        background: 'rgba(0, 0, 0, 0.5)'
    },
    nav_element: {
        color: 'rgba(0,0,0,0)',
        '&:hover': {
            fontSize: 60,
            color: theme.palette.grey[300],
            background: 'rgba(0, 0, 0, 0.5)'
        }
    },
    left_nav: {
        position: 'absolute',
        left: '0px',
        top: '40%',
    },
    right_nav: {
        position: 'absolute',
        right: '0px',
        top: '40%',
    },
    fullscreen_nav: {
        position: 'absolute',
        right: '0px',
        top: '0px'
    },
    fullscreen_image: {
        // height: '100%',
        height: 'auto',
        width: '100%',
        // minHeight: '100%',
        // minWidth: '100%',
        transform: 'rotate(90deg) translateY(-100%)',
        transformOrigin: 'top left'
        // position: 'absolute',
        // top:'-100%',
        // bottom:'-100%',
        // left:'-100%',
        // right:'-100%',
        // margin: 'auto'
    },
    fullscreen_image_wrapper: {
        zIndex: 9999,
        position: 'fixed',
        padding: 0,
        margin: 0,

        top: 0,
        left: 0,

        width: '100%',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        /*width: '100%',
        minHeight: "calc(100vh)",
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',*/
    },
    stepper: {
        paddingTop: 0,
        marginTop: '5px',
        // position: 'relative',
        // top: '-30px',
    }
};


const ImageGallery = React.forwardRef(function RecommendationCard(props, ref) {
    const {
        images,
        fullscreen: fullscreen_enabled = false,
        classes
    } = props;

    console.log('ImageGallery: images: ', images);

    const max_steps = images.length;
    const [activeStep, setActiveStep] = React.useState(0);
    const [fullscreen, setFullscreen] = React.useState(false);
    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    const handleCloseFullscreen = () => {
        setFullscreen(false);
        document.removeEventListener('mousedown', handleCloseFullscreen)
    };
    const handleOpenFullscreen = () => {
        setFullscreen(true);
        document.addEventListener('mousedown', handleCloseFullscreen)
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if (!images || images.length === 0) {
        return (<React.Fragment></React.Fragment>);
    }

    const NextButton = (props) => {
        return (
            <Button {...props} onClick={handleNext} disabled={activeStep === max_steps - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft className={classes.nav_element}/> :
                    <KeyboardArrowRight className={classes.nav_element}/>}
            </Button>
        )
    };
    const BackButton = (props) => {
        return (
            <Button  {...props} onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight className={classes.nav_element}/> :
                    <KeyboardArrowLeft className={classes.nav_element}/>}
            </Button>
        );
    }

    const FullscreenButton = (props) => {
        return (
            <Button {...props} onClick={handleOpenFullscreen}>
                <FullscreenIcon className={classes.fullscreen_element}/>
            </Button>
        )
    }

    const FullscreenWrapper = (props) => {
        const {image} = props;
        return (
            // <Box className={classes.fullscreen_image_wrapper}>
                <Box
                    sx={{
                        zIndex: 9999,
                        position: 'fixed',
                        padding: 0,
                        margin: 0,

                        top: 0,
                        left: 0,

                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${image.src})`,
                        objectFit: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundOrigin: 'content-box',
                        // transform: 'rotate(90deg) translateY(-100%)',
                        // transformOrigin: 'top left'
                    }}

                />
                // <LazyImage className={classes.fullscreen_image} src={image.src} alt={image.alt}/>
            // </Box>
        )
    }


    return (
        <div className={classes.root}>
            <Box className={classes.imageFrame}>
                <SwipeableViews
                    axis='x'
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >

                    {images.map((image, i) => (
                        <div key={`${i}`}>
                            <LazyImage
                                className={classes.image}
                                src={image.src} alt={image.alt}/>
                        </div>
                    ))}

                </SwipeableViews>
                {fullscreen_enabled &&
                <React.Fragment>
                    <Dialog fullscreen open={fullscreen} onClose={handleCloseFullscreen}>
                        <FullscreenWrapper image={images[activeStep]}/>
                    </Dialog>
                    <FullscreenButton className={classes.fullscreen_nav}/>
                </React.Fragment>
                }
                {images.length > 1 &&
                <React.Fragment>
                    <BackButton className={classes.left_nav}/>
                    <NextButton className={classes.right_nav}/>
                </React.Fragment>
                }
            </Box>
            {images.length > 1 &&
            <React.Fragment>
                <MobileStepper
                    className={classes.stepper}
                    steps={max_steps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={<span/>}
                    backButton={<span/>}
                />
            </React.Fragment>

            }
        </div>
    )

})


ImageGallery.propTypes = {
    images: PropTypes.array.isRequired,
    classes: PropTypes.object,
    fullscreen: PropTypes.bool
}

export default withStyles(styles)(ImageGallery)