import {gql, graphID_to_int, point_to_coords} from "../../hooks/gql_helpers";
import {remap} from "../../utils/TransformData";

const filter_query = gql`{
  filters(fType: "") {
    edges {
      node {
        id
        fType
        followUid
        following {
          avatarUrl
          nickname
        }
        name
        tags {
          keywords {
            lang
            tagId
            text
          }
        }
        location {
          coords
          displayName
          road
          houseNumber
          city
          state
          postcode
          country
        }
        parameters
      }
    }
  }
}
`


const filter_template = {
    f_type: 'fType',
    follow_uid: 'followUid',
    nickname: 'following.nickname',
    avatar_url: 'following.avatarUrl',
    name: 'name',
    tags: 'tags',
    location: {
        display_name: 'location.displayName',
        address: {
            road: "location.road",
            house_number: "location.houseNumber",
            city: "location.city",
            state: "location.state",
            postcode: "location.postcode",
            country: "location.country",
        },
    },
    // parameters: 'parameters'
};


const remapFilters = (raw) => {
    return raw.data.filters.edges.map((r) => {
        const mapped = remap(r.node, filter_template);
        mapped.id = graphID_to_int(r.node.id);
        if (!!r.node.parameters) {
            mapped.parameters = r.node.parameters;
        }
        if (r.node.location) {
            const coords = point_to_coords(r.node.location.coords)
            mapped.location.lat = coords.lat;
            mapped.location.lon = coords.lon;
        } else {
            mapped.location = null;
        }
        return mapped
    });
}

export {filter_query, remapFilters}