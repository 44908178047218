const config = {
    apiKey: "AIzaSyAemZay22lTj49EC1elPJFmqOkkuqTpMRU",
    authDomain: "moodiks.firebaseapp.com",
   // databaseURL: "https://moodiks.firebaseio.com",
    projectId: "moodiks",
    storageBucket: "moodiks.appspot.com",
    messagingSenderId: "425781550744",
    appId: "1:425781550744:web:9c491482985b642b09faed"
  };

export default config;