import React, {useRef, useState} from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useIntersectionObserver from "./IntersectionObserver";
import withStyles from "@material-ui/styles/withStyles";

export const styles = (theme) => ({
    root: {
        display: 'inline-block',
        position: 'relative',
        lineHeight: 0, // 🤷🏻‍♂️Fixes masonry item gap
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
})

const noImage = 'https://thumbs.dreamstime.com/z/upset-magnifying-glass-cute-not-found-symbol-unsuccessful-s-upset-magnifying-glass-cute-not-found-symbol-unsuccessful-122205900.jpg'

const LazyImage = React.forwardRef(function LazyImage(props, ref) {
    const {
        src,
        alt,
        className = {},
        classes,
        component: Component = 'div',
        onError,
        ...other
    } = props;


    ref = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useIntersectionObserver({
        target: ref,
        onIntersect: ([{isIntersecting}], observerElement) => {
            if (isIntersecting) {
                setIsVisible(true);
                //This is a hack to stop RecommendationCardSmall from crashing
                if (ref.current) {
                    observerElement.unobserve(ref.current);
                }
            }
        }
    });

    const handleError = (ev) => {
        if (onError)
            onError(ev)
        else {
            console.log('LazyImage: onError');
            ev.target.src = noImage;
        }
    }
    console.log('LazyImage: src: ', src);

    return (
        <Component
            className={clsx(classes.root, className)}
            ref={ref}
        >
            {isVisible && (
                <img
                    src={src}
                    alt={alt}
                    className={classes.image}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noImage
                    }}
                    {...other}
                />
            )}
        </Component>
    );
});

LazyImage.propTypes = {
    src: PropTypes.node,
    alt: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    component: PropTypes.string,
    onError: PropTypes.func,
};
LazyImage.defaultProps = {
    onError: ((i) => (i.target.style.display = 'none'))
}

export default withStyles(styles)(LazyImage);