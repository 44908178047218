import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import AuthUserContext from './context';
import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';


{/* each protected page is wrapped in:
  export default withAuthorization(condition)(ProtectedPage);

to access auth content, the code needs to be enclosed in
  <AuthUserContext.Consumer>
  </AuthUserContext.Consumer>

  the condition needs to be defined on that page as
  const condition = authUser => !!authUser;

  this will push the SIGN_IN page to the router history if the user is not yet authorized
  */
}


const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            console.log('withAuthorization: ')
            this.listener = this.props.firebase.auth.onAuthStateChanged(
                authUser => {
                    console.log('withAuthorization: authUser', authUser)
                    if (!condition(authUser)) {
                        if (!authUser || (authUser && authUser.role) === 'anonymous')
                            this.props.history.push(ROUTES.SIGN_IN);
                    }
                });
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {state =>
                        condition(state.authUser) ? <Component {...this.props} /> : null
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(WithAuthorization);
};
export default withAuthorization;