import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Box from "@material-ui/core/Box";
import {NewlineText} from "./NewLineText";
import theme from "../../Theme";
import clsx from "clsx";

export const styles = {
    root:{
        marginTop: theme.spacing(1),
        marginBottom:0,
        textAlign: 'center'
    }
};

const BioTextBase = React.forwardRef(function BioTextBase(props, ref) {
    const {
        className,
        classes,
        text
    } = props;

    return (
        <Box className={clsx(classes.root, className)}>
            {text &&
            <NewlineText text={text}/>
            }
        </Box>
    )

})
BioTextBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const BioText = withStyles(styles)(BioTextBase);
export {BioText};
