import {useTranslation} from "react-i18next";
import {useField} from "formik";
import {useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import InputLabel from "@material-ui/core/InputLabel";
import MuiTextField, {} from '@material-ui/core/TextField';

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import * as React from "react";
import withStyles from "@material-ui/styles/withStyles";
import MyFormikTextField from "../MyFormikTextField";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";


function MyFormikPasswordField(props) {
    const {
        name,
        onBlur,
        helperText,
        disabled,
        ns,
        label,
        className,
        FormHelperTextProps,
        InputLabelProps,
        fullWidth = false,
        required = false,
        ...other
    } = props;

    const {t} = useTranslation([ns]);
    const [field, meta, helpers] = useField(name);
    const {onBlur: fieldOnBlur} = field;
    const {touched, isSubmitting, error: fieldError} = meta;
    const {value} = field;
    const showError = touched && !!fieldError;

    const new_props = {
        error: showError,
        helperText: showError ? t(fieldError) : helperText,
        disabled: disabled ?? isSubmitting,
        onBlur:
            onBlur ??
            function (e) {
                fieldOnBlur(e ?? field.name);
            },
        ...field,
        ...other,
    }

    const InputMore = {};

    if (InputLabelProps && typeof InputLabelProps.shrink !== 'undefined') {
        InputMore.notched = InputLabelProps.shrink;
    }
    if (label) {
        const displayRequired = InputLabelProps?.required ?? required;
        InputMore.label = (
            <React.Fragment>
                {label}
                {displayRequired && '\u00a0*'}
            </React.Fragment>
        );
    }


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl className={className} fullWidth={fullWidth} variant="outlined" required={required} error={new_props.error}>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput  {...new_props} {...InputMore}
                            fullWidth={fullWidth}
                            required={required}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {value ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
            />
            {helperText && (
                <FormHelperText  {...FormHelperTextProps}>
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
}

MyFormikTextField.displayName = 'MyFormikMaterialUIPasswordField';

export default MyFormikPasswordField;