const image_url = process.env.PUBLIC_URL + "static/";

const images = {
    install: image_url + "install.png",
    add_to_home_page: image_url + "add_to_home.png",
    my_account: image_url + "my_account.png",
    manage_profile: image_url + "manage_profile.png",
    soc_dem: image_url + "soc_dem.png",
    questionnaire: image_url + "questionnaire.png",
    invite_friends: image_url + "invite_friends.png"
}
console.log('Introduction: images: ', images);
export default images;