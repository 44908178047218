import Box from "@material-ui/core/Box";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import MyFormikTextField from "../MyFormikTextField";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import withStyles from "@material-ui/styles/withStyles";
import {useTranslation} from "react-i18next";
import {CommentContext} from "./Comments";
import {AppBar, Snackbar, Toolbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const styles = theme => ({
    grid: {
        flexGrow: 0,
        padding: '0 10px 10px',
        width: 'auto'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        elevation: 0,
    },
    toolbar_root:{
        display: 'block'
    }
});


const emojis = {
    'smile': String.fromCodePoint('0x1F600'),
    'thumbs_up': String.fromCodePoint('0x1F44D'),
    'clap': String.fromCodePoint('0x1F44F'),
    'heart': '\u2764\ufe0f'
}

const Emoji = (props) => {
    const {
        symbol
    } = props;
    return (
        <span role="img">
        {symbol}
    </span>
    )
}

const PostCommentBase = (props) => {
    const {
        classes,
        respondingTo,
        nickname = '',
        handleClose,
        editing = false,
        commentId = null,
    } = props;


    const context = React.useContext(CommentContext)
    const {
        commentsLookup,
        commentTree,
        moodiks,
        recommendationId,
        setCommentTree,
        setCommentsLookup,
        currentUid
    } = context;
    const not_registered_user = !currentUid;
    const [waitingServerResponse, setWaitingServerResponse] = useState(false);
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});


    const {t} = useTranslation(['recommendation']);

    const schema = {
        user_comment: Yup.string().required('Comment is empty').min(1, 'Comment is empty')
    }
    const initial_values = {
        user_comment: ''
    }


    if (editing && commentId) {
        const comment = commentsLookup[commentId];
        initial_values.user_comment = comment.text;
        initial_values.responding_to = comment.comment_id
    } else {
        initial_values.responding_to = respondingTo;
        initial_values.user_comment = ""
    }

    const placeholder = editing
        ? ''
        : nickname
            ? t('respond to') + ' ' + nickname
            : t('take part in the conversation...');
    const button_text = editing
        ? 'change'
        : 'post';

    const handle_emoji = (e, formikProps) => {
        const {values, setFieldValue} = formikProps;
        const new_value = values['user_comment'] + e;
        setFieldValue('user_comment', new_value, false);
    }
    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }


    const handleSubmit = ({callback, commentId}) => (values, actions) => {
        const fix_tree = (c) => {
            const comment = {...c};
            comment.uid = c.uid.toString();
            const parent = commentsLookup[comment.comment_id];
            if (parent)
                parent.responses[comment.id] = comment;
            else {
                commentTree[comment.id] = comment;
                setCommentTree(commentTree);
                actions.setFieldValue('user_comment', '', false);
                debugger;
            }
            commentsLookup[comment.id] = comment;
            setCommentsLookup(commentsLookup)
            if (callback) callback()
        }
        const update_text = (c) => {
            const comment = commentsLookup[c.id];
            comment.text = c.text;
            if (callback) callback()
        }
        setWaitingServerResponse(true);
        setSnackBar({open: true, message: t('saving comment'), severity: 'info'})
        moodiks.doPostComment(recommendationId, values.user_comment, values.responding_to, commentId)
            .then((c) => {
                setWaitingServerResponse(false);
                setSnackBar({open: true, message: t('comment saved'), severity: 'success'})
                if (commentId)
                    update_text(c)
                else
                    fix_tree(c)
            }, (reason) => {
                setWaitingServerResponse(false);
                setSnackBar({open: true, message: t('error saving comment'), severity: 'error'})
            });
    }

    const handleAbort = ({callback, commentId}) => (values) => {
        if (!commentId) {
        }
        if (callback) callback()
    }


    return (
        <AppBar position="sticky" color="default" className={classes.appBar}>
            <Toolbar classes={{root: classes.toolbar_root}}>
                <Box>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={snackBar.open}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                            {snackBar.message}
                        </Alert>
                    </Snackbar>
                    <Formik
                        validationSchema={Yup.object().shape(schema)}
                        initialValues={initial_values}
                        onSubmit={handleSubmit({
                            callback: handleClose,
                            commentId: commentId
                        })}
                    >
                        {(formik_props) => <Form>
                            <Grid container direction="column"
                                  justifyContent="space-evenly"
                                // alignItems="flex-start"
                                  className={classes.grid}
                            >
                                <Grid item>
                                    <MyFormikTextField
                                        name='user_comment'
                                        // label={t('comment')}
                                        placeholder={placeholder}
                                        fullWidth variant='outlined' margin='normal'
                                        multiline
                                        ns='recommendation'
                                    />
                                </Grid>
                                <Grid item container direction='row'>
                                    <Button
                                        type='submit'
                                        variant='contained' color='primary' size='small'
                                        disabled={not_registered_user || waitingServerResponse}
                                    >
                                        {t(button_text)}
                                    </Button>
                                    {respondingTo &&
                                    <Button
                                        style={{marginRight: '40px'}}
                                        onClick={handleAbort({
                                            callback: handleClose,
                                            commentId: commentId
                                        })}
                                        color='secondary'>
                                        {t('cancel')}
                                    </Button>
                                    }
                                    {Object.values(emojis).map((e, i) => (
                                        <Button size='small' style={{maxWidth: '35px', minWidth: '35px'}}
                                                key={i.toString()}
                                                onClick={() => {
                                                    handle_emoji(e, formik_props)
                                                }}
                                        >
                                            <Emoji symbol={e}/>
                                        </Button>
                                    ))}

                                </Grid>
                            </Grid>
                        </Form>}

                    </Formik>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

const PostComment = withStyles(styles)(PostCommentBase);
export {PostComment}