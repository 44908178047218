import {gql} from "../../hooks/gql_helpers";
import {likes, remap} from "../../utils/TransformData";

const get_likes = gql`{
  recommendationsById(id: ${'id'}) {
    edges {
      node {
      likedBy(first: ${'first'}, after: ${'after'}) {
          edges{
             node{
               uid
               nickname
               avatarUrl
             }
          }
          pageInfo {
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
        }
      }
    }
  }
}`


const user_template = {
    nickname: 'nickname',
    avatar_url: 'avatarUrl',
}

const remap_likes = (raw) => {
    if (raw.data.recommendationsById.edges[0].node.likedBy.edges == "undefined") {
        console.error("remap_likes: raw.data.recommendationsById.edges[0].node.likedBy.edges is invalid", raw)
        return []
    }

    return raw.data.recommendationsById.edges[0].node.likedBy.edges.map((r) => {
        const mapped = remap(r.node, user_template);
        mapped.uid = parseInt(r.node.uid);
        return mapped
    })
}

const extractPagination = (raw) => {
    console.log('Like: extractPagination:', raw)
    return raw.data.recommendationsById.edges[0].node.likedBy.pageInfo;
}

const likes_mapper = {'data': remap_likes, 'pagination': extractPagination}
export {get_likes, likes_mapper};