import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Box from "@material-ui/core/Box";
import theme from "../../Theme";
import Grid from "@material-ui/core/Grid";
import {Card} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import RollingRoutes, {Route} from "../RollingRoutes";
import UserPage from "../UserPage";
import TopBar from "../TopBar/TopBar";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitButton from "../ExitButton/ExitButton";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import InfiniteFeed from "../InfiniteFeed";
import CacheMarker from "../CacheMarker";


export const styles = {
    background: {
        backgroundColor: theme.palette.grey["100"],
    },
    scrollable: {
        height: 'calc(100vh - 50px)',
        paddingRight: '10%',
        paddingLeft: '10%'
    },
    card: {
        minHeight: '150px',
        margin: "7px 7px",
        background: theme.palette.background.paper,
        borderStyle: "solid",
        borderRadius: "30px",
        borderWidth: "1px",
        borderColor: "#CCCC",
        boxShadow: "10px 10px 15px  lightgrey",
        backgroundColor: "white",
        position: 'relative'  // need this for absolute positioned children to work
    },
    card_content: {
        textAlign: 'center',
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        marginBottom: theme.spacing(2),
        marginRight: 'auto',
        marginLeft: 'auto'
    }
};

const UserListBase = React.forwardRef(function UserListBase(props, ref) {
    const {
        classes,
        history,
        title,
        query,
        mapper,
        queryParams,
        onClose = null
    } = props;

    const counterInitialState = 1;
    const [counter, setCounter] = useState(counterInitialState);

    const routes = {
        userPage: false
    };
    const exitFun = () => {
        if (onClose) {
            onClose(counter !== counterInitialState)
        } else history.goBack();
    };

    return (
        <CacheMarker
            initialValue={counter}
            markDirty={() => {
                setCounter(c => c + 1)
            }}>
            {(cache) => (
                <RollingRoutes initialState={routes}>
                    {(routeState) => (
                        <div className={classes.background}>

                            <TopBar
                                title={title} endIcon={<AccountCircleOutlinedIcon/>}
                                leftButton={(<ExitButton onExit={exitFun}/>)}
                            />
                            <Grid container position="static"
                                  className={classes.background}>
                                <InfiniteFeed query={query} mapper={mapper}
                                              queryParams={{'counter': counter, ...queryParams}}>
                                    {(user, i, hideShare, props) => (
                                        <Grid item xs={6}>
                                            <Card className={classes.card} raised
                                                  onClick={() => routeState.openRoute('userPage',
                                                      {
                                                          uid: user.uid,
                                                          nickname: user.nickname,
                                                          avatarUrl: user.avatar_url
                                                      })}
                                            >
                                                <CardContent className={classes.card_content}>
                                                    <Avatar className={classes.avatar} src={user.avatar_url}
                                                            alt={user.nickname}/>
                                                    {user.nickname}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    )}
                                </InfiniteFeed>
                            </Grid>
                            <Route dialog='userPage'>
                                <UserPage {...routeState.props}
                                          noActionsChildren
                                          onClose={(dirty) => {
                                              if (dirty) cache.markDirty();
                                              routeState.closeRoute('userPage')
                                          }}/>
                            </Route>
                        </div>

                    )}
                </RollingRoutes>
            )}
        </CacheMarker>
    )

})
UserListBase.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    query: PropTypes.func.isRequired,
    mapper: PropTypes.object.isRequired,
    queryParams: PropTypes.object.isRequired,
    onClose: PropTypes.func
}


const UserList = compose(
    withRouter,
    withStyles(styles)
)(UserListBase);
export default UserList;
