import React, {useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import i18n from "i18next";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import theme from "../../Theme";
import RollingRoutes, {Route} from "../RollingRoutes";
import Grid from "@material-ui/core/Grid";
import InfiniteFeed from "../InfiniteFeed";
import {RecommendationCardSmallRouting} from "../Recommendation/RecommendationCardSmall";
import {get_list, mapper} from './queries'
import {query_string} from "../../hooks/gql_helpers";
import ViewRecommendation from "../ViewRecommendation/ViewRecommendation";
import {TagViewTypeSelector} from "./TagViewTypeSelector";
import {Badge, Snackbar} from "@material-ui/core";
import {useAsync} from "react-async";
import {getMoodiks} from "../Moodiks";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import ButtonBase from "@material-ui/core/ButtonBase";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import {useFilterContext} from "../Feed";
import Alert from "@material-ui/lab/Alert";

export const styles = {
    root: {
        backgroundColor: theme.palette.grey["100"],
    },
    grid: {
        flexGrow: 0,
        padding: '20px 10px 40px',
    },
    headerChip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        borderColor: theme.palette.common.white,
        boxShadow: "10px 10px 15px  lightgrey",
        marginLeft: 'auto',
        marginRight: 'auto',
        textSize: '25px',
    },
    headerChipLabel: {
        fontSize: '25px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '4px 12px',
        whiteSpace: 'nowrap',
    },
    editButton: {
        backgroundColor: theme.palette.primary.main,
    },
    editButtonSize: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    isFollowing: {
        backgroundColor: theme.palette.success.main
    },
    addFollow: {
        backgroundColor: theme.palette.secondary.main
    }
};

const tags_graphql_to_rest = (filter_obj, language) => {
    console.log('CategoryFilter: tags_graphql_to_rest: filter_obj, language :', filter_obj, language);
    const tags = filter_obj.tags.map((tag) => {
        const keyword = tag.keywords.filter((keyword) => keyword.lang === language);
        return {name_id: keyword[0].tagId, text: [keyword[0].text]}
    })
    return {...filter_obj, tags: tags}
}


const TagViewBase = (props) => {
    const {
        filter: filterProp,
        text: textProp,
        firebase,
        classes
    } = props;

    const routes = {
        viewRecommendation: false
    };
    const [isFollowing, setIsFollowing] = useState(null)

    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});


    const [followValid, setFollowValid] = useState(false)
    const [textValid, setTextValid] = useState(false)
    const textRef = useRef('none')
    const {language} = i18n

    const initialQueryParams = {
        keyword: query_string('none'),
        related: query_string('False'),
        lang: query_string(i18n.language)
    }
    const [queryParams, setQueryParams] = useState(initialQueryParams)

    useEffect(() => {
        if (moodiksValid && textValid) {
            moodiks.doGetIsFollowingTag(textRef.current).then((result) => {
                setIsFollowing(result["is_following_tag"]);
                setFollowValid(true)
            })
        }
    }, [moodiks, moodiksValid, textValid])

    useEffect(() => {
        if (!textValid) {
            let keyword;
            if (textProp)
                keyword = textProp;
            else {
                const new_filter = tags_graphql_to_rest(filterProp, language);
                new_filter.tag_names = new_filter.tags.map((tag) => tag.text[0]);
                keyword = new_filter.tag_names[0]
            }
            textRef.current = keyword;
            setQueryParams({
                keyword: query_string(keyword),
                related: query_string('False'),
                lang: query_string(language)
            })
            setTextValid(true);
            return () => {
                console.log('closing TagView: textValid, text:', textValid, textRef.current)
            }
        }
    }, [filterProp, language, textProp, textValid])

    const HeaderChip = (props) => {
        return (
            <Chip className={classes.headerChip}
                  classes={{label: classes.headerChipLabel}}
                  variant='default'
                  {...props}
            />
        )
    }

    const ToggleButton = () => {
        const {toggleFollowTag} = useFilterContext() || {};

        const EditButton = (props) => {
            const {
                className,
                classes,
                icon,
                onClick,
                disabled
            } = props;
            return (
                <ButtonBase onClick={onClick} disabled={disabled}>
                    <Avatar className={clsx(className, classes.editButtonSize)}>
                        {icon}
                    </Avatar>
                </ButtonBase>
            )
        }

        const handle_toggle_follow = async (event, text, new_status) => {

            event.preventDefault();
            setSnackBar({open: true, message: t('saving'), severity: 'info'})
            let result;
            try {
                const data = await moodiks.doSetIsFollowingTag(text, new_status);
                console.log('handle_toggle_follow: data: ', data)
                const {is_following_tag, filter} = data;
                if (toggleFollowTag) {
                    result = toggleFollowTag(filter, new_status)
                    // result = {success: true, message: ''}
                } else {
                    result = {success: true, message: ''}
                }
                setIsFollowing(is_following_tag)
            } catch (e) {
                result = {success: false, message: e}
            }

            console.log('handle_toggle_follow: result: ', result)
            if (result.success)
                setSnackBar({open: true, message: t('success'), severity: 'success'})
            else
                setSnackBar({open: true, message: t('error: ') + result.message, severity: 'error'})
        }

        return (
            <EditButton className={editButtonClass} classes={classes}
                        icon={followIcon}
                        onClick={(e) => handle_toggle_follow(e, textRef.current, !isFollowing)}
            />
        )
    }

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }

    const followIcon = isFollowing
        ? (<CheckSharpIcon/>)
        : (<AddOutlinedIcon/>);

    const editButtonClass = isFollowing
        ? classes.isFollowing
        : classes.addFollow;

    const {t} = useTranslation(['recommendation']);
    return (
        <Box position='static' component='main' className={classes.root}>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={snackBar.open}
                autoHideDuration={1000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            {textValid &&
            <RollingRoutes initialState={routes}>
                {(routeState) => (
                    <React.Fragment>


                        <TagViewTypeSelector
                            queryParams={queryParams}
                            onClick={(qp) => setQueryParams(qp)}
                        />
                        <Grid container direction="column"
                              justifyContent="space-evenly"
                              alignItems="center"
                              className={classes.grid}
                        >
                            {followValid &&
                            <Badge
                                badgeContent={
                                    <ToggleButton/>
                                }
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            >
                                <HeaderChip label={'#' + textRef.current}/>
                            </Badge>
                            }
                        </Grid>
                        <Grid container position="static" component="main">

                            <InfiniteFeed query={get_list} mapper={mapper}
                                          queryParams={queryParams}
                            >

                                {(recommendation, i, hideShare, props) => (
                                    <Grid item xs={6}>
                                        <RecommendationCardSmallRouting
                                            recommendation={recommendation}
                                            routeState={routeState}
                                            keyProp={i.toString()}
                                            {...props}
                                        />
                                    </Grid>
                                )}
                            </InfiniteFeed>
                            <Route dialog='viewRecommendation'>
                                <ViewRecommendation {...routeState.props}  />
                            </Route>
                        </Grid>
                    </React.Fragment>
                )}
            </RollingRoutes>
            }
        </Box>
    )
}


TagViewBase.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.string,
    filter: PropTypes.object
}

const if_authorized = authUser => !!authUser;
const TagView = compose(
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(TagViewBase);


export default TagView;
