import React from "react";
import theme from "../../Theme";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {query_string} from "../../hooks/gql_helpers";


export const styles = {
    gridRoot: {
        backgroundColor: theme.palette.common.white,
        width: '100%',
        marginTop: '12px',
        marginBottom: '12px'
    }
};


const RecommendationTypeSelectorBase = React.forwardRef(function RecommendationTypeSelectorBase(props, ref) {
    const {
        classes,
        onClick,
        withDisabled = false,
        withBookmarks = false
    } = props;

    const [value, setValue] = React.useState(0);

    function a11yProps(index) {
        return {
            id: `scrollable-force-tab-${index}`,
            'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }

    //Tab change handlers
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0 :
                onClick({rtype: query_string('RECOMMENDATION'), active: query_string('True')}, 'RECOMMENDATION');
                break;
            case 1 :
                onClick({rtype: query_string('REQUEST'), active: query_string('True')},'RECOMMENDATION');
                break;
            case 2 :
                onClick({rtype: query_string('RECOMMENDATION'), active: query_string('True')}, 'BOOKMARK');
                break;
            case 3 :
                onClick({rtype: query_string(''), active: query_string('False')}, 'RECOMMENDATION');
                break;
            default:
                onClick({rtype: 'RECOMMENDATION', active: 'True'}, 'RECOMMENDATION');
        }

    };


    return (
        <div className={classes.gridRoot}>
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab icon={<ListAltOutlinedIcon/>} {...a11yProps(0)}/>
                <Tab icon={<LiveHelpOutlinedIcon/>}{...a11yProps(1)}/>
                {withBookmarks &&
                <Tab icon={<BookmarksOutlinedIcon/>}{...a11yProps(2)}/>
                }
                {withDisabled &&
                <Tab icon={<LockOutlinedIcon/>}{...a11yProps(3)}/>
                }
            </Tabs>
        </div>
    )

})
RecommendationTypeSelectorBase.propTypes = {
    classes: PropTypes.object,
    onClick: PropTypes.func,
    withDisabled: PropTypes.bool,
    withBookmarks: PropTypes.bool
}


const RecommendationTypeSelector = withStyles(styles)(RecommendationTypeSelectorBase);
export default RecommendationTypeSelector;
