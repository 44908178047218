import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import withStyles from "@material-ui/styles/withStyles";
import theme from "../../Theme";
import Box from "@material-ui/core/Box";

export const styles = {
    square: {
        borderRadius: "4px",
        minWidth: 20,
        minHeight: 20,
        // flex: '0 0 auto',
        padding: theme.spacing(1),
    },
};

const SquareBase = React.forwardRef(function SquareBase(props, ref) {
    const {
        color,
        size,
        sx,
        borderRadius = "4px",
        other
    } = props;

    return (
        <Box component="div"
             sx={{
                 margin: theme.spacing(1),
                 backgroundColor: color,
                 height: size,
                 width: size,
                 borderRadius: borderRadius,
                 // flex: '0 0 auto',
                 padding: theme.spacing(1),
                 ...sx
             }}
             {...other}
        />
    )
})
SquareBase.propTypes = {
    color: PropTypes.string.isRequired,
}


const Square = withStyles(styles)(SquareBase);
export default Square;
