import {gql, graphID_to_int, point_to_coords, reduce_tags} from "../../hooks/gql_helpers";
import {bookmarks, category_text, likes, remap} from "../../utils/TransformData";

const get_feed = gql`{
  filteredRecommendationsList(categories:${'categories'}, country:${'country'}, state:${'state'},city:${'city'},lat: ${'lat'}, lon: ${'lon'},distance: ${'distance'} first: ${'first'}, after: ${'after'}){
    edges {
      node {
        id
        url
        uid
        userProfile {
          nickname
          avatarUrl
        }
        subject
        type
        experience
        lang
        createdAt
        images {
          alt
          url
        }
        category {
          id
          keywords {
            text
            lang
          }
        }
        location {
          coords
          country
          state
          city
        }
        tags {
          keywords {
            text
            lang
          }
        }
        likedBy{
          totalCount
          edges{
             node{
               uid
               nickname
               avatarUrl
             }
          }
        }
        bookmarkedBy{
          totalCount
          edges{
             node{
               uid
             }
          }
        }
        comments {
          totalCount
          edges {
            node {
              id
            }
          }
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`

const recommendation_template = {
    url: 'url',
    uid: 'uid',
    subject: 'subject',
    // category: 'category.keywords[0].text',
    tags: 'tags',
    type: 'type',
    lang: 'lang',
    created_at: 'createdAt',
    category_id: 'category.id',
    location: {
        address: {
            city: 'location.city',
            state: 'location.state',
            country: 'location.country'
        },
    },
    nickname: 'userProfile.nickname',
    avatar_url: 'userProfile.avatarUrl',
    experience: 'experience',
    num_comments: 'comments.totalCount',
    num_likes: 'likedBy.totalCount',
};

const image_template = {
    src: 'url',
    alt: 'alt'
}


const remapRecommendations = (raw) => {
    debugger;
    return raw.data.filteredRecommendationsList.edges.map((r) => {
        const mapped = remap(r.node, recommendation_template);
        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.id = graphID_to_int(r.node.id);
        mapped.images = r.node.images.map((i) => {
            return remap(i, image_template);
        });
        if (r.node.location) {
            const coords = point_to_coords(r.node.location.coords)
            mapped.location.lat = coords.lat;
            mapped.location.lon = coords.lon;
        } else {
            mapped.location = null;
        }
        mapped.liked_by = likes(r.node.likedBy);
        mapped.bookmarked_by = bookmarks(r.node.bookmarkedBy);
        return mapped
    });
}

export {get_feed, remapRecommendations}