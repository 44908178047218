import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton";

const HelpText = (props) => {
    const {
        recommendationType
    } = props;
    const [visible, setVisible] = useState(false);

    const {t} = useTranslation(['recommendation'])

    const is_recommendation = recommendationType === 'RECOMMENDATION';

    const handleClick = () => {
        setVisible(!visible)
    }
    const recommendation_text = (
        <React.Fragment>
            {visible &&
            <React.Fragment>
                <h4>{t('Category')}</h4>
                <p>{t('help_line2')}</p>
                <h4>{t('subcategory')}</h4>
                <p>{t('help_line3')}</p>
                <h4>{t('tags')}</h4>
                <p>{t('help_line4')}</p>
                <h4>{t('URL')}</h4>
                <p>{t('help_line5')}</p>
                <h4>{t('Location')}</h4>
                <p>{t('help_line6')}</p>
                <h4>{t('Details')}</h4>
                <p>{t('help_line8')}</p>
            </React.Fragment>
            }
        </React.Fragment>
    );
    const request_text = (
        <React.Fragment>
            {visible &&
            <React.Fragment>
                <h5>{t('Category')}</h5>
                <p>{t('help_line9')}</p>
                <h4>{t('subcategory')}</h4>
                <p>{t('help_line10')}</p>
                <h4>{t('tags')}</h4>
                <p>{t('help_line11')}</p>
                <h5>{t('Location')}</h5>
                <p>{t('help_line6')}</p>
            </React.Fragment>
            }
        </React.Fragment>
    );

    const help_text = is_recommendation ? recommendation_text : request_text;

    return (
        <Box>
            <IconButton onClick={handleClick} color="primary">
                <InfoIcon/>
            </IconButton>
            {help_text}
        </Box>
    )
}

export {HelpText}