import React from 'react';
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from '../Firebase';
import {useTranslation} from 'react-i18next';
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as ROUTES from '../../constants/routes';
import Box from "@material-ui/core/Box";
import theme from "../../Theme";
import TopBar from "../TopBar";
import ExitButton from "../ExitButton/ExitButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";


const SignOutButtonBase = ({firebase, history}) => {

    const sign_out = () => {
        firebase.doSignOut()
            .then(() => {
                history.push(ROUTES.SIGN_IN)
            })
    }

    const {t} = useTranslation(['common']);
    return (
        <Button color="primary" variant="contained" size="small" type="button" onClick={sign_out}>
            {t('sign out')}
        </Button>
    );
};

const styles = {
    background: {
        backgroundColor: theme.palette.grey["100"],
        height: '100vh',
        position: 'relative'
    },
    container: {
      position: 'absolute',
      top: '50%'
    }
};

const SignOutPageBase = (props) => {
    const {
        classes,
        history,
    } = props;
    const exitFun = () => {
        history.goBack();
    }

    const {t} = useTranslation(['common']);


    return (
        <Box position='static' component='main' className={classes.background}>
            <TopBar title={t(('sign out'))}
                    startIcon={<ExitToAppIcon/>}
                    leftButton={<ExitButton onExit={exitFun}/>}
            />
            <Grid container justifyContent="center" alignItems="center" className={classes.container}>
                <Grid item>
                    <SignOutButton/>
                </Grid>
            </Grid>
        </Box>
    )

}

const SignOutButton = compose(
    withRouter,
    withFirebase
)(SignOutButtonBase);

const SignOutPage = compose(
    withRouter,
    withStyles(styles)
)(SignOutPageBase)

export default SignOutButton;
export {SignOutPage}