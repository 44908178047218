import {gql, graphID_to_int, reduce_tags} from "../../hooks/gql_helpers";
import {remap} from "../../utils/TransformData";

const query_following = gql`{
    filters(fType: "USER" user: ${'user'}, first: ${'first'}, after: ${'after'}) {
    edges {
      node {
        followUid
        following {
          avatarUrl
          nickname
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`


const user_template = {
    nickname: 'nickname',
    avatar_url: 'avatarUrl',
}

const remap_following = (raw) => {
    if (raw.data.filters.edges == "undefined") {
        console.error("remap_following: raw.data.filters.edges is invalid", raw)
        return []
    }

    return raw.data.filters.edges.map((r) => {
        const mapped = remap(r.node.following, user_template);
        mapped.uid = parseInt(r.node.followUid);
        return mapped
    })
}

const extractPaginationFollowing = (raw) => {
    console.log('Like: extractPagination:', raw)
    return raw.data.filters.pageInfo;
}

const following_mapper = {'data': remap_following, 'pagination': extractPaginationFollowing}


const query_followers = gql`{
    followers(user: ${'user'}, first: ${'first'}, after: ${'after'}) {
    edges {
      node {
        uid
        nickname
        avatarUrl
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`


const remap_followers = (raw) => {
    if (raw.data.followers.edges == "undefined") {
        console.error("remap_followers: raw.data.followers.edges is invalid", raw)
        return []
    }

    return raw.data.followers.edges.map((r) => {
        const mapped = remap(r.node, user_template);
        mapped.uid = parseInt(r.node.uid);
        return mapped
    })
}


const extractPaginationFollowers = (raw) => {
    console.log('Like: extractPagination:', raw)
    return raw.data.followers.pageInfo;
}

const followers_mapper = {'data': remap_followers, 'pagination': extractPaginationFollowers}

const query_tags = gql`{
    filters(fType: "CATEGORY" user: ${'user'}, first: ${'first'}, after: ${'after'}) {
    edges {
      node {
      id
       tags {
          keywords {
            lang
            tagId
            text
          }
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}`

const remap_tag = (raw) => {
    const kw_reducer = (tags, keyword) => {
        console.log('kw_reducer: tags, keyword', tags, keyword);
        tags[keyword.lang] = {
            tag_id: keyword.tagId,
            text: keyword.text
        }
        return tags
    }
    const keywords = raw[0].keywords;

    return !!keywords ? keywords.reduce(kw_reducer, {}) : null;
}

const remap_tags = (raw) => {

    if (raw.data.filters.edges == "undefined") {
        console.error("remap_following: raw.data.filters.edges is invalid", raw)
        return []
    }
    console.log('remap_tags: raw:', raw)

    return raw.data.filters.edges.map((r) => {
        const mapped = {};
        mapped.id = graphID_to_int(r.node.id);
        mapped.tags = remap_tag(r.node.tags);
        return mapped
    })

}

const extractPaginationTags = (raw) => {
    return raw.data.filters.pageInfo;
}
const tags_mapper = {'data': remap_tags, 'pagination': extractPaginationTags}

export {query_following, following_mapper, query_followers, followers_mapper, query_tags, tags_mapper}