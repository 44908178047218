import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../Theme'
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


import logo from '../../static/moodiks_logo_horizontal_49px.png'


import Box from "@material-ui/core/Box";
import clsx from "clsx";
import {compose} from "recompose";


const styles = {
    topBar: {
        flexGrow: 1,
        top: 0,
        minHeight: 50,
        maxHeight: 51,
        backgroundColor: 'white',
        alignContent: 'center',
        justifyContent: 'center',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    leftButton: {
        position: 'absolute',
        left: '1rem',
        top: 0,
        bottom: 0,
        maxWidth: '20%',
        display: 'flex',
        justifyContent: 'center'
    },
    rightButton: {
        position: 'absolute',
        right: '1rem',
        top: 0,
        bottom: 0,
        maxWidth: '20%',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        margin: 'auto',
        maxHeight: 30,
        maxWidth: '60%',
        backgroundColor: 'white',
        verticalAlign: "middle",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        width: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: theme.typography.h3.fontWeight,
        fontSize: theme.typography.h3.fontSize,
        textAlign: 'center',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    },
    logo: {
        margin: 'auto',
        maxHeight: 30,
        backgroundColor: 'white',
        display: 'block'
    },
    startIcon: {
        position: 'relative',
        top: '2px',
        bottom: '2px',
        marginRight: 8
    },
    endIcon: {
        position: 'relative',
        top: '2px',
        bottom: '2px',
        marginLeft: 8
    }
};


const TopBar = (props) => {
    const {
        leftButton,
        title: title_prop,
        startIcon: start_icon_prop,
        endIcon: end_icon_prop,
        rightButton,
        classes,
        className
    } = props;
    const tiny = useMediaQuery('(max-width:347px)');
    const logoStyle = tiny ? {maxWidth: '110px'} : {maxWidth: '180px'};
    const startIcon = start_icon_prop && (
        <span className={classes.startIcon}>{start_icon_prop}</span>
    )
    const endIcon = end_icon_prop && (
        <span className={classes.endIcon}>{end_icon_prop}</span>
    )
    const title = title_prop && (
        <span className={classes.text}>{title_prop}</span>
    )
    //todo last <Toolbar/> is a hack and better solution  to be found
    return (
        <MuiThemeProvider theme={theme}>
            <AppBar color="default" className={clsx(classes.topBar, className)} position="fixed">
                <Toolbar>
                    {title && <span className={classes.title}>
                        {startIcon}
                        {title}
                        {endIcon}
                    </span>}
                    {!title && <img className={classes.logo} style={logoStyle} alt="logo" src={logo}/>}

                    {leftButton && <div className={classes.leftButton}>{leftButton}</div>}
                    {rightButton && <div className={classes.rightButton}>{rightButton}</div>}
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </MuiThemeProvider>
    );
}

TopBar.propTypes = {
    title: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    leftButton: PropTypes.node,
    rightButton: PropTypes.node
};

function TopLineBase(props) {
    const {
        leftButton,
        title,
        rightButton,
        classes
    } = props;

    return (
        <React.Fragment>
            {leftButton && <div className={classes.leftButton}>{leftButton}</div>}

            <Box fontStyle="bold" fontSize="h6.fontSize" className={classes.title}>
                {title}
            </Box>
            {rightButton && <div className={classes.rightButton}>{rightButton}</div>}

        </React.Fragment>
    );
}

const TopLine = compose(withStyles(styles))(TopLineBase)

TopLine.propTypes = {
    leftButton: PropTypes.node,
    title: PropTypes.string,
    rightButton: PropTypes.node
};

export default withStyles(styles)(TopBar);
export {TopLine};