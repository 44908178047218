import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {graphID_to_int, graphID_to_num_str, useLazyQuery} from "../../hooks/gql_helpers";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {AuthUserContext, withAuthentication, withAuthorization} from "../Session";
import {get_recommendation, remapRecommendations} from "./QueryById";
import {RecommendationViewCard} from "../Recommendation/RecommendationViewCard";
import MoodiksClient from "../Moodiks";
import * as ROUTES from "../../constants/routes";
import Box from "@material-ui/core/Box";
import Navigation, {NavigationHideable} from "../Navigation";
import TopBar from "../TopBar";
import Loading from "../Loading";
import Comments from "../Comments"
import CacheMarker, {useCacheMarkerContext} from "../CacheMarker";

const mapper = {'data': remapRecommendations};

export const styles = {
    root: {
        width: '100%',
        paddingBottom: '10px'
    }
};


const ViewRecommendationBase = React.forwardRef(function ViewRecommendationBase(props, ref) {
    const {
        classes,
        match,
        firebase,
        history,
        recId: rec_id_prop = null,
        gotoComments,
        handleCommentsChanged = null,
        canEdit = false
    } = props;

    console.log('ViewRecommendation: props', props);
    const {t} = useTranslation(['recommendation']);
    const [valid, setValid] = useState(false);
    const [images, setImages] = useState(null);
    const [attributeGroup, setAttributeGroup] = useState({});
    const [moodiks, setMoodiks] = useState(null);
    const cacheMarker = useCacheMarkerContext();
    const [counter, setCounter] = useState(1);
    const [{data, loading, error, pagination}, getData] = useLazyQuery(firebase, mapper);
    const {authUser, setInvitedBy} = React.useContext(AuthUserContext);
    const [recIdNumStr, setRecIdNumStr] = useState(null);
    const commentsRef = useRef(null);
    if (valid && gotoComments && commentsRef.current)
        commentsRef.current.scrollIntoView();
    const currentUid = firebase.getUid();

    const getAttributeGroup = (category_object) => {
        const {attribute_group} = category_object;
        return attribute_group;
    };


    useEffect(() => {
        const rec_id = rec_id_prop ? rec_id_prop : match.params.r;
        const invite_id = match.params.id;
        if (rec_id) {
            const is_authorized = !!authUser;
            if (!is_authorized) {
                firebase.doSignInAnonymously().then(r => {
                    firebase.auth.invite_id = invite_id;
                    setInvitedBy(invite_id);
                    console.log('ViewRecommendation: logged in anonymously')
                });
                return;
            }
        }
        setValid(false);
        const id_str = '"' + rec_id.toString() + '"'
        // const id_str = graphID_to_num_str(rec_id);
        setRecIdNumStr(rec_id);
        getData(get_recommendation({id: id_str}))
            .then((data) => get_more(data))
            .catch(
                //    requested recommendation not found
                //    else go somewhere else Landing?
            )

        //    else go somewhere else Landing?
    }, [getData, match.params.id, authUser, counter]);


    const getUserId = useCallback((moodiks) => {
        moodiks.doGetOnboardingTasks().then((data) => {
            const {uid} = data;
            firebase.setUid(uid);
            setValid(true)
        })
    }, [firebase]);

    const getAttributes = useCallback((moodiks, recommendation) => {
        moodiks.doGetAttributes([parseInt(recommendation.category_id)]).then((attrs) => {
            if (attrs.length !== 0)
                setAttributeGroup(getAttributeGroup(attrs[0]));
            if (!!firebase.getUid())
                setValid(true)
            else
                getUserId(moodiks)
        });
    }, [firebase, getUserId]);


    const get_more = useCallback((rec_list) => {
        if (!rec_list || rec_list.length === 0) return;
        const recommendation = rec_list[0];
        debugger;
        setImages([...recommendation.images]);
        if (moodiks) {
            getAttributes(moodiks, recommendation);
        } else {
            firebase.doGetAccessToken(
                (id_token) => {
                    const moodiks = new MoodiksClient(id_token);
                    getAttributes(moodiks, recommendation)
                    setMoodiks(moodiks);
                });
        }
    }, [firebase, getAttributes, moodiks]);


    useEffect(() => {
        get_more(data);
    }, [data, get_more]);


    const recommendation = valid ? data[0] : null;
    const if_registered = authUser && authUser.role === 'regular';
    const comments = valid ? recommendation.comments : null;

    return (
        <Box position='static' component='main' className={classes.root}>
            {!rec_id_prop &&
                < TopBar/>
            }
            {!valid &&
                <Loading/>
            }
            {valid &&
                <CacheMarker
                    initialValue={counter}
                    markDirty={() => {
                        setCounter(c => c + 1); //updates card
                        if(cacheMarker)
                            cacheMarker.markDirty(true) //updates feed
                    }}>
                    {(cache) => (
                        <RecommendationViewCard recommendation={recommendation} images={images}
                                                attributeGroup={attributeGroup}
                                                moodiks={moodiks}
                                                canEdit={canEdit}

                        />
                    )}
                </CacheMarker>
            }
            {if_registered && valid &&
                <Comments
                    ref={commentsRef} comments={comments} moodiks={moodiks} recommendationId={recIdNumStr}
                    currentUid={currentUid} handleCommentsChanged={handleCommentsChanged} gotoComments={gotoComments}
                />
            }

            {!rec_id_prop &&
                <Navigation/>
            }
        </Box>
    )

})
ViewRecommendationBase.propTypes = {
    classes: PropTypes.object.isRequired,
}
const if_authorized = authUser => true;
const if_registered = authUser => authUser && authUser.role === 'regular';


const ViewRecommendation = compose(
    withRouter,
    withFirebase,
    withTranslation(['recommendation']),
    withStyles(styles),
)(ViewRecommendationBase);

export default withAuthorization(if_authorized)(ViewRecommendation);
