import React, {Component} from 'react';


export const moodiksColors = {
    moodiks_green: "#78ff00",
    moodiks_orange: "#FF6B00",
    moodiks_magenta: "#F200FF",
    moodiks_blue: "#61e3ff",
    moodiks_yellow: "#EDFF00",
    moodiks_gray: "#B0ADAE"
};
