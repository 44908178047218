import React, {Component} from 'react';
import {compose} from 'recompose';
import {Link} from 'react-router-dom';
import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import {withTranslation, useTranslation} from 'react-i18next';
import {withStyles} from "@material-ui/core";
import theme from "../../Theme";
import TextField from "@material-ui/core/TextField";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {Snackbar} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";


const styles = {
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        elevation: 0,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: 'primary',
    },
};

const useStyles = makeStyles((theme) => (styles));

const PasswordForgetPage = () => {
    const {t} = useTranslation(['common']);
    const classes = useStyles();


    return (
        <div className={classes.paper}>
            <h1>{t("PasswordForget")}</h1>
            <PasswordForgetForm/>
        </div>
    );
}

const INITIAL_STATE = {
    email: '',
    error: null,
    snackbarOpen: false
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = {...INITIAL_STATE};
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }

    onSubmit = event => {
        const {email} = this.state;
        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({
                    email: '',
                    error: null,
                    snackbarOpen: true
                });
            })
            .catch(error => {
                this.setState({error});
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    closeSnackbar() {
        this.setState({
            snackbarOpen: false
        })
    }

    render() {
        const {email, error, snackbarOpen} = this.state;
        const isInvalid = email === '';
        const {t} = this.props;
        const classes = this.props.classes;

        return (
            <Container container maxWidth="xs" position="static" component="main">
                <CssBaseline/>
                <div width='60%' className={classes.paper}>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={this.closeSnackbar}
                    >
                        <Alert onClose={this.closeSnackbar} severity="success">
                            {t('email was sent')}
                        </Alert>
                    </Snackbar>
                    <form className={classes.form} onSubmit={this.onSubmit}>
                        <TextField
                            name="email"
                            value={this.state.email}
                            onChange={this.onChange}
                            type="text"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            placeholder={t("Email Address")}
                        />
                        <Button disabled={isInvalid}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                            {t("Reset My Password")}
                        </Button>
                        {error && <p>{t(error.message)}</p>}
                    </form>
                </div>
            </Container>
        );
    }
}

const PasswordForgetLink = () => {
    const {t} = useTranslation(['common']);
    const classes = useStyles();

    return (

        <p>
            <Link className={classes.link} to={ROUTES.PASSWORD_FORGET}>{t("Forgot Password")}</Link>
        </p>
    );
};
export default PasswordForgetPage;
const PasswordForgetForm = compose(
    withStyles(styles),
    withFirebase,
    withTranslation(['common'])
)(PasswordForgetFormBase);

export {PasswordForgetForm, PasswordForgetLink};