import React, {useRef, useState} from "react";
import PropTypes from 'prop-types';
import withStyles from "@material-ui/styles/withStyles";
import ImageGallery from "./ImageGallery";
import {useTranslation} from 'react-i18next';
import PublicIcon from '@material-ui/icons/Public';
import clsx from 'clsx';
import theme from "../../Theme"
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import MailIcon from '@material-ui/icons/Mail';
import ReplyIcon from '@material-ui/icons/Reply';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteTwoToneIcon from '@material-ui/icons/FavoriteTwoTone';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import {Badge, Link as MuiLink, Snackbar} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import RecommendationCardHeader from "./RecommendationCardHeader";
import {AvatarButton} from "./MoodiksAvatar";
import IconButton from "@material-ui/core/IconButton";
import {recommendationStyles} from "./recommendationStyles"
import Share from "../Share";
import {TextEllipsesWords} from "./TextEllipsesWords"
import TimeAgo from "timeago-react"
import * as timeago from 'timeago.js'
import de from 'timeago.js/lib/lang/de'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TopBar from "../TopBar";
import ExitButton from "../ExitButton/ExitButton";
import ViewRecommendation from "../ViewRecommendation";
import ResponsePage from "../ResponsePage";
import Recommendation from "./Recommendation";
import ReportProblem from "../ReportProblem"
import UserPage from "../UserPage";
import * as TYPES from '../../constants/recommendation_types';
import CategoryFilter from "../CategoryFilter";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import {RecommendationEdit} from "./RecommendationEdit";
import {useFirebaseContext} from "../Firebase/context";
import Alert from "@material-ui/lab/Alert";
import UserList from "../UserList";
import {look_up} from "../../utils/TransformData";
import {get_likes, likes_mapper} from "./QueryLikes";
import {int_to_num_str} from "../../hooks/gql_helpers";
import {useCacheMarkerContext} from "../CacheMarker";


timeago.register('de', de)


const HighlightText = (props) => {
    const {
        highlight,
        text,
        expandable = true
    } = props;
    const [ignore, setIgnore] = React.useState(false);
    const {t} = useTranslation(['recommendation']);
    const handleClick = () => {
        setIgnore(true);
    }

    return (
        <Box fontSize="body2.fontSize">
            {expandable &&
                <React.Fragment>
            <span style={{fontWeight: 700}}>
                   {t(highlight)} {": "}
                </span>
                    <span>
                <TextEllipsesWords
                    text={text}
                    component='span'
                    ignore={ignore}
                    maxWords={4}
                    renderEllipses={(params) => (
                        <MuiLink {...params} onClick={handleClick} component='button'
                                 variant='body2'>{t('more')}</MuiLink>)}
                />
                </span>
                </React.Fragment>
            }
            {!expandable &&
                <div>
                    <h4 style={{margin: 0}}>{t(highlight)}</h4>
                    <p>{text}</p>
                </div>
            }
        </Box>

    );
}


const ShowUrl = (props) => {
    const {
        url,
        open = false,
        component: Component = 'div'
    } = props;

    const [visible, setVisible] = React.useState(false);
    const handleClick = () => {
        setVisible(true);
    }

    return (
        <React.Fragment>
            {!open &&
                <Component>
                    <MuiLink onClick={handleClick}>url: </MuiLink>
                    {visible && <a href={url} target='_blank'>{url}</a>}
                </Component>
            }
            {open &&
                <Component>
                    {'url: '} <a href={url} target='_blank'>{url}</a>
                </Component>
            }
        </React.Fragment>
    )
}

const ExitToApp = (props) => {
    const {
        url
    } = props;

    return (
        <span>
            <IconButton href={url} target='_blank' color="primary">
                <PublicIcon/>
            </IconButton>

    </span>
    )
}


export const styles = {
    header_root: {
        display: 'flex',
        alignItems: 'right',
        margin: 16,
    },
    gallery: {
        height: 'auto'
    },
    avatar: {
        flex: '0 0 auto',
        alignSelf: 'flex-end',
    },
    root: {
        margin: "7px 14px",
        background: theme.palette.background.paper,
        borderStyle: "solid",
        borderRadius: "30px",
        borderWidth: "1px",
        borderColor: "#CCCC",
        boxShadow: "10px 10px 15px  lightgrey",
        backgroundColor: "white"
    },
    actions: {
        paddingTop: 0,
        paddingBottom: 0
    },
    like: {
        color: theme.palette.secondary.main
    },
    action_button_right_edge: {
        marginLeft: 'auto',
        marginRight: 0
    },
    action_button_text: {
        textDecoration: 'underline',
        textTransform: 'none',
        paddingLeft: 0
    },
    edit_button: {
        borderRadius: "50px",
        textTransform: 'none',
        fontSize: '10px'
    },
    button_root: {
        minWidth: "130px",
        borderRadius: "50px",
        backgroundColor: recommendationStyles.REQUEST.borderColor,
    },
    button_root_left: {
        borderRadius: "50px",
        backgroundColor: recommendationStyles.REQUEST.borderColor,
        // marginLeft: '-13px',
    },
    button_root_right: {
        borderRadius: "50px",
        backgroundColor: recommendationStyles.REQUEST.borderColor,

        // marginRight: '-13px',
    },
    button_label: {
        color: theme.palette.common.white,
        textTransform: 'none',
        fontSize: '10px'
    },
    button_icon: {
        color: theme.palette.common.white,
        transform: "scale(1.0)"
    },
    RECOMMENDATION: recommendationStyles.RECOMMENDATION,
    REQUEST: recommendationStyles.REQUEST
};


const RecommendationCardBase = React.forwardRef(function RecommendationCard(props, ref) {
    const {
        recommendation,
        images = [],
        classes,
        hideShare = false,
        showAvatar = false,
        noActions = false,
        noActionsChildren = false,
        canRespond = false,
        canEdit = false,
        subjectClickable: subject_clickable_prop = true,
        onClose = null,
        moodiks = null,
        children,           //attribute fields, tags & location are shown here
    } = props;


    const {
        id = false,
        subject,
        category: category_prop,
        type = 'RECOMMENDATION',
        location,
        url,
        created_at,
        uid = null,
        experience = '',
        nickname,
        avatar_url = '',
    } = recommendation;

    const cacheMarker = useCacheMarkerContext();

    const liked_by_param = recommendation.liked_by ? recommendation.liked_by : [];
    const bookmarked_by_param = recommendation.bookmarked_by ? recommendation.bookmarked_by : [];

    console.log('RecommendationCard: liked_by_param, recommendation: ', liked_by_param, recommendation)
    let {num_comments} = recommendation;
    const [likedBy, setLikedBy] = useState(liked_by_param);
    const num_likes = likedBy.length;
    const [bookmarkedBy, setBookmarkedBy] = useState(bookmarked_by_param);

    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});


    const {t, i18n} = useTranslation(['recommendation']);
    const {language} = i18n;
    const firebase = useFirebaseContext()
    const user_uid = firebase.getUid();
    const liked_by_user = likedBy.some((l) => l.uid === user_uid);
    console.log('RecommendationCard: id, user_uid, liked_by_user, liked_by', id, user_uid, liked_by_user, likedBy)

    const bookmarked_by_user = bookmarkedBy.some((l) => l.uid === user_uid);

    const city = look_up(location, 'address.city');
    const country = look_up(location, 'address.country');
    const category = category_prop ? category_prop : '';
    const subheader = country
        ? city
            ? city + ', ' + country
            : country
        : '';
    // const subjectClickable = (type === 'RECOMMENDATION' && subject_c0lickable_prop) || canRespond;
    const subjectClickable = subject_clickable_prop;

    console.log('RecommendationCard: images: ', images);
    // const shareText = subject + "\nShared by: " + nickname +  "\nExperience:\n" + experience +"\n\n";
    const shareText = `${subject} 
    
${subheader}
${t("shared by")} : ${nickname} 
    
${t("experience")}:
${experience}  
    
${t("See it in MOODIKS")}
    
    `;
    //end of share_text

    const avatar_prop = showAvatar
        ? (props) => (
            <AvatarButton avatarUrl={avatar_url} nickname={nickname} userId={uid}
                          onClick={() => handleOpen('userPage')} {...props}/>
        )
        : null;

    const isRecommendation = type === 'RECOMMENDATION';
    const isRequest = type === 'REQUEST';
    const has_id = id !== false;
    const showLink = !!url;
    const showShare = !hideShare && isRecommendation;
    const showRespond = (isRequest && has_id && !canEdit && !noActions);
    const showComments = isRecommendation && subjectClickable;
    const showLikes = isRecommendation;
    const showBookmarks = isRecommendation;
    // const showCreateFilter = showComments && !showRespond && !noActionsChildren;
    const highlight_text = isRecommendation ? 'experience' : 'detail';
    const showUrl = showLink && !subjectClickable;
    const action_color = recommendationStyles[type].borderColor;
    const topLineText = category;

    const [open, setOpen] = useState({
        recommendation: false,
        comments: false,
        responses: false,
        reply: false,
        problem: false,
        userPage: false,
        userList: false,
        categoryFilter: false,
        edit: false
    });


    const handleCommentsChanged = (new_num) => {
        num_comments = new_num;
    }
    const handleOpen = (dialog) => {
        setOpen({...open, [dialog]: true});
    }
    const handleClose = (dialog) => {
        setOpen({...open, [dialog]: false});
        if (onClose)
            onClose()
    }

    const ActionButton = (props) => {
        const {
            icon = null,
            text,
            dialog,
            disabled = false,
            onClick
        } = props;
        const no_text = text === undefined || text === '0';
        const is_icon_button = !!icon;

        const clickHandler = !!dialog
            ? () => handleOpen(dialog)
            : () => onClick();

        return (
            <React.Fragment>
                {is_icon_button &&
                    <IconButton onClick={clickHandler} disabled={disabled}>
                        <Badge color="secondary" badgeContent={text} invisible={no_text}>
                            {icon}
                        </Badge>
                    </IconButton>
                }
                {!is_icon_button && !no_text &&
                    <Button onClick={clickHandler} disabled={disabled || no_text}
                            className={classes.action_button_text}>
                        {text}
                    </Button>

                }
            </React.Fragment>
        )
    }

    const LinkText = (props) => {
        const {
            text: text_prop,
            dialog,
            onClick,
            ...other
        } = props;
        const clickHandler = !!dialog
            ? () => handleOpen(dialog)
            : () => onClick();
        const text = (typeof (text_prop) == "string") ? text_prop : text_prop.join(' ');

        return (
            <Button onClick={clickHandler} className={classes.action_button_text} {...other}>
                {text}
            </Button>
        )
    }

    const ActionButtonLarge = (props) => {
        const {
            icon,
            label,
            dialog,
            position,
        } = props;
        const isLeft = position === "left";
        const isRight = position === "right";
        const isNeutral = !isLeft & !isRight;

        console.log('RecommendationCard: ActionButtonLarge: props: ', props)
        return (
            <Button
                variant="contained"
                color="inherit"
                disableElevation
                startIcon={icon}
                onClick={() => handleOpen(dialog)}
                classes={{
                    root: clsx({
                        [classes.button_root]: isNeutral,
                        [classes.button_root_left]: isLeft,
                        [classes.button_root_right]: isRight
                    }),
                    label: classes.button_label,
                    startIcon: classes.button_icon
                }}
            >
                {label}
            </Button>
        )
    }

    ActionButtonLarge.propTypes = {
        icon: PropTypes.element.isRequired,
        label: PropTypes.string.isRequired,
        dialog: PropTypes.string.isRequired,
        position: PropTypes.string
    };

    const Item = (props) => {
        const {sx, ...other} = props;
        return (
            <Box sx={{
                p: 0,
                m: 1,
                borderRadius: 1,
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: '700',
                ...sx,
            }}
                 {...other}
            />
        )
    }

    const RollingRoute = (props) => {
        const {
            dialog,
            children,
            noTopBar = false,
        } = props;
        const element_open = open[dialog];
        if (typeof (element_open) === 'undefined')
            console.error('RollingRoute: failed dialog: ', dialog);

        return (
            <Dialog fullScreen open={element_open}>
                {element_open &&
                    <React.Fragment>
                        {!noTopBar &&
                            <TopBar
                                leftButton={
                                    <ExitButton
                                        onExit={() => {
                                            handleClose(dialog)
                                        }}
                                    />}
                            />}
                        {children}
                    </React.Fragment>
                }
            </Dialog>
        );
    }

    RollingRoute.propTypes = {
        dialog: PropTypes.string.isRequired,
        noTopBar: PropTypes.bool
    }

    const do_like = () => {
        if (!liked_by_user)
            setSnackBar({open: true, message: t('liking recommendation'), severity: 'info'});
        moodiks.doSetLike(id, !liked_by_user).then((like) => {
            console.log('RecommendationCard: do_like: like: ', like)
            if (like === true)
                setLikedBy(old => [...old, {uid: user_uid, nickname: 'you'}])
            else
                setLikedBy(old => old.filter((l) => l.uid !== user_uid))
        })
    }

    const do_bookmark = () => {
        if (!bookmarked_by_user)
            setSnackBar({open: true, message: t('bookmarking recommendation'), severity: 'info'});
        moodiks.doSetBookmark(id, !bookmarked_by_user).then((bookmark) => {
            console.log('RecommendationCard: do_bookmark: bookmark: ', bookmark)
            if (bookmark === true)
                setBookmarkedBy(old => [...old, {uid: user_uid}])
            else
                setBookmarkedBy(old => old.filter((l) => l.uid !== user_uid))
            handleRecommendationClose(true)
        })
    }

    const filter_from_recommendation = (recommendation) => {
        console.log('RecommendationCard: filter_from_recommendation: recommendation: ', recommendation);
        //use current i18n language, as any other text was removed in mapping
        const filter = {
            id: undefined,
            f_type: 'CATEGORY',
            follow_uid: undefined,
            nickname: undefined,
            avatar_url: undefined,
            name: recommendation.category.text,
            distance: "2000",
            tags: [
                {
                    keywords: [
                        {
                            lang: language,
                            tagId: recommendation.category.id,
                            text: [recommendation.category.text]
                        }
                    ]
                }
            ],

        }
        if (!!recommendation.location) {
            filter.location = {...recommendation.location}
        }
        return filter
    }

    const EditButton = () => {
        return (
            <Button
                className={classes.edit_button}
                variant="contained"
                color="primary"
                size="small"
                endIcon={<EditIcon/>}
                onClick={() => {
                    handleOpen('edit')
                }}
            >
                {t('edit')}
            </Button>
        )
    }

    const handleRecommendationClose = (has_changed) => {
        cacheMarker.markDirty(has_changed);
        console.log('RecommendationCard:handleRecommendationClose: marked dirty')
    }

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }


    const time_ago = (<TimeAgo datetime={created_at} locale={language}/>);

    const bottom_left = canEdit
        ? <EditButton/>
        : <div/>;

    const bottom_right = time_ago;

    const subject_click_handler = isRecommendation
        ? () => handleOpen('recommendation')
        : () => handleOpen('responses');

    const favorite_icon = liked_by_user
        ? <FavoriteTwoToneIcon className={classes.like}/>
        : <FavoriteBorderIcon/>;

    const bookmark_icon = bookmarked_by_user
        ? <BookmarkIcon/>
        : <BookmarkBorderIcon/>


    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                open={snackBar.open}
                autoHideDuration={2000}
                onClose={closeSnackbar}
            >
                <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
            <Card className={classes.root} raised>
                <RecommendationCardHeader
                    classes={{avatar: classes.avatar}}
                    avatar={avatar_prop}
                    title={subject}
                    titleBottom={isRequest}
                    subheader={subheader}
                    recommendationId={id}
                    topLine={topLineText}
                    actionColor={action_color}
                    noActions={noActions}
                    subjectClickable={subjectClickable}
                    handleOpen={subject_click_handler}
                />
                <ImageGallery classes={{root: classes.gallery}} images={images}/>
                {canRespond &&
                    <CardContent>{children}</CardContent>
                }

                {!noActions &&
                    <CardActions disableSpacing style={{color: action_color, paddingTop: '0px', paddingBottom: '0px'}}>
                        {showLikes &&
                            <React.Fragment>
                                <ActionButton icon={favorite_icon} onClick={do_like}
                                              disabled={uid === user_uid}/>
                            </React.Fragment>
                        }
                        {showComments &&
                            <ActionButton icon={<ChatBubbleOutlineIcon/>} dialog='comments'/>
                        }
                        {showLink &&
                            <ExitToApp url={url}/>
                        }
                        {showShare &&
                            <Share title='my title' text={shareText} url={url} files={images} id={id}/>
                        }
                        {!canEdit &&
                            <ActionButton icon={<ReportProblemOutlinedIcon/>} dialog='problem'/>
                        }
                        {showBookmarks &&
                            <ActionButton className={classes.action_button_right_edge} icon={bookmark_icon}
                                          onClick={do_bookmark}/>
                        }
                    </CardActions>
                }

                <CardContent style={{paddingTop: '0px'}}>
                    {(showLikes && num_likes > 0) &&
                        <React.Fragment>
                            <LinkText text={[t('liked by'), num_likes.toString(), t('person', {count: num_likes})]}
                                      dialog={'userList'}/>
                            <br/>
                        </React.Fragment>
                    }
                    {(showComments && num_comments) > 0 &&
                        <LinkText text={[t('comment link1'), num_comments.toString(), t('comment link2')]}
                                  dialog={'comments'}/>
                    }
                    <HighlightText highlight={highlight_text} text={experience} expandable={subjectClickable}/>

                    {!canRespond &&
                        children}

                    {showLink &&
                        <ShowUrl url={url} open={showUrl}/>
                    }
                    {(showRespond || canRespond) &&
                        <Grid container direction='row' alignItems='center'
                              justifyContent={showRespond ? 'space-between' : 'center'}
                              style={{marginTop: '4px', marginBottom: '4px'}}
                        >
                            <Grid item>
                                {(showRespond || canRespond) &&
                                    <ActionButtonLarge label={t('reply')} icon={<ReplyIcon/>} dialog='reply'/>
                                }
                            </Grid>
                            {showRespond &&
                                <Grid item>
                                    <ActionButtonLarge label={t('Responses')} icon={<MailIcon/>} dialog='responses'/>
                                </Grid>
                            }
                        </Grid>
                    }
                    <Grid container direction='row' alignItems='center' justifyContent='space-between'
                          style={{marginTop: '4px'}}
                    >
                        <Grid item>{bottom_left}</Grid>
                        <Grid item>{bottom_right}</Grid>
                    </Grid>
                </CardContent>
            </Card>


            <RollingRoute dialog={'userList'}>
                <UserList
                    title={t('liked by')}
                    query={get_likes}
                    mapper={likes_mapper}
                    queryParams={{id: int_to_num_str(id)}}
                    onClose={() => {
                        handleClose('userList')
                    }}
                />

            </RollingRoute>
            <RollingRoute dialog='userPage' noTopBar>
                <UserPage uid={uid} nickname={nickname} avatarUrl={avatar_url} moodiks={moodiks}
                          noActions={noActionsChildren}
                          noActionsChildren
                          onClose={() => {
                              handleClose('userPage')
                          }}/>
            </RollingRoute>

            <RollingRoute dialog='problem'>
                <ReportProblem id={id} handleClose={() => {
                    handleClose('problem')
                }}/>
            </RollingRoute>
            <RollingRoute dialog='reply' noTopBar>
                <Recommendation id={id}
                                type={TYPES.RESPONSE}
                                handleClose={() => {
                                    handleClose()
                                    handleClose('reply')
                                }}/>
            </RollingRoute>
            <RollingRoute dialog='responses' noTopBar>
                <ResponsePage id={id} onClose={() => {
                    handleClose('responses')
                }}
                />
            </RollingRoute>
            <RollingRoute dialog='recommendation'>
                <ViewRecommendation recId={id} handleCommentsChanged={handleCommentsChanged}/>
            </RollingRoute>
            <RollingRoute dialog='comments'>
                <ViewRecommendation recId={id} gotoComments handleCommentsChanged={handleCommentsChanged}/>
            </RollingRoute>

            {!noActions && !showRespond &&
                <RollingRoute dialog='categoryFilter'>
                    <CategoryFilter filter={filter_from_recommendation(recommendation)}
                                    noActionsChildren
                                    handleClose={() => {
                                        handleClose('categoryFilter')
                                    }}
                    />
                </RollingRoute>
            }
            {canEdit &&
                <RollingRoute dialog='edit' noTopBar>
                    <RecommendationEdit recommendation={recommendation}
                                        type={recommendation.type}
                                        handleClose={(changed) => {
                                            handleRecommendationClose(changed)
                                            handleClose('edit')
                                        }}
                    />
                </RollingRoute>}
        </React.Fragment>
    )
});


RecommendationCardBase.propTypes =
    {
        classes: PropTypes.object.isRequired,
        recommendation: PropTypes.object.isRequired,
        images: PropTypes.array
    }

const RecommendationCard = withStyles(styles)(RecommendationCardBase);
export {RecommendationCard};