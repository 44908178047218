import React, { useEffect, useRef, useState} from 'react';
import { useTranslation} from 'react-i18next';
import theme from '../../Theme'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import {SignUpLink} from '../SignUp';
import {PasswordForgetLink} from "../PasswordForget";

import {withFirebase} from '../Firebase';
import * as ROUTES from '../../constants/routes';
import moodiks_logo from "../../static/moodiks_logo.jpg"
import {withStyles} from "@material-ui/core";
import styled from "styled-components";
import {useAuthUserContext} from "../Session/context";
import MyFormikTextField from "../MyFormikTextField";
import MyFormikPasswordField from "../MyFormikPasswordField";
import {Form, Formik} from "formik";
import * as Yup from "yup";


const styles = {
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        elevation: 0,
    },
    logo_box: {},
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    grow: {
        flexGrow: 1,
    },

};

const useStyles = makeStyles((theme) => (styles));

const StyledLink = styled(Link)`
text-decoration: none;
    &:visited{
        text-decoration: none;
        color: blue;
    }
`;

const SignInPage = () => (
    <div>
        <SignInForm/>
    </div>
);



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="primary" href="https://moodiks.com/" >
                MOODIKS, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const MoodiksInfoLink=()=>{
    return(
        <Link color="primary" href="https://moodiks.info">
            moodiks.info
        </Link>
    )
}

const SignInFormBase = (props) => {
    const {
        history,
        firebase,
        classes
    } = props;

    const {invitedBy} = useAuthUserContext();
    const formRef = useRef();
    const [error,setError] = useState('');

    const signInSchema = {
        email: Yup.string().required(),
        password: Yup.string().required()
    }
    const signInValues = {
        email: '',
        password: ''
    }

    useEffect(()=>{
        if (invitedBy)
            history.push(ROUTES.SIGN_UP)
    },[history, invitedBy]);
    
    
    const onSubmit = (values) => {
        const {email, password} = values;
        firebase.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                history.push(ROUTES.FEED);
            })
            .catch(error => {
                setError({error});
            });
    };


    const {t} = useTranslation(['common']);

    const textDefaults = {
        className: classes.input_field,
        variant: "outlined",
        margin: "normal",
        required: true,
        fullWidth: true,
        ns: 'common'
    };

    return (
        <Container container maxWidth="xs" position="static" component="main" justify="space-between" flexGrow={0}>
            <CssBaseline/>
            <div className={classes.paper}>
                <div className={classes.logo_box}>
                    <img src={moodiks_logo} alt='logo'/>
                </div>
                <Formik
                    innerRef={formRef}
                    initialValues={signInValues}
                    validationSchema={Yup.object().shape(signInSchema)}
                    validateOnMount={true}
                    validateOnBlu={true}
                    isInitialValid={false}
                    onSubmit={onSubmit}
                >
                    {(formikProps) => (
                        <Form className={classes.form}>
                            <MyFormikTextField name="email" label={t("Email Address")}
                                               autoComplete="email"
                                               autoFocus {...textDefaults}/>
                            <MyFormikPasswordField name="password" label={t("Password")} autoComplete="password"
                                                   type="password" {...textDefaults}/>

                            <Button disabled={!formikProps.isValid}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                            >
                                {t('sign in')}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <SignUpLink/>
                                    <PasswordForgetLink/>
                                    <MoodiksInfoLink/>
                                </Grid>
                                {error && <p>{error.message}</p>}
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </div>
        </Container>
    );
}



const SignInLink = () => {
    const {t} = useTranslation(['common']);
    const classes = useStyles();

    return (
        <p>
            <StyledLink className={classes.link}
                        to={ROUTES.SIGN_IN}>{t('Already have an account - Sign me in')}</StyledLink>
        </p>
    );
};


const SignInForm = compose(
    withStyles(styles),
    withRouter,
    withFirebase,
)(SignInFormBase);


export default SignInPage;
export {SignInForm, SignInLink};
