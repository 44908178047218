//all other parameters of a filter are stored as a JSON string in the parameters field of the filter
// stringify_parameters packs the [distance] parameter into the parameters field and removes distance from the filter object
//parse_parameters works on a list of filters and unpacks all objects in the parameters field and adds them to the filter, removing the parameters field


const stringify_parameters = (values) => {
    //to add more:
    // const {distance,other ...new_filter} = values;
    // const parameter_obj = {distance, other}
    const {distance, ...new_filter} = values;
    const parameter_obj = {distance}
    new_filter.parameters = JSON.stringify(parameter_obj);
    console.log('stringify_parameters: new_filter: ', new_filter)
    return new_filter
}

const parse_parameters = (filter) => {
    const {parameters, ...new_filter} = filter;
    console.log('parse_parameters : parameters, new_filter: ', parameters, new_filter)
    if (!!parameters) {
        const params_objs = JSON.parse(parameters);
        console.log('parse_parameters : !!parameters: return', {...new_filter, ...params_objs})
        return {...new_filter, ...params_objs}
    }
    return {...new_filter}
}

const parse_filter_list = (filter_list) => {
    if (!!filter_list)
        return filter_list.map((filter) =>
            parse_parameters(filter)
        )
    else return null
}

export {stringify_parameters}
export {parse_filter_list}
export{parse_parameters}