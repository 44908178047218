import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Box from "@material-ui/core/Box";

export const styles = {};
const TextEllipsesBase = React.forwardRef(function TextEllipsesBase(props, ref) {
    const {
        width,
        sx, ...other
    } = props;

    return (
        <Box
            sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: width,
                whiteSpace: 'nowrap',
                ...sx
            }}
            {...other}
        />
    );
})
TextEllipsesBase.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}


const TextEllipses = withStyles(styles)(TextEllipsesBase);
export {TextEllipses};
