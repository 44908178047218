import React from "react";
import PropTypes from "prop-types";

/*
 How to use
 CacheMarker has two props:
    initialValue : some initial state value of the cache marker usually a Boolean or an Int counter
    markDirty : a function that will mark the cache as dirty
    Children: a function using the cache context as a parameter

 Note:
    the component need to check the state itself
    calls to markDirty can include a Boolean parameter to only do the marking if true
    the markDirty function in the CacheMarker prop has no parameters

 Example:
  <CacheMarker
      initialValue={statCacheDirty}
      markDirty={()=>{setStatCacheDirty(true)}}
   >
       {(statCache) => (
               <Grid className={classes.stats} item>
                   <UserStats stats={userStats} uid={uid} moodiks={moodiks}
                              markDirty={(dirty) => statCache.markDirty(dirty)}
                    />
                </Grid>
        )}
    </CacheMarker>
*/

const isFunction = (obj) => typeof obj === 'function';


const CacheMarkerContext = React.createContext(null);
const CacheMarkerProvider = CacheMarkerContext.Provider;

const useCacheMarkerContext = () => {
    const context = React.useContext(CacheMarkerContext);
    if (context === undefined)
        throw new Error('attempt to use CacheMarker context outside definition')
    return context
}

const stateReducer = (state, msg) => {
    switch (msg.type) {
        case 'DIRTY':
            return state;
        default:
            return state;
    }
}

const useCacheMarker = (props) => {
    const {
        value: initialStateProp,
        markDirty
    } = props;

    const initialState = React.useRef(initialStateProp)

    const [state, dispatch] = React.useReducer(stateReducer,
        {
            value: initialStateProp,
            markDirty: markDirty
        });

    const callMarkDirty = (dirty = true) => {
        debugger;
        if (dirty) state.markDirty();
        dispatch({type: 'markDirty', payload: {}});
    }

    const ctx = {
        value: state.value,
        markDirty: callMarkDirty,
    };
    return ctx
}


const CacheMarker = React.forwardRef(function CacheMarkerBase(props, ref) {
    const {
        initialValue,
        markDirty,
        children
    } = props;

    const state = useCacheMarker({'initialValue': initialValue, 'markDirty': markDirty})
    return (
        <CacheMarkerProvider value={state}>
            {isFunction(children) &&
                children(state)
            }
        </CacheMarkerProvider>
    )

})
CacheMarker.propTypes = {}


export default CacheMarker;
export {useCacheMarker, useCacheMarkerContext}
