import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "@material-ui/core/IconButton";

const HelpText = () => {
    const [visible, setVisible] = useState(false);

    const {t} = useTranslation(['recommendation'])

    const handleClick = () => {
        setVisible(!visible)
    }

    const category_text = (
        <React.Fragment>
            <span>{t('CategoryHelp')}</span>
            {visible &&
            <React.Fragment>
                <h4>{t('Category Filter')}</h4>
                <p>{t('CategoryFilterHelp')}</p>
            </React.Fragment>
            }
        </React.Fragment>
    );


    return (
        <Paper elevation={3}>
            <IconButton onClick={handleClick} color="primary">
                <InfoIcon/>
            </IconButton>
            {category_text}
        </Paper>
    )
}

export {HelpText}