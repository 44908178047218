import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";

export const styles = {
    root: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        position: 'relative',
        justifyContent: 'center'
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    banner: {
        height: '90px',
        gridColumn: '1/span 12',
        gridRow: 1,
    },
    children: {
        paddingTop: '20%',
        gridColumn: '7',
        gridRow: 1,
        zIndex: 1,

    }
};

function useLoaded({src, srcSet}) {
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        if (!src && !srcSet) {
            return undefined;
        }

        setLoaded(false);

        let active = true;
        const image = new Image();
        image.src = src;
        image.srcSet = srcSet;
        image.onload = () => {
            if (!active) {
                return;
            }
            setLoaded('loaded');
        };
        image.onerror = () => {
            if (!active) {
                return;
            }
            setLoaded('error');
        };

        return () => {
            active = false;
        };
    }, [src, srcSet]);

    return loaded;
}

const default_banner = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/110238/texture-waves2.jpeg';

// noinspection JSValidateTypes
const BannerBase = React.forwardRef(function BannerBase(props, ref) {
    const {
        classes,
        src=default_banner,
        // src='https://cdn.pixabay.com/photo/2017/09/12/11/56/universe-2742113_960_720.jpg',
        srcSet,
        children
    } = props;
    let banner_img = null;
    const loaded = useLoaded({src, srcSet});
    const hasImg = src || srcSet;
    const hasImgNotFailing = hasImg && loaded !== 'error';

    if (hasImgNotFailing) {
        banner_img = (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
                src={src}
                className={classes.img}
                alt='no image'
            />
        )
    } else {
        banner_img = (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
                src={default_banner}
                className={classes.img}
                alt='no image'
            />
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.children}>
                {children}
            </div>
            <div className={classes.banner}>
                {banner_img}
            </div>
        </div>
    )

})
BannerBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const Banner = withStyles(styles)(BannerBase);
export {Banner};
