//uploadFile does not check file type
async function uploadFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file);
    })
}

async function uploadEventHandler(event) {
    const files = event.target.files;
    const result = {};
    for (let i = 0; i < files.length; i++) {
        const img = {};
        const file = files[i];
        img['src'] = await uploadFile(file);
        img['file'] = file;
        result[file.name] = img;
    }
    return result;
}

function images_obj_to_array(image_obj) {
    let result = [];
    for (let fname in image_obj) {
        const new_image = {
            src: image_obj[fname].src,
            file: image_obj[fname].file,
            alt: fname
        }
        result.push(new_image)
    }
    return result;
}

const orientation_to_angle = (orientation) => {
    const ORIENTATION_TO_ANGLE = {
        '3': 180,
        '6': 90,
        '8': -90,
    }
    return ORIENTATION_TO_ANGLE[orientation]
}

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

//  from https://stackoverflow.com/questions/16702966/rotate-image-and-crop-out-black-borders/16778797#16778797
// see also https://stackoverflow.com/questions/5789239/calculate-largest-rectangle-in-a-rotated-rectangle
const maxCropRotated = (rect, angle) => {
    const angle_rad = getRadianAngle(angle);
    const {width, height} = rect;

    if (width === 0 || height === 0)
        return {width: 0, height: 0}

    const width_is_longer = width > height;
    const side_long = width_is_longer ? width : height;
    const side_short = width_is_longer ? height : width;

    const sin_a = Math.abs(Math.sin(angle_rad))
    const cos_a = Math.abs(Math.cos(angle_rad))

    if (side_short <= 2 * sin_a * cos_a * side_long || Math.abs(sin_a - cos_a) < 1e-10) {
        const x = 0.5 * side_short;
        const wr = width_is_longer ? x / sin_a : x / cos_a;
        const hr = width_is_longer ? x / cos_a : x / sin_a;
        return {width: wr, height: hr}
    } else {
        const cos_2a = cos_a * cos_a - sin_a * sin_a;
        const wr = (width * cos_a - height * sin_a) / cos_2a;
        const hr = (height * cos_a - width * sin_a) / cos_2a;
        return {width: wr, height: hr}
    }
}

const rect = (width, height) => {
    return {width, height}
}

const rectMultScalar = (rect, scalar) => {
    return {
        width: Math.round(rect.width * scalar),
        height: Math.round(rect.height * scalar),
        x: Math.round(rect.x * scalar),
        y: Math.round(rect.y * scalar)
    }
}


const changeHeightWidth = (currentSize, maxSize, minSize) => {
    const {height: currenHeight, width: currentWidth} = currentSize;
    const {height: maxHeight, width: maxWidth} = maxSize;
    const {height: minHeight, width: minWidth} = minSize || {};

    let height = currenHeight, width = currentWidth;
    console.log('changeHeightWidth:currentHeight, currentWidth. ', currenHeight, currentWidth);
    console.log('changeHeightWidth:maxHeight, maxWidth. ', maxHeight, maxWidth);
    console.log('changeHeightWidth:minHeight, minWidth. ', minHeight, minWidth);

    debugger;
    if (maxWidth && currentWidth > maxWidth) {
        height = Math.round((currenHeight * maxWidth) / currentWidth);
        width = maxWidth;
    }
    if (maxHeight && height > maxHeight) {
        width = Math.round((currentWidth * maxHeight) / currenHeight);
        height = maxHeight;
    }
    if (minWidth && currentWidth < minWidth) {
        height = Math.round((currenHeight * minWidth) / currentWidth);
        width = minWidth;
    }
    if (minHeight && currentWidth < minHeight) {
        width = Math.round((currentWidth * minHeight) / currenHeight);
        height = minHeight;
    }
    return {width, height};
}


/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 * @param {number} ratio_prop - optional aspect ratio parameter
 */
// async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
//     const image = await createImage(imageSrc)
//     const canvas = document.createElement('canvas')
//     const ctx = canvas.getContext('2d')
//
//     const maxSize = Math.max(image.width, image.height)
//     const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
//
//     // set each dimensions to double largest dimension to allow for a safe area for the
//     // image to rotate in without being clipped by canvas context
//     canvas.width = safeArea
//     canvas.height = safeArea
//     console.log('ImageUpload: getCroppedImg: maxSize, safeArea: ', maxSize, safeArea, safeArea*safeArea)
//
//     // translate canvas context to a central location on image to allow rotating around the center.
//     ctx.translate(safeArea / 2, safeArea / 2)
//     ctx.rotate(getRadianAngle(rotation))
//     ctx.translate(-safeArea / 2, -safeArea / 2)
//
//     // draw rotated image and store data.
//     ctx.drawImage(
//         image,
//         safeArea / 2 - image.width * 0.5,
//         safeArea / 2 - image.height * 0.5
//     )
//     const data = ctx.getImageData(0, 0, safeArea, safeArea)
//
//     // set canvas width to final desired crop size - this will clear existing context
//     canvas.width = pixelCrop.width
//     canvas.height = pixelCrop.height
//
//     // paste generated rotate image with correct offsets for x,y crop values.
//     ctx.putImageData(
//         data,
//         Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
//         Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
//     )
//
//     // As Base64 string
//     // return canvas.toDataURL('image/jpeg');
//
//     // As a blob
//     return new Promise((resolve) => {
//         canvas.toBlob((file) => {
//             resolve(URL.createObjectURL(file))
//         }, 'image/jpg')
//     })
// }


async function getCroppedImg(imageSrc, fileName, pixelCrop, rotation = 0, ratio_prop) {
    const ratio = ratio_prop ? ratio_prop : 4 / 3;
    const max_width = 480;
    const max_height = max_width / ratio;
    const min_width = 320;
    const min_height = min_width / ratio;
    const image = await createImage(imageSrc)

    const targetSize = rect(max_width, max_height);

    //Only resize to 960p in canvas0
    const canvas0 = document.createElement('canvas')
    const workingSize = changeHeightWidth(image, rect(2 * max_width, 2 * max_height), rect(min_width, min_height))
    canvas0.width = workingSize.width
    canvas0.height = workingSize.height
    const ctx0 = canvas0.getContext('2d')
    ctx0.drawImage(
        image,
        0, 0, image.width, image.height,
        0, 0, workingSize.width, workingSize.height
    );
    console.log('getCroppedImg:image.width,height: ', image.width, image.height);
    console.log('getCroppedImg:workingSize.width,height: ', workingSize.width, workingSize.height);

    //rotate, crop and resize to target in canvas1
    const workingCrop = rectMultScalar(pixelCrop, workingSize.width / image.width);

    const canvas1 = document.createElement('canvas')
    const ctx1 = canvas1.getContext('2d')
    const safeArea = 2 * Math.max(workingSize.width, workingSize.height)
    console.log('getCroppedImg:safeArea: ', safeArea);
    canvas1.width = safeArea
    canvas1.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx1.translate(safeArea / 2, safeArea / 2)
    ctx1.rotate(getRadianAngle(rotation))
    ctx1.translate(-safeArea / 2, -safeArea / 2)

    ctx1.drawImage(
        canvas0,
        (safeArea - workingSize.width) / 2,
        (safeArea - workingSize.height) / 2,
    )

    const croppedImage = ctx1.getImageData(0, 0, safeArea, safeArea)

    canvas1.width = workingCrop.width
    canvas1.height = workingCrop.height
    ctx1.putImageData(
        croppedImage,
        Math.round(0 - safeArea / 2 + workingSize.width * 0.5 - workingCrop.x),
        Math.round(0 - safeArea / 2 + workingSize.height * 0.5 - workingCrop.y)
    )


    //only resize to final size in canvas2
    const canvas2 = document.createElement('canvas')
    canvas2.width = targetSize.width
    canvas2.height = targetSize.height
    const ctx2 = canvas2.getContext('2d')
    ctx2.drawImage(
        canvas1,
        0, 0, workingCrop.width, workingCrop.height,
        0, 0, targetSize.width, targetSize.height
    );

    // As a blob
    return new Promise((resolve) => {
        canvas2.toBlob((blob) => {
            const image_obj = {}
            image_obj[fileName] = {
                src: URL.createObjectURL(blob),
                file: new File([blob], fileName, {type: 'image.jpg'})
            }
            resolve(image_obj)
        }, 'image/jpg')
    })
}


export {uploadEventHandler, images_obj_to_array, getCroppedImg}