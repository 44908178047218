import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {useField} from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export const styles = {};

const MyFormikRadioGroupBase = React.forwardRef(function MyFormikRadioGroupBase(props, ref) {
    const {
        name,
        options,
        onChange,
        label,
        ns
    } = props;


    const {t} = useTranslation([ns]);
    const [field, meta, helpers] = useField(name);
    const {value} = field;
    const {setValue} = helpers;

    const changeHandler = (event) => {
        setValue(event.target.value);
        if (onChange)
            onChange(event.target.value)
    }


    return (
        <FormControl row component='fieldset'>
            <FormLabel component='legend'>{label}</FormLabel>
            <RadioGroup name={name} value={value}
                        onChange={(event) => {
                            changeHandler(event)
                        }}
            >
                {options.map((option) => (
                    <FormControlLabel value={option} control={<Radio/>}
                                      label={t(option)}/>
                ))}

            </RadioGroup>
        </FormControl>
    )
})
MyFormikRadioGroupBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const MyFormikRadioGroup = withStyles(styles)(MyFormikRadioGroupBase);
export default MyFormikRadioGroup;
