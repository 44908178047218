import React from "react";
import {compose} from 'recompose';
import {withTranslation, useTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import theme from '../../Theme'
import {ImageList, ImageListItem, ListItemText} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {LongText} from '../TextPop' ;
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {withFirebase} from "../Firebase";
import Navigation from "../Navigation";
import TopBar from "../TopBar";
import textBackground from "../../static/faces_grey.jpg"
import tileData from "./landing_tiles.json"
import images from "./landing_images";
import LazyImage from "../LazyImage";
import {useAuthUserContext} from "../Session/context";
import ButtonBase from "@material-ui/core/ButtonBase";
import {SignUpLink} from "../SignUp";

const styles = {
    root: {
        display: 'flex',
        margin: 0,
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flex: '1 1 auto',
        margin: 0,
        width: '100vw',
        height: 'auto',
    },
    card: {
        display: 'flex',
        flex: '1 1 auto',
        height: "50vw",
        width: "50vw",
        backgroundColor: '#6c707b',
        alignItems: "center",
        justifyContent: 'center',
        padding: 0,
        margin: 0,
        raised: false,
    },
    grid_text: {
        textAlign: "center",
        // color: '#f0f4ff',
        padding: 0,
        margin: 0,
    },
    text: {
        width: '80%',
        margin: 'auto',
    },
    image: {
        width: '100%',
        height: 'auto',
        flex: 1,
        objectFit: 'contain',
    },
    paper: {
        width: '100%',
        margin: 'auto',
        backgroundImage: `url(${textBackground})`,
        objectFit: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    title: {
        fontSize: 14,
        padding: 0,
    },
    signup_button: {
        margin: theme.spacing(3, 0, 2),
        position: 'center',
        borderRadius: 50,
    },
    about_link: {
        margin: theme.spacing(2, 0, 6)
    },
};


const useStyles = makeStyles((theme) => (styles));

const SignIn = () => {
    const {t} = useTranslation(['common']);
    const classes = useStyles();
    const {invitedBy} = useAuthUserContext();

    console.log('Landing: SignIn: invitedBy :', invitedBy);

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item>
                {invitedBy &&
                <Link to={ROUTES.SIGN_UP_REDIRECT}>
                    <Button
                        type="submit"
                        // fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.signup_button}
                    >
                        {t('common:sign up')}</Button>
                </Link>}
            </Grid>
            <Grid item>
                {!invitedBy &&
                <Link to={ROUTES.SIGN_IN}>
                    <Button
                        type="submit"
                        // fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.signup_button}
                    >
                        {t('common:sign in')}</Button>
                </Link>}
            </Grid>
        </Grid>
    )
}

const OverviewLink = () => {
    const {t} = useTranslation(['common']);
    const classes = useStyles();

    return (
        <ButtonBase
            className={classes.about_link}
            href="https://moodiks.info"
            target='_blank'
        >
            {t('About MOODIKS')}
        </ButtonBase>
    )
}


const SignUpButton = () => {
    const {t} = useTranslation(['common'])
    return (
        <Grid item container direction="column" alignItems="center">
            <Grid item>
                <div>
                    <Link to={ROUTES.SIGN_UP_REDIRECT}>
                        <h4>{t('common:sign up')}</h4>
                    </Link>
                </div>
            </Grid>
        </Grid>
    )
}


function ImageGrid() {
    const classes = useStyles();
    const {t} = useTranslation(['landing']);

    return (
        <>
            <ImageList gap={0} className={classes.gridList} cols={2} rows={4}>

                {tileData.map((tile, id) => (
                    <ImageListItem id={id} key={id} cols={1} rows={1} style={{height: 'auto'}}>
                        {{
                            'text':
                                <Card square className={classes.card}
                                      style={{background: tile.background}}>
                                    <CardContent>
                                        <Typography className={classes.grid_text} variant="h2"
                                                    color={tile.color}
                                        >
                                            {t(tile.text, tile.text)}
                                        </Typography>
                                    </CardContent>
                                </Card>,
                            'image':
                                <Card square className={classes.card}>
                                    <LazyImage src={images[tile.image]} className={classes.image}
                                               alt={tile.image}/>
                                </Card>
                        }[tile.type] || <div/>}
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    );
}

const LandingPageBase = (props) => {
    const {
        classes,
        t
    } = props;

    const text = LongText(t('landing:MOODIKS is'));
    const {authUser} = useAuthUserContext();
    const show_sign_in = !authUser || (!!authUser && authUser.role === 'anonymous')

    return (
        <Grid container direction="column" position="static" component="main" className={classes.root}>
            {authUser && <TopBar/>}
            <ImageGrid/>
            <ListItemText>
                <div className={classes.paper}>
                    <div className={classes.text}>
                        {text}
                    </div>
                </div>
            </ListItemText>
            {show_sign_in && <React.Fragment> <SignIn/> </React.Fragment>}
            {authUser && <React.Fragment> <OverviewLink/><Navigation/> </React.Fragment>}
        </Grid>
    )
}

const LandingPageBaseStyled = compose(
    withStyles(styles),
)(LandingPageBase);

const LandingPage = compose(
    withRouter,
    withFirebase,
    withTranslation(['common', 'landing'])
)(LandingPageBaseStyled);


export default LandingPage;

