import React, {useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {useHistory, withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import Grid from "@material-ui/core/Grid";
import TopBar from "../TopBar";
import Button from "@material-ui/core/Button";
import {Form, Formik} from "formik";
import RollingInput from "../Recommendation/RollingInput";
import {useAsync} from "react-async";
import {getMoodiks} from "../Moodiks";
import * as yup from 'yup';
import theme from "../../Theme";
import MyFormikTextField from "../MyFormikTextField";
import InfiniteFeed from "../InfiniteFeed";
import ChipFactory from "../ChipFactory";
import {get_feed, remapRecommendations} from "./Queries";
import {HelpText} from "./HelpText";
import SearchAutocomplete from "../Search";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ExitButton from "../ExitButton/ExitButton";
import {moodiksColors} from "../Styles/palette";
import {useFilterContext} from "../Feed";
import {RecommendationCard} from "../Recommendation";
import MyFormikRadioGroup from "../MyFormikRadioGroup";
import {parse_parameters, stringify_parameters} from "./parameters";
import {query_from_values} from "./utils";
import {TagAutoComplete} from "../Recommendation/TagAutoComplete";

var fastDeepEqual = require('fast-deep-equal');

const distance_options = [
    'country', 'state', 'city', '500', '2000', '5000', '20000', '50000'
]
export const styles = {
    root: {
        overflowX: 'hidden'
    },
    form: {
        '& > *': {
            padding: theme.spacing(1),
        },
    },
    error: {
        paddingLeft: theme.spacing(3),
        paddingTop: 0,
        marginTop: 0
    },
    chip_field: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    main_chip: {
        margin: theme.spacing(0.5),
        textOverflow: "ellipsis",
        width: '15rem',
        marginBottom: theme.spacing(5)
    },
    grid: {
        flexGrow: 0,
        padding: '3px 4px',
        backgroundColor: theme.palette.grey[100]
    },
    input_field: {
        backgroundColor: 'white',
        borderWidth: "1px",
        borderColor: "#CCCC",
        borderRadius: '15px',
        '& fieldset': {
            borderRadius: '15px'
        },
        paddingBottom: 20
    },
};


const extractPagination = (raw) => {
    return raw.data.filteredRecommendationsList.pageInfo;
}
const mapper = {
    'data': remapRecommendations,
    'pagination': extractPagination,
    'prefix': 'data.filteredRecommendationsList.edges'
}

const tags_graphql_to_rest = (filter_obj, language) => {
    console.log('CategoryFilter: tags_graphql_to_rest: filter_obj, language :', filter_obj, language);
    const tags = filter_obj.tags.map((tag) => {
        const keyword = tag.keywords.filter((keyword) => keyword.lang === language);
        return {name_id: keyword[0].tagId, text: [keyword[0].text]}
    })
    return {...filter_obj, tags: tags}
}

const tags_rest_to_graphql = (filter_obj, language) => {
    const tags = filter_obj.tags.map((tag) => {
        return {keywords: [{lang: language, tagId: tag.name_id, text: tag.text[0]}]}
    })
    return {...filter_obj, tags: tags}
}

const convert_from_rest = (filter_obj, language) => {
    return parse_parameters(tags_rest_to_graphql(filter_obj, language));
}

const empty_filter = {
    f_type: 'CATEGORY',
    tag_names: [],
    tags:[],
    name: '',
    location: null,
    distance: "2000"
};

const filterSchema = yup.object().shape({
    f_type: yup.string(),
    tag_names: yup.array().of(yup.string()),
    tags: yup.array().of(yup.object()),
    name: yup.string().required('your filter needs a name to be saved'),
    location: yup.object().nullable(),
    distance: yup.string().nullable()
});

const CategoryFilterBase = (props) => {
    const {
        filter: filter_prop,
        classes,
        firebase,
        handleChangeFilter,
        onClose,
        ...other
    } = props;


    const {t, i18n} = useTranslation(['recommendation']);
    const {language} = i18n

    const has_initial_filter = !!filter_prop;
    const unsaved = has_initial_filter && filter_prop.id === undefined;
    const history = useHistory();
    const formRef = useRef();
    const filterPropRef = useRef(filter_prop)
    const [filter, setFilter] = useState(empty_filter);
    const [valid, setValid] = useState(false);  //valid to display
    const [showFeed, setShowFeed] = useState(false);  //valid to display
    const [showLocation, setShowLocation] = useState(false);  //valid to display
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});
    const [initialAddress, setInitialAddress] = useState('');
    const [showForm, setShowForm] = useState(!has_initial_filter || unsaved);
    const filterContext = useFilterContext();
    const BlueChip = ChipFactory(moodiksColors.moodiks_blue);


    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;

    if (!fastDeepEqual(filterPropRef.current, filter_prop)) {
        filterPropRef.current = filter_prop
    }


    const DisplayFormikState = props =>
        <div style={{margin: '1rem 0'}}>
            <h3 style={{fontFamily: 'monospace'}}/>
            <pre
                style={{
                    background: '#f6f8fa',
                    fontSize: '.65rem',
                    padding: '.5rem',
                }}
            >
      <strong>props</strong> ={' '}
                {JSON.stringify(props, null, 2)}
    </pre>
        </div>;

    const ErrorFields = (props) => {
        const {formikProps} = props;

        return (
            <React.Fragment>
                {Object.keys(formikProps.errors).map((field) => (
                    <div>{field},{formikProps.errors[field]}</div>
                ))}
            </React.Fragment>
        )
    };


    useEffect(() => {
        if (moodiksValid) {
            setValid(true);
            if (has_initial_filter) {
                debugger;
                if (!!filterPropRef.current.location) {
                    setShowLocation(true)
                }
                const new_filter = tags_graphql_to_rest(filterPropRef.current, language);
                new_filter.tag_names=new_filter.tags.map((tag)=>tag.text[0]);
                console.log('CategoryFilter: useEffect: setFilter()', new_filter)
                setFilter(new_filter);
                setTimeout(() => setShowFeed(true), 10);
                console.log('CategoryFilter: useEffect: setFilter()')
            }
            console.log('CategoryFilter: useEffect')
        }
    }, [has_initial_filter, moodiksValid, filterPropRef.current, language]);


    const updateTagIDs=(wordlist, formikProps)=>{
        const { setFieldValue} = formikProps;
        moodiks.doGetTagIds(wordlist).then((data)=>{
            const {tags} =data;
            setFieldValue('tags', tags);
            setShowFeed(tags.length!==0);
        })
    }

    const handleLocation = (event, location, formik_props) => {
        const {address} = location;
        if (!address) return;
        console.log('location is:', location);
        setTimeout(() => setShowFeed(true), 10)
    }

    const exitFun = () => {
        if (onClose)
            onClose();
        else
            history.goBack()
    }

    const onSubmit = async (values, formikBag) => {
        const {initialValues} = formikBag;
        const isDirty = !fastDeepEqual(initialValues, values)
        if (!isDirty) {
            setSnackBar({open: true, message: t('no changes to save'), severity: "info"});
            return;
        }
        setSnackBar({open: true, message: t('saving filter'), severity: "info"});
        const new_filter = stringify_parameters(values);
        console.log('CategoryFilter: onSubmit: new_filter: : ', new_filter);
        try {
            const saved_filter = await moodiks.doPostFilter(new_filter);
            const saved_filter_converted = convert_from_rest(saved_filter, language);
            console.log('CategoryFilter: saved_filter: ', saved_filter_converted);
            setSnackBar({open: true, message: t('saved !'), severity: "success"});
            if (handleChangeFilter)
                handleChangeFilter(saved_filter_converted)
            else if (!!filterContext && unsaved) {
                filterContext.addFilter(saved_filter_converted)
            }
            exitFun()
        } catch (err) {
            setSnackBar({open: true, message: t("error saving"), severity: "error"});
        }
    }

    const closeSnackbar = () => {
        setSnackBar({open: false, message: '', severity: 'success'});
    }

    const textDefaults = {
        className: classes.input_field,
        variant: "outlined",
        margin: "normal",
        required: true,
        fullWidth: true,
        ns: 'recommendation'
    };

    const handleEdit = (formikProps) => {
        const {validateForm} = formikProps;
        validateForm();
        setShowForm(true);
    }

    const handleRadio = (event, setFieldValue, name) => {
        setTimeout(() => setShowFeed(true), 10);
    }

    const radioDisplay = (value) => {
        return t('Search in') + ': ' + t(value)
    }


    const RightButton = (props) => {
        const {
            showForm: show_form,
            submitDisabled,
            formikProps
        } = props;
        const text = show_form ? t('save') : t('change');
        const disabled = show_form && submitDisabled;
        const buttonType = show_form ? "submit" : 'button';

        return (
            <Button
                edge="end"
                type={buttonType}
                size="small"
                variant="outlined"
                disabled={disabled}
                color='primary'
                {...(!show_form && {
                    onClick: () => {
                        handleEdit(formikProps)
                    }
                })}
            >
                {text}
            </Button>
        )
    }


    return (
        <div >
        {/*<div className={classes.root}>*/}
            < Grid container direction="column"
                   justifyContent="space-evenly"
                   className={classes.grid}
            >
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={snackBar.open}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                >
                    <Alert onClose={closeSnackbar} severity={snackBar.severity}>
                        {snackBar.message}
                    </Alert>
                </Snackbar>
                {valid &&
                <Formik
                    innerRef={formRef}
                    initialValues={filter}
                    validationSchema={filterSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values, bag) => onSubmit(values, bag)}
                >
                    {(formikProps) => (
                        <React.Fragment>
                            <Form className={classes.form} autoComplete="off">
                                <TopBar title={t('filter')}
                                        leftButton={
                                            <ExitButton onExit={exitFun}/>
                                        }
                                        rightButton={
                                            <RightButton
                                                showForm={showForm}
                                                submitDisabled={!formikProps.isValid}
                                                formikProps={formikProps}
                                            />
                                        }
                                />

                                {showForm && formikProps.values.tags.length !== 0 &&
                                <MyFormikTextField name='name'
                                                   label={t('filter name')} {...textDefaults}
                                />
                                }
                                {(showForm && moodiksValid) &&
                                <Grid item>
                                    <RollingInput name='tag_names' label={t('tags')}
                                                  isRolling={false}
                                                  component={TagAutoComplete}
                                                  className={classes.input_field}
                                                  params={{
                                                      moodiks: moodiks,
                                                      max_values: 20,
                                                      helper_text: "subject_helper",
                                                      variant: 'outlined',
                                                      fullWidth: true,
                                                      margin: 'normal',
                                                  }}
                                                  onChange={(wordlist)=>{updateTagIDs(wordlist, formikProps)}}
                                    />
                                </Grid>

                                }
                                {showForm && showLocation &&
                                <Grid item>
                                    <SearchAutocomplete name='location' {...textDefaults}
                                                        label={t('near')}
                                                        initialInput={initialAddress}
                                                        onChange={(event, location) => handleLocation(event, location, formikProps)}
                                    />
                                </Grid>
                                }
                                {showForm && showLocation && !!formikProps.values.location &&
                                <Grid item container direction='row' justifyContent="space-between"
                                      alignItems="center">
                                    <Grid item>
                                        <RollingInput
                                            name='distance'
                                            label={t('Search in')}
                                            replaceLabel
                                            displayFun={radioDisplay}
                                            component={MyFormikRadioGroup}
                                            options={distance_options}
                                            ns='recommendation'
                                            onChange={handleRadio}
                                        />
                                    </Grid>
                                </Grid>
                                }
                                {(showForm && formikProps.values.tags.length === 0) &&
                                <Grid item>
                                    <HelpText/>
                                </Grid>
                                }

                                {/*<DisplayFormikState {...formikProps} unsaved={unsaved} />*/}
                                {/*<ErrorFields formikProps={formikProps}/>*/}

                            </Form>

                            {!showForm &&
                            <Grid container justifyContent='center' alignItems='center'>
                                <Grid item>
                                    <BlueChip label={formikProps.values.name} className={classes.main_chip}
                                              color='default'/>
                                </Grid>
                            </Grid>
                            }


                            <InfiniteFeed
                                query={get_feed} mapper={mapper} showAvatar={true}
                                queryParams={query_from_values(formikProps.values)}
                                suppressQuery={!showFeed}
                            >
                                {(recommendation, i, hideShare, props) => (
                                    <Grid item>
                                        <RecommendationCard
                                            className={classes.card}
                                            recommendation={recommendation}
                                            images={recommendation.images}
                                            key={i.toString()}
                                            hideShare={hideShare}
                                            {...props}
                                            {...other}
                                        />
                                    </Grid>
                                )}
                            </InfiniteFeed>
                        </React.Fragment>
                    )}
                </Formik>
                }
            </Grid>
        </div>
    );
}

CategoryFilterBase.propTypes = {
    classes: PropTypes.object.isRequired,
}

const if_authorized = authUser => !!authUser;
const CategoryFilter = compose(
    withRouter,
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(CategoryFilterBase);


export default CategoryFilter;
