import React, {useEffect, useRef, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import {compose} from "recompose";
import {useHistory} from "react-router-dom";
import {withFirebase} from "../Firebase";
import {withAuthorization} from "../Session";
import Grid from "@material-ui/core/Grid";
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {Form, Formik} from "formik";
import * as yup from 'yup';
import TopBar from "../TopBar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {useAsync} from "react-async";
import {getMoodiks} from "../Moodiks";
import {graphID_to_int} from "../../hooks/gql_helpers";
import MyFormikTextField from "../MyFormikTextField";
import MenuItem from "@material-ui/core/MenuItem";
import theme from "../../Theme";


export const styles = {
    form: {
        margin: theme.spacing(1),
        width: '90%'
    },
    field: {
        margin: theme.spacing(2),
        width: '100%'
    }
};

const empty_report = (id_prop) => {
    return {
        id: id_prop,
        reason: '',
        description: ''
    }
};

const reason_options = [
    {id: 1, text: 'copyright violation'},
    {id: 2, text: 'not positive'},
    {id: 3, text: 'inappropriate'},
    {id: 4, text: 'wrong category'},
    {id: 5, text: 'undeclared affiliation'},
];

const report_schema = yup.object().shape({
    id: yup.number().required(),
    description: yup.string().required().min(50, 'field too short').max(1000, 'field too long'),
    reason: yup.string().required()
});


const ReportProblemProtectedBase = (props) => {
    const {
        classes,
        firebase,
        match,
        id: id_prop,
        handleClose
    } = props;


    const formRef = useRef();
    const history = useHistory();

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const {data: moodiks, error: moodiksError, isPending: moodiksIsPending} = useAsync({
        promiseFn: getMoodiks,
        firebase
    });
    const moodiksValid = !!moodiks && !moodiksIsPending;

    const [report, setReport] = useState('');
    const [id, setId] = useState(null);
    const [valid, setValid] = useState(false);


    useEffect(() => {
        const id = id_prop ? id_prop : match.params.id;

        debugger;
        if (id_prop) {
            const id_num = graphID_to_int(id_prop);
            setId(id_num);
            setReport(empty_report(id_num));
            setValid(true)
        } else {
            onReset();
        }
    }, []);


    const closeSnackbar = () => {
        setSnackBarOpen(false);
    }

    const onSubmit = async (report) => {
        setSnackBarOpen(true);
        debugger;
        await moodiks.doPostProblem(report)
            .catch((e) => {
                console.log(e);
            })
        onReset();
    }

    const onReset = (report) => {
        if (handleClose)
            handleClose()
        else
            history.goBack();
    }

    const {t} = useTranslation(['common']);
    const header = "problem";


    return (
        <React.Fragment>
            <Typography component='div'>
                < Grid container direction="column"
                       justify="space-evenly"
                       alignItems="center"
                       className={classes.grid}
                >
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={snackBarOpen}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                    >
                        <Alert onClose={closeSnackbar} severity="success">
                            {t('problem reported')}
                        </Alert>
                    </Snackbar>
                    {valid &&
                    <Formik
                        innerRef={formRef}
                        initialValues={report}
                        validationSchema={report_schema}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={(values, bag) => onSubmit(values, bag)}
                        onReset={(values, bag) => onReset(values, bag)}
                    >
                        {(formikProps) => (
                            <React.Fragment>
                                <Form className={classes.form} autoComplete="off">
                                    <TopBar title={t(header)}
                                            leftButton={
                                                <Button
                                                    edge="start"
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={formikProps.resetForm}
                                                    color='secondary'
                                                >
                                                    {t('cancel')}
                                                </Button>
                                            }
                                            rightButton={
                                                <Button
                                                    edge="end"
                                                    type="submit"
                                                    size="small"
                                                    variant="outlined"
                                                    disabled={!formikProps.isValid}
                                                    color='primary'
                                                >
                                                    {t('report')}
                                                </Button>
                                            }
                                    />

                                    <Grid item>
                                        <MyFormikTextField className={classes.field}
                                                           name='reason' label={t('reason')}
                                                           select variant='outlined'
                                                           ns="common">
                                            {reason_options.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>{t(option.text)}</MenuItem>
                                            ))}
                                        </MyFormikTextField>
                                    </Grid>

                                    <Grid item>
                                        <MyFormikTextField className={classes.field}
                                                           name={'description'} label={t('description')}
                                                           multiline rows={6} variant='outlined' fullwidth
                                                           margin='normal'
                                                           ns='common'/>

                                    </Grid>
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                    }
                </Grid>
            </Typography>
        </React.Fragment>
    )
}


ReportProblemProtectedBase.propTypes = {
    classes: PropTypes.object.isRequired,
}


const if_authorized = authUser => authUser && authUser.role === 'regular';
const ReportProblemProtected = compose(
    withFirebase,
    withTranslation(['common']),
    withStyles(styles),
    withAuthorization(if_authorized)
)(ReportProblemProtectedBase);


export default ReportProblemProtected;
