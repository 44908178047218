import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import ShareIcon from '@material-ui/icons/Share';
import IconButton from "@material-ui/core/IconButton";
import * as ROUTES from "../../constants/routes";
import {useAuthUserContext} from "../Session/context";

export const styles = {};


const ShareBase = React.forwardRef(function ShareBase(props, ref) {
    const {
        files: fileProp = [],
        text = '',
        title = '',
        url = '',
        id = '',
        classes,
        ...other
    } = props;

    const {inviteId} = useAuthUserContext();

    const srcToFile = async (src, fileName, mimeType) => {
        return (fetch(src)
                .then(async function (res) {
                    return await res.arrayBuffer();
                })
                .then(async function (buf) {
                    const f = await new File([buf], fileName, {type: mimeType});
                    console.log(f)
                    return f;
                })
        );
    }

    const makeUrl = (id) => {
        const link = ROUTES.VIEW_RECOMMENDATION_BASE + inviteId + '/' + id;
        return link;
    }


    //  Leaving this out until file sharing is resolved
    //
    // const handleClick = async () => {
    //     // eslint-disable-next-line array-callback-return
    //
    //     const files = await Promise.all(fileProp.map(async (image, i) => {
    //         return await srcToFile(image.src, i.toString() + '.jpg', 'image/jpeg')
    //     }));
    //     const shareOptions = navigator.canShare({files:files})
    //     ? {
    //         title: title,
    //         files: files,
    //         text: text,
    //         url: url
    //     }
    //     :{
    //         title: title,
    //         text: text,
    //         url: url
    //     };
    //     navigator
    //         .share(shareOptions)
    //         .then(() => console.log('Share was successful.'))
    //         .catch((error) => console.log('Sharing failed', error))
    // }


    const handleClick = async () => {

        const link = makeUrl(id.toString());
        const shareOptions = {
            title: title,
            text: text,
            url: link
        };
        navigator
            .share(shareOptions)
            .then(() => console.log('Share was successful.'))
            .catch((error) => console.log('Sharing failed', error))
    }

    const can_share = !!inviteId && navigator.canShare;
    return (
        <React.Fragment>
            {can_share &&
            <IconButton onClick={() => {
                handleClick()
            }}>
                <ShareIcon/>
            </IconButton>
            }
        </React.Fragment>
    )

})
ShareBase.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.number
}


const Share = withStyles(styles)(ShareBase);
export default Share;
