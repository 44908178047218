import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import withStyles from "@material-ui/styles/withStyles";
import Box from "@material-ui/core/Box";
import {Badge, Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import theme from "../../Theme";
import {myRecommendationStyles, recommendationStyles} from "./recommendationStyles";
import LazyImage from "../LazyImage";
import Square from "../Square";
import TimeAgo from "timeago-react";
import Grid from "@material-ui/core/Grid";
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import clsx from "clsx";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import {look_up} from "../../utils/TransformData";

export const styles = {
    root: {
        minHeight: '200px',
        margin: "7px 7px",
        background: theme.palette.background.paper,
        borderStyle: "solid",
        borderRadius: "30px",
        borderWidth: "1px",
        borderColor: "#CCCC",
        boxShadow: "10px 10px 15px  lightgrey",
        backgroundColor: "white",
        position: 'relative'  // need this for absolute positioned children to work
    },
    responseHeight: {
        minHeight: '140px'
    },
    imageFrame: {
        marginLeft: '-20px',
        marginRight: '-20px',
        height: 110,
        width: 'calc(100%+40px)',
    },
    image: {
        // objectFit: 'cover',
        maxWidth: '100%',
        height: '100%',
        width: 'auto',
        display: 'block',
        margin: '0 auto',
    },
    svgFrame: {
        margin: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    badgeBox: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    badge:{
      display: 'table',
      margin: '0 auto'
    },
    svg: {
        display: 'block',
        margin: 'auto',
        transform: 'scale(1.5)'
    },
    text_multi: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    text_single: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
    },

    RECOMMENDATION: myRecommendationStyles.RECOMMENDATION,
    REQUEST: myRecommendationStyles.REQUEST
};


const Likes = (props) => {
    const {
        numLikes,
        className,
        sx
    } = props;

    return (
        <Box className={className} sx={sx}>
            <FavoriteBorderIcon style={{
                fontSize: "10px",
                position: 'relative',
                top: "2px",
                bottom: "2px",
                marginRight: '2px',
                left: 0
            }}/>
            {numLikes.toString()}
        </Box>
    )
}


const RecommendationCardSmallBase = React.forwardRef(function RecommendationCardHeaderSmallBase(props, ref) {
    const {
        recommendation,
        onClick = () => {
        },
        classes,
    } = props;

    const {
        subject,
        images,
        location,
        category,
        created_at,
        num_responses,
        type = 'RECOMMENDATION'

    } = recommendation;
    const liked_by_param = recommendation.liked_by ? recommendation.liked_by : [];


    const actionColor = recommendationStyles[type].borderColor;


    const image = (type === 'RECOMMENDATION' && !!images && images.length !== 0)
        ? (<div className={classes.imageFrame}>
            <LazyImage
                className={classes.image}
                src={images[0].src} alt={images[0].alt}
            />
        </div>)
        : <React.Fragment/>

    const isRequest = type === 'REQUEST';
    const svg = isRequest
        ? <Box className={classes.svgFrame}>
            <Box className={classes.badgeBox}>
                <Badge color={'secondary'}  className={classes.badge} badgeContent={num_responses}>
                    <MailOutlinedIcon className={classes.svg}/>
                </Badge>
            </Box>
        </Box>
        : <React.Fragment/>

    const city = look_up(location, 'address.city');
    const country = look_up(location, 'address.country');
    const place = !!location && !!country ? city + ', ' + country : '';
    const {i18n} = useTranslation(['recommendation']);
    const {language} = i18n;


    return (
        <Card className={clsx({[classes.root]: true, [classes.responseHeight]: isRequest})} raised
              onClick={onClick}
        >
            <Square size='2px' color={actionColor} sx={{position: 'absolute', top: '6px', right: '8px'}}/>
            <CardContent>
                <Box className={classes.text_single}
                     sx={{height: '18px', maxWidth: '100px', fontSize: '11px', fontWeight: 700}}>{category}</Box>
                <Box className={classes.text_single}
                     sx={{height: '10px', fontSize: '10px', marginBottom: '2px'}}>{place}</Box>
                {image}
                <Box
                    className={classes.text_multi}
                    sx={{fontSize: '15px', lineHeight: '18px', height: '36px'}}
                >
                    {subject}
                </Box>
                {svg}

                <Grid container direction='row' alignItems='center' justifyContent='space-between'
                      style={{marginTop: '4px'}}
                >
                    <Grid item>
                        {liked_by_param.length > 0 &&
                        <Likes className={classes.text_single}
                               sx={{height: '10px', fontSize: '10px'}}
                               numLikes={liked_by_param.length}
                        />
                        }
                    </Grid>
                    <Grid item>
                        <TimeAgo className={classes.text_single}
                                 style={{height: '10px', fontSize: '10px'}}
                                 datetime={created_at}
                                 locale={language}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

})
RecommendationCardSmallBase.propTypes = {
    classes: PropTypes.object.isRequired,
}

const RecommendationCardSmallRouting = (props) => {
    const {
        recommendation,
        canEdit = false,
        routeState,
        keyProp
    } = props;

    const click_handler = recommendation.type === 'RECOMMENDATION'
        ? () => {
            routeState.openRoute('viewRecommendation', {
                'recId': recommendation.id,
                'canEdit': canEdit
            })
        }
        : () => {
            routeState.openRoute('responses', {
                'id': recommendation.id,
                'onClose': () => {
                    routeState.closeRoute('responses')
                },
                'canEdit': canEdit
            })
        }
    return (
        <RecommendationCardSmall
            recommendation={recommendation}
            onClick={click_handler}
            key={keyProp}
            {...props}
        />
    )
}

RecommendationCardSmallRouting.propTypes = {
    recommendation: PropTypes.object.isRequired,
    canEdit: PropTypes.bool,
    routeState: PropTypes.object.isRequired,
    keyProp: PropTypes.string.isRequired
}


const RecommendationCardSmall = withStyles(styles)(RecommendationCardSmallBase);
export {RecommendationCardSmall};
export {RecommendationCardSmallRouting}
