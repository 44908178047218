import React, {Suspense, lazy} from 'react';
import {
    BrowserRouter as Router,
    Route, Redirect
} from 'react-router-dom';

import {withAuthentication} from "../Session"


import LandingPage from '../Landing';
import SignUpPage, {SignUpRedirect} from '../SignUp';
import SignInPage from '../SignIn/SignIn';
import {SignOutPage} from '../SignOut';
import PasswordForgetPage from '../PasswordForget';


import Introduction, {Introduction_install} from "../Introduction";

import UserPage from "../UserPage";
import ResponsePage from "../ResponsePage";
import ReportProblem from "../ReportProblem";


import {MuiThemeProvider, makeStyles} from '@material-ui/core/styles';
import theme from '../../Theme'

import * as ROUTES from '../../constants/routes';

import CssBaseline from "@material-ui/core/CssBaseline";

const QuestionnairePage = lazy(() => import( '../Questionnaire'));
const QuestionnaireButtonHandler = lazy(() => import( '../QuestionnaireButtonHandler'));
const Recommendation = lazy(() => import( "../Recommendation"));
const Feed = lazy(() => import( "../Feed"));
const MyRecommendations = lazy(() => import( "../MyRecommendations"));
const ViewRecommendation = lazy(() => import( "../ViewRecommendation"));
const Account = lazy(() => import( "../Account"));
const AccountAdmin = lazy(() => import( "../AccountAdmin"));
const EditProfile = lazy(() => import( "../EditProfile"));
const Demographics = lazy(() => import( "../Demographics"));
const InviteFriends = lazy(() => import( "../InviteFriends"));
const CategoryFilter = lazy(() => import( "../CategoryFilter"));
const NotificationCenter = lazy(() => import("../NotificationCenter/"));
const MatchMenu = lazy(()=>import('../MatchMenu'))
const Matches = lazy(()=>import('../Matches'))
const SearchView = lazy(()=>import('../SearchView'))


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 0,
        paddingRight: 0,
        disableGutters: 'true',
        width: '100%',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        elevation: 0,
    },
    grow: {
        flexGrow: 1,
    },
}));
const classes = useStyles;

const App = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <Suspense fallback='loading'>
                    <AppContent/>
                </Suspense>
            </Router>
        </MuiThemeProvider>
    );
};

const AppContent = () => (

    <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <div className={classes.root}>
            <Route exact path="/">
                <Redirect to={ROUTES.FEED}/>
            </Route>
            <Route path={ROUTES.LANDING} component={LandingPage}/>
            <Route path={ROUTES.SIGN_UP_REDIRECT} component={SignUpRedirect}/>
            <Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
            <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
            <Route path={ROUTES.SIGN_OUT} component={SignOutPage}/>
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage}/>
            <Route path={ROUTES.QUESTIONNAIRE_BUTTON} component={QuestionnaireButtonHandler}/>
            <Route exact path={ROUTES.QUESTIONNAIRE_BASE} component={QuestionnairePage}/>
            <Route path={ROUTES.QUESTIONNAIRE} component={QuestionnairePage}/>
            <Route path={ROUTES.DEMOGRAPHICS} component={Demographics}/>
            <Route path={ROUTES.ACCOUNT} component={Account}/>
            <Route path={ROUTES.ACCOUNTADMIN} component={AccountAdmin}/>
            <Route path={ROUTES.EDIT_PROFILE} component={EditProfile}/>
            <Route path={ROUTES.INVITEFRIENDS} component={InviteFriends}/>
            <Route path={ROUTES.INTRODUCTION_INSTALL} component={Introduction_install}/>
            <Route path={ROUTES.INTRODUCTION} component={Introduction}/>
            <Route path={ROUTES.RECOMMENDATION_BASE} component={Recommendation}/>
            <Route path={ROUTES.FEED} component={Feed}/>
            <Route path={ROUTES.MATCH_MENU} component={MatchMenu} />
            <Route path={ROUTES.MATCHES} component={Matches} />
            <Route path={ROUTES.MY_RECOMMENDATIONS} component={MyRecommendations}/>
            <Route path={ROUTES.EDIT_RECOMMENDATIONS} component={Recommendation}/>
            <Route path={ROUTES.USER_PAGE} component={UserPage}/>
            <Route path={ROUTES.RESPONSES} component={ResponsePage}/>
            <Route path={ROUTES.VIEW_RECOMMENDATION} component={ViewRecommendation}/>
            <Route path={ROUTES.REPORT} component={ReportProblem}/>
            <Route path={ROUTES.FILTER} component={CategoryFilter}/>
            <Route path={ROUTES.SEARCH_VIEW} component={SearchView}/>
            <Route path={ROUTES.NOTIFICATION_CENTER} component={NotificationCenter}/>

        </div>
    </MuiThemeProvider>
);


export default withAuthentication(App);