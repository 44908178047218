const image_url = process.env.PUBLIC_URL + "static/";

const images = {
    logo_white: image_url + "moodiks_logo_gradient.jpg",
    give_me_check: image_url + "give_me_check.png",
    girl_smiling: image_url + "girl_smiling.png",
    two_chellos: image_url + "two_chellos.png",
    two_hands_sun: image_url + "two_hands_sun.png",
    two_cyclists: image_url + "two_cyclists.png",
}
console.log('Landing: images: ', images);

export default images;