import {gql, graphID_to_int, point_to_coords, reduce_tags} from "../../hooks/gql_helpers";
import {attribute_values, category_text, likes, bookmarks, remap} from "../../utils/TransformData";


const get_recommendation = gql`{
  recommendationsById(id: ${'id'}) {
    edges {
      node {
        id
        url
        type
        deactivated
        lang
        createdAt
        uid
        userProfile {
          nickname
          avatarUrl
        }
        images {
          url
          alt
        }
        subject
        supercategories {
          keywords {
            text
            lang
          }
        }
        category {
          id
          keywords {
            text
            lang
          }
        }
        tags {
          keywords {
            text
            lang
          }
        }
        location {
          coords
          displayName
          road
          houseNumber
          city
          state
          postcode
          country
        }
        experience
        attributeValues {
          valueText
          attributeName {
            id
          }
          value {
            id
          }
        }
        likedBy{
          totalCount
          edges{
             node{
               uid
               nickname
               avatarUrl
             }
          }
        }
        bookmarkedBy{
          totalCount
          edges{
             node{
               uid
             }
          }
        }
        comments {
          totalCount
          edges {
            node {
              id
              userProfile {
                uid
                avatarUrl
                nickname
              }
              createdAt
              text
              lang
              commentId
            }
          }
        }
      }
    }
  }
}`

const recommendation_template = {
    url: 'url',
    subject: 'subject',
    category_id: 'category.id',
    deactivated: 'deactivated',
    type: 'type',
    lang: 'lang',
    created_at: 'createdAt',
    uid: 'uid',
    nickname: 'userProfile.nickname',
    avatar_url: 'userProfile.avatarUrl',
    location: {
        display_name: 'location.displayName',
        address: {
            road: "location.road",
            house_number: "location.houseNumber",
            city: "location.city",
            state: "location.state",
            postcode: "location.postcode",
            country: "location.country",
        },
    },
    experience: 'experience',
    num_comments: 'comments.totalCount',
    num_likes: 'likedBy.totalCount',
};

const image_template = {
    src: 'url',
    alt: 'alt'
}


const comment_template = {
    banned: 'banned',
    posted_at: 'createdAt',
    text: 'text',
    nickname: 'userProfile.nickname',
    avatar_url: 'userProfile.avatarUrl',
    uid: 'userProfile.uid',
    lang: 'lang',
    comment_id: 'commentId'
}

const remapRecommendations = (raw) => {
    return raw.data.recommendationsById.edges.map((r) => {
        const mapped = remap(r.node, recommendation_template);

        mapped.id = graphID_to_int(r.node.id);
        // mapped.responding_to = graphID_to_int(r.node.comment_id)

        if (r.node.location) {
            const coords = point_to_coords(r.node.location.coords)
            mapped.location.lat = coords.lat;
            mapped.location.lon = coords.lon;
        } else {
            mapped.location = null;
        }

        mapped.category = category_text(r.node.category);
        mapped.tags = reduce_tags(r.node.tags, r.node.lang);
        mapped.supercategories = reduce_tags(r.node.supercategories, r.node.lang);
        if (r.node.attributeValues === undefined || r.node.attributeValues.length !== 0) {
            const a_values = attribute_values(r.node.attributeValues);
            a_values.forEach(attribute_value => mapped[attribute_value.name] = attribute_value.value)
        }

        mapped.images = r.node.images.map((i) => {
            return remap(i, image_template);
        });

        mapped.comments = r.node.comments.edges.map((comment) => {
            const mapped_comment = remap(comment.node, comment_template);
            mapped_comment.id = graphID_to_int(comment.node.id);
            return mapped_comment
        });
        mapped.liked_by = likes(r.node.likedBy);
        mapped.bookmarked_by = bookmarks(r.node.bookmarkedBy);

        return mapped
    });
}

export {get_recommendation, remapRecommendations};